.creating-variations__select-label-wrap {
   display: flex;
   gap: 10px;
   justify-content: space-between;
   align-items: center; 
}
.creating-variations__select-label {
    margin-bottom: 5px;
}
.creating-variations__select-close-btn {
    width: 11px;
    height: auto;
    cursor: pointer;
    margin-top: 2px;
}

.creating-variations__characteristics-wrap {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
}

.creating-variations__characteristics-wrap .MuiButtonBase-root {
    padding: 0;
}

.creating-variations__characteristics-wrap.MuiPaper-root::before {
    display: none;
}
.creating-variations__characteristics-wrap.MuiPaper-root {
    box-shadow: none;
}

.creating-variations__characteristics-accordion-details {
    padding: 8px 0 16px !important;
}

.creating-variations__input-characteristics-lable {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: var(--dark-charcoal);
    font-weight: 500;
}

.creating-variations__result--wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.creating-variations__select-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--dark-charcoal);
}

.creating-variations__select-label-color {
    margin-top: 10px;
}

.creating-variations__result-wrap {
}

.creating-variations__result-wrap .MuiPaper-root {
    width: 100%;
}    

.creating-variations__result-label {
    max-width: 500px;
    width: 100%;
    z-index: 2;
    margin: 3px 0 !important;
    transition: .3s;
}

.creating-variations__result-label:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-variations__result-label--disabled {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-variations__material-result-wrap {

}

.creating-variations__material-result {
    padding: 5px;
    border: 1px solid #000;
    margin-bottom: 15px;
}

.creating-variations__material-result-img {
    width: 230px;
    height: 230px;
    border: 1px solid #93939a;
    padding: 3px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.creating-variations__material-create {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.creating-variations__material-create-label-wrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.creating-variations__material-create-label {

}

.creating-variations__material-result-img-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.creating-variations__material-create-input-wrap {
    display: flex;
    flex-direction: column;
}

.creating-variations__material-create-lable-wrap {

}

.creating-variations__material-create-lable {
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--dark-charcoal);
}

.creating-variations__color-result--wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.creating-variations__color-add-img {
    width: 17px;
    height: 17px;
}

.creating-variations__characteristics-result {
    width: 100%;
}

.creating-variations__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 15px;
}

.creating-variations__textarea::placeholder {
    font-size: 13px !important;
}

.creating-variations__input {
    height: 40.4px;
}

.creating-variations__input::placeholder {
    font-size: 13px !important;
}

.creating-variations__btn-select-wrap {
    display: flex;
    align-items: center;
    height: 40.4px;
    --webkit-height: 40.4px;
}

.creating-variations__btn-select {
    height: 40.4px;
    --webkit-height: 40.4px;
    padding: 0 10px;
    border-top: 1px solid #89898999;
    border-bottom: 1px solid #89898999;
    border-left: 1px solid #89898999;
}
.creating-variations__btn-select-img {
    width: 15px;
    height: auto;
    transform: rotate(180deg);
    transition: .3s;
}

.creating-variations__btn-select-img--open {
    transform: rotate(0deg);
}

.creating-variations__result-characteristic--wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.creating-variations__result-characteristic-wrap {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    border: 1px solid #D8D8D8;
    gap: 15px;
}

.creating-variations__result-characteristic-btn {
    width: 20px;
    height: auto;
    cursor: pointer;
}

.creating-variations__color-variations-result--wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.creating-variations__color-picker-wrap {
    margin-bottom: 20px;
}

.creating-variations__select-mark--wrap {
    
}

.creating-variations__select-mark--wrap .MuiSelect-select {
    font-size: 14px;
}

.creating-variations__add-btn--wrap {
    height: 40.4px;
    --webkit-height: 40.4px;
}

.creating-variations__characteristic-add-result {
    width: fit-content;
    border-radius: 0 !important;
    height: 40.4px;
}

.creating-variations__select-variations-error {
    font-size: 14px;
    margin-top: 15px;
}

.creating-variations__menu-item-wrap {
    
}

.creating-variations__menu-item-wrap .MuiPaper-root {
    max-height: calc(100% - 200px);
}

@media (min-width: 500px) {
    
    .creating-variations__menu-item-wrap .MuiPaper-root {
        max-height: calc(100% - 96px);
    }
    .creating-variations__material-create-label-wrap {
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .creating-variations__result-wrap .MuiPaper-root {
        max-width: 469px;
    }   
}

@media (min-width: 768px) {
    .creating-variations__result-wrap .MuiPaper-root {
        max-width: 453px;
    }  
}
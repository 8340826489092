.wrapper {
	position: absolute;
	z-index: 10;
	background: #ffffff;
	width: 100%;
	height: 100%;
}

.preloader {
	position: fixed;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	width: 60px;
	aspect-ratio: 1;
	--c: no-repeat linear-gradient(#2a7f77 0 0);
	background: var(--c) 0 0, var(--c) 100% 0, var(--c) 100% 100%, var(--c) 0 100%;
	animation: l2-1 2s infinite, l2-2 2s infinite;
}
@keyframes l2-1 {
	0% {
		background-size: 0 4px, 4px 0, 0 4px, 4px 0;
	}
	12.5% {
		background-size: 100% 4px, 4px 0, 0 4px, 4px 0;
	}
	25% {
		background-size: 100% 4px, 4px 100%, 0 4px, 4px 0;
	}
	37.5% {
		background-size: 100% 4px, 4px 100%, 100% 4px, 4px 0;
	}
	45%,
	55% {
		background-size: 100% 4px, 4px 100%, 100% 4px, 4px 100%;
	}
	62.5% {
		background-size: 0 4px, 4px 100%, 100% 4px, 4px 100%;
	}
	75% {
		background-size: 0 4px, 4px 0, 100% 4px, 4px 100%;
	}
	87.5% {
		background-size: 0 4px, 4px 0, 0 4px, 4px 100%;
	}
	100% {
		background-size: 0 4px, 4px 0, 0 4px, 4px 0;
	}
}
@keyframes l2-2 {
	0%,
	49.9% {
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
	}
	50%,
	100% {
		background-position: 100% 0, 100% 100%, 0 100%, 0 0;
	}
}

.user-order {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 14px;
    color: #19191D;
}

.user-order__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-order__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-order__header-title-img {
    width: 18px;
    height: 20px;
}

.user-order__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-order__filter-wrap {
    display: none;
    width: 100vw;
    border-bottom: 2px solid #D8D8D8;
    padding: 20px 15px;
}

.user-order__filter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
    padding: 0 20px;
    margin-top: 15px;
}

.user-order__filter-select-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.user-order__search-input {
    max-width: 160px;
    width: 100%;
    height: 33.6px;
    border: 1px solid #D8D8D8;
    padding: 8.4px 22px 8.5px 30px;
    border-radius: 0;
    --webkit-border-radius: 0;
}

.user-order__filter-date-btn-wrap {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.user-order__filter-date-btn-del {
    border: 1px solid var(--main-green);
    background-color: white;
    color: #19191D;
    padding: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 3px;
    display: block;
    transition: .3s;
}

.user-order__filter-date-select {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-order__orders-wrap {
    overflow: hidden;
}

.user-order__orders {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    overflow: auto;
    --webkit-overflow: auto;
    transition: .3s;
}

.user-order__orders--is-open-menu {
    width: calc(100vw - 262px);
}

.user-order__orders--is-close-menu {
}

.user-order__text-tab-wrap--is-open-menu {
    max-width: calc(100vw - 447px);
    overflow: auto;
}

.user-order__text-tab-wrap--is-close-menu {
    max-width: auto;
    overflow: auto;
}

.user-order__order-select-status {

}

.user-order__order-wrap {
    display: flex;
    flex-direction: column;
    width: auto;
    width: 100%;
}

.user-order__order {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    min-width: fit-content;
    padding: 12px 5px;
    border-bottom: 1px solid #D8D8D8;
    font-size: 13px;
    transition: .3s;
}

.user-order__order--pending {
    border: 1px solid #b82dbd;
    color: #b82dbd;
    padding: 2px 12px;;
}

.user-order__order--calculation {
    border: 1px solid #898989;
    color: #898989;
    padding: 2px 12px;;
}

.user-order__order--in_progress {
    border: 1px solid #4C6FFF;
    color: #4C6FFF;
    padding: 2px 12px;;
}

.user-order__order--completed {
    border: 1px solid #2A7F77;
    color: #2A7F77;
    padding: 2px 12px;;
}

.user-order__order--cancelled {
    border: 1px solid #932906;
    color: #932906;
    padding: 2px 12px;;
}

.user-order__order-text-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    flex: 1 1;
    justify-content: space-between;
    cursor: pointer;
    padding-right: 32px;
}

.user-order__order-text {
    white-space: nowrap;
}

.user-order__order-text--2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.user-order__order-text--3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.user-order__order-text-numer {
    display: none;
    width: 120px;
}

.user-order__order-text-code-wrap {
    display: none;
    width: 100px;
}

.user-order__order-text-code-title {
}

.user-order__order-text-code {
    color: #2A7F77;
}

.user-order__order-status {
    position: absolute;
    top: 12px;
    right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
}

.user-order__order-text-vendor {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    border-radius: 1px;
    padding: 2px 0;
}

.user-order__order-text-phone {
    display: none;
    width: 135px;
}

.user-order__order-text-title {
    display: none;
    width: 140px;
}

.user-order__order-text-price {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.user-order__order-text-date {
    width: 135px;
}

.user-order__orders-pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.user-order__order-select-wrap {
    position: absolute;
    transform: rotate(90deg) translateY(-50%);
    top: 50%;
    right: 10px;
}

.user-order__order-select-wrap .MuiButtonBase-root {
    min-width: 35px;
    width: 35px;
    padding: 0;
}

.user-order__products-header--wrap {
    display: none;
    margin-top: 35px;
    width: fit-content;
}

.user-order__products-header-wrap {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    font-size: 13px;
}

.user-order__products-header {
}

.user-order__products-header-status {
    display: flex;
    align-items: end;
    width: 161px;
    font-weight: 500;
}

.user-order__products-header-count {
    display: flex;
    align-items: end;
    width: 100px;
    font-weight: 500;
}

.user-order__products-header-vendor {
    display: flex;
    align-items: end;
    width: 140px;
    font-weight: 500;
}

.user-order__products-header-tel {
    display: flex;
    align-items: end;
    width: 135px;
    font-weight: 500;
}

.user-order__products-header-name {
    display: flex;
    align-items: end;
    width: 140px;
    font-weight: 500;
}

.user-order__products-header-code {
    display: flex;
    align-items: end;
    width: 100px;
    font-weight: 500;
}

.user-order__products-header-date {
    display: flex;
    align-items: end;
    width: 135px;
    font-weight: 500;
}

.user-order__products-header {
    display: flex;
    align-items: end;
    width: 126px;
    font-weight: 500;
}

.user-order__order-text-date-title {
}

.user-order__filter-btn-del-wrpa {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.user-order__filter-btn {
    height: 40px;
    font-family: 'Montserrat';
    font-size: 13px;
    border: 1px solid var(--main-green);
    background: var(--main-green);
    color: #fff;
    min-width: 144px;
    font-weight: 500;
}

.user-order__filter-btn-del {
    height: 40px;
    font-family: 'Montserrat';
    font-size: 13px;
    border: 1px solid var(--main-green);
    color: #19191D;
    min-width: 144px;
    font-weight: 500;
}

.user-order__text-tab-wrap {
    display: none;
    white-space: nowrap;
}

.user-order__text-tab {
    max-width: 230px;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.user-order__text-tab:hover {
}

.user-order__text-tab-active {
    border-bottom: 2px solid var(--main-green) !important;
    position: relative;
    color: var(--main-green);
    z-index: 2;
    font-weight: 500;
    font-family: Montserrat;
}

.user-order__modal-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 20px;
    gap: 15px;
}

.user-order__mobile-filter-btn {
    display: flex;
    gap: 5px;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-left: 15px;
    margin-left: -15px;
    border-bottom: 2px solid #D8D8D8;
    width: calc(100% + 30px);
}

.user-order__mobile-filter-btn--wrap {
    display: flex;
    gap: 5px;
    align-items: center;
}

.user-order__mobile-filter-wrap {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 12px;
}

.user-order__modal-filter-btn-close {
    width: 12px;
    height: 12px;
}

.user-order__modal-filter-btn-close-wrap {
    position: relative;
    position: absolute;
    top: 20px;
    right: 21px;
    cursor: pointer;
    z-index: 100;
    transition: .3s;
}

.user-order__modal-filter-btn-close-wrap::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
}

.user-order__modal-filter-menu-item {
    font-size: 14px !important;
    min-height: auto !important;
}

.user-order__text-tab--wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.user-order__mobile-filter-btn-text {
    font-weight: 500;
    font-size: 15px;
}

.user-order__header-title-img--is-requests {
    width: 25px;
    height: auto;
}

.user-order__order-error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: 500;
    color: #545D69;
    font-size: 26px;
}

@media (min-width: 365px) {
    .user-order__header {
        gap: 15px;
    }
    .user-order__header-title-wrap {
        gap: 20px;
    }
}
@media (min-width: 768px) {
    .user-order__header {
        padding: 0 15px 0 15px;
        margin-left: 0;
        align-items: flex-end;
        flex-wrap: nowrap;
        width: calc(100% + 15px);
    }
    .user-order__order-select-status {
        display: none !important;
    }
    .user-order__text-tab-wrap {
        display: flex;
    }
    .user-order__text-tab-wrap::-webkit-scrollbar {
        height: 1px;
        background: #898989;
    }
    .user-order__filter-wrap {
        display: flex;
        width: 100%;
        min-width: 1140px;
    }
    .user-order__products-header--wrap {
        display: block;
        width: 100%;
        min-width: 1140px;
    }
    .user-order__mobile-filter-btn {
        display: none;
    }
    .user-order__order-text-phone,
    .user-order__order-text-title,
    .user-order__order-text-code-wrap,
    .user-order__order-text-numer {
        display: block;
    }
    .user-order__order-select-wrap {
        display: block;
        transform: rotate(90deg);
        position: static;
    }
    .user-order__order-text-wrap {
        align-items: center;
        flex-direction: row;
        padding-right: 0;
    }
    .user-order__orders-wrap {
        padding-left: 15px;
    }
    .user-order__order-status {
        position: static;
    }
    .user-order__order-text-date-title {
        display: none;
    }
    .user-order__order-text-vendor {
        flex-direction: column;
        justify-content: center;
        width: 140px;
        gap: 0;
    }
    .user-order__order-text {
        white-space: nowrap;
    }
    .user-order__order-text-price {
        width: 100px;
        font-weight: 400;
    }
    .user-order__header-title-wrap {
        padding-bottom: 32px;
    }
    .user-order__text-tab--wrap {
        justify-content: center;
    }
}
@media (min-width: 1037px) {
    .user-order__text-tab {
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media (min-width: 1200px) {
    .user-order__products-header--wrap {
        display: block;
    }
    .user-order__header {
        padding: 0 30px 0 30px;
    }
    .user-order__text-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 1680px) {
    .user-order__orders {
        font-size: 14px;
    }
    .user-order__products-header-wrap {
        font-size: 14px;
    }
    .user-order__order {
        font-size: 14px;
        
    }
    .user-order__order-text {
        font-size: 14px;
    }
}
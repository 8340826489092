.preloader-product-custom-wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 10000000;
}

.loader {
}
@keyframes l1 {
  0% {background-position: right}
}
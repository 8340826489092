.partner-registration {
	color: var(--dark-charcoal);
	font-size: var(--text-size);
}

.partner-registration--wrap {
	max-width: 640px;
	padding: 50px 20px;
}

.partner-registration__title {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	padding: 45px 20px;
	background-image: -webkit-linear-gradient(
		top,
		rgba(241, 242, 242, 1),
		rgba(241, 242, 242, 1)
	);
}

.partner-registration__title-link {
	color: var(--main-green);
	font-size: 26px;
}

.partner-registration__form-input-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 600px;
}

.partner-registration__form-input {
	height: 55px;
	line-height: 1.33;
	width: 100%;
	border-bottom: 1px solid #191900;
	font-size: 15px;
	outline: none;
	border-radius: 0;
}

.partner-registration__form-input-work {
	margin-bottom: 25px;
}

.partner-registration__form-input-error {
	border: 1px solid red;
}

.partner-registration__form-input::placeholder {
}

.partner-registration__form-textarea {
	padding: 15px 0;
	line-height: 1.33;
	width: 100%;
	border-bottom: 1px solid #19191d;
	outline: none;
	border-left: 0;
	border-top: 0;
	border-right: 0;
	caret-color: #19191d;
	font-family: 'Montserrat';
	font-size: 15px;
	border-radius: 0;
	font-family: 'Montserrat';
}

.partner-registration__btn-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 15px;
}

.partner-registration__step-wrap {
	width: 100%;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 5px;
	color: #757575;
	font-size: 12px;
}

.partner-registration__input-upload-logo-wrap--error {
	border: 1px solid red;
}

.partner-registration__input-upload-wrap {
	margin-bottom: 25px;
}

.partner-registration__input-upload-sub-title {
	font-size: 12px;
	opacity: 0.7;
	color: #2f2f2f;
	padding-top: 5px;
}

.partner-registration__input-upload {
	position: absolute;
	width: 0;
	height: 0;
	z-index: -100;
	opacity: 0;
}

.partner-registration__create-btn-wrap {
	margin-top: 30px;
}

.partner-registration__upload-result-logo-wrap {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 15px;
}

.partner-registration__upload-result-wrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row-reverse;
	margin-bottom: 15px;
}

.partner-registration__upload-result-banner-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 5px;
	margin-bottom: 15px;
	max-width: 800px;
}

.partner-registration__upload-result-del-btn {
	width: 25px;
	height: 25px;
	margin-left: auto;
	margin-right: auto;
}

.partner-registration__upload-img-logo-result {
	width: 110px;
	height: 110px;
	border-radius: 50%;
	object-fit: cover;
}

.partner-registration__input-upload-banner-wrap {
	margin-bottom: 15px;
}

.partner-registration__input-upload-banner-wrap--error {
	border: 1px solid red;
}

.partner-registration__upload-img-banner-result {
	aspect-ratio: 2/1;
	width: 100%;
}

.partner-registration__text-warning {
	text-align: center;
	margin-top: 30px;
}

.partner-registration__text-warning-linc {
	color: var(--main-green);
}

.partner-registration__form-input-payment {
	height: 50px;
	line-height: 1.33;
	min-width: 200px;
	width: 100%;
	border-bottom: 1px solid #19191d;
	padding-right: 10px;
	outline: none;
	border-radius: 0;
	font-size: 15px;
}

.partner-registration__form-input-payment-wrap {
	display: flex;
	align-items: flex-end;
}

.partner-registration__result-payment-wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 10px;
	margin-bottom: 25px;
}

.partner-registration__result-payment {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 15px;
}

.partner-registration__result-payment-del-btn {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.partner-registration__form-input-label {
	position: relative;
	width: 100%;
	font-size: 15px;
	margin-bottom: 35px;
}

.partner-registration__form-input-label--phone {
	padding-bottom: 20px;
}

.partner-registration__form-input-label--phone-main {
	padding-bottom: 20px;
	margin-bottom: 10px;
}

.partner-registration__phone-wrap {
	margin-bottom: 35px;
}

.partner-registration__btn {
	display: flex;
	gap: 10px;
	align-items: center;
	height: 50px;
	font-size: 14px;
	font-weight: 600 !important;
}

.partner-registration__btn-img {
	width: 18px;
	transform: rotate(180deg);
}

.partner-registration__form-input-sub-title {
	font-family: 'Montserrat', Arial, sans-serif;
	font-weight: 400;
	color: #2f2f2f;
	line-height: 1.55;
	opacity: 0.7;
	font-size: 12px;
	padding-top: 5px;
}

.partner-registration__add-new-payment {
	font-size: 14px;
}

.partner-registration__upload-img {
	display: inline-block;
	font-size: 14px;
	margin-top: 10px;
}

.partner-registration__input-upload-title {
	font-size: 15px;
}

.partner-registration__upload-result-del-btn-wrap {
	margin-left: 90px;
	transform: translateY(10px);
}

.partner-registration__upload-result-del-btn-banner-files-wrap {
}

.partner-registration__upload-img-doc-result {
	width: 96%;
}

.partner-registration__btn-back {
	display: flex;
	align-items: center;
	gap: 10px;
}

.partner-registration__btn-back-img-stroke {
	transition: 0.3s;
}

.partner-registration__btn-back:hover
	.partner-registration__btn-back-img-stroke {
	stroke: #fff;
}

.partner-registration__btn-back-img {
	width: 16.8px;
	height: 16.8px;
}

.partner-registration__btn-send {
	padding: 11px 45px;
}

.partner-registration__btn-send-disabled {
	opacity: 0.6;
	pointer-events: none;
}

.partner-registration__btn--wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.partner-registration__btn-registration-wrap {
	margin-left: auto;
	margin-right: auto;
}

.partner-registration__input-upload-banner-wrap--is-img {
	margin-bottom: 25px;
}

.partner-registration__step-1-wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 640px) {
	.partner-registration__btn--wrap {
		display: flex;
	}
	.partner-registration__step-wrap {
		width: fit-content;
	}
	.partner-registration__btn-wrap {
		width: 100%;
		justify-content: space-between;
	}
	.partner-registration__btn-send {
		order: 2;
	}
	.partner-registration__step-full {
		width: 100%;
	}
	.partner-registration__step-1-wrap {
		flex-direction: row-reverse;
		justify-content: flex-start;
		gap: 15px;
	}
	.partner-registration__btn-registration-wrap {
		margin-left: 0;
		margin-right: 0;
	}
}

@media (min-width: 960px) {
	.partner-registration__title {
		font-size: 32px;
	}
	.partner-registration__form-input-wrap {
		width: 50%;
	}
	.partner-registration__input-upload-sub-title {
		font-size: 13px;
	}
	.partner-registration__form-input-label {
		font-size: 17px;
	}
	.partner-registration__form-input-sub-title {
		font-size: 13px;
	}
	.partner-registration__input-upload-title {
		font-size: 17px;
	}
	.partner-registration--wrap {
		max-width: 660px;
	}
}
@media (min-width: 1200px) {
	.partner-registration__title {
		font-size: 36px;
	}
	.partner-registration__form-input-label {
		font-size: 20px;
	}
	.partner-registration__input-upload-title {
		font-size: 20px;
	}
	.partner-registration__form-input-sub-title {
		font-size: 14px;
	}
	.partner-registration__input-upload-sub-title {
		font-size: 14px;
	}
	.partner-registration--wrap {
		max-width: 800px;
	}
	.partner-registration__result-payment {
		font-size: 16px;
	}
	.partner-registration__step-1-wrap {
		gap: 87px;
	}
}

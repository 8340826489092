.not-found-view {
}

.not-found-view--wrap {
    
}

.not-found-view__main-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.not-found-view__main {
    margin-bottom: 90px;
    margin-right: auto;
    margin-left: auto;
    max-width: 2300px;
    padding: 0 15px;
}

.not-found-view__main-img {
    max-height: 390px;
    object-fit: contain;
}

.not-found-view__main-title {
    font-size: 120px
}

.not-found-view__main-text {
    line-height: 19px;
    font-size: 14px;
    margin-bottom: 30px;
    font-weight: 400;
}

.not-found-view__main--wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.not-found-view__main-wrap {

}
@media (min-width: 640px) {
    .not-found-view__main-title {
        font-size: 150px
    }
    .not-found-view__main-text {
        font-size: 16px
    }
    .not-found-view__main {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .not-found-view__main-img {
        width: 50%;
        max-height: 500px;
    }
}
@media (min-width: 960px) {
    .not-found-view__main-title {
        font-size: 180px
    }
}
@media (min-width: 1200px) {
    .not-found-view__main {
        padding: 0 30px;
    }
}
.show-message__success {
    min-width: 250px;
    color: rgb(41, 113, 7);
}

.show-message__error {
    min-width: 250px;
    color: rgb(223, 17, 17);
}

.show-message__hidden {
    opacity: 0;
}
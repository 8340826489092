.main-button {
    display: inline-block;
    width: 100%;
    min-width: 144px;
    height: 41px;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    line-height: 0;
    padding: 10px 20px;
    transition: .3s;
}

.main-button-wrap {
    width: fit-content;
}

.main-button--white {
    border: 1px solid #fff;
    background: #fff;
    color: var(--dark-charcoal);
}

.main-button--white:hover {
    background: var(--main-green);
    color: #ffffff;
}

.main-button--green {
    font-size: 12px;
    border: 1px solid var(--main-green);
    background: var(--main-green);
    color: #fff;
}

.main-button--green:hover {
    background: #fff;
    color: var(--main-green);
}

.main-button--black {
    border: 1px solid var(--dark-charcoal);
    background: var(--dark-charcoal);
    color: #fff;
}

.main-button--black:hover {
    background: #fff;
    color: var(--dark-charcoal);
}

.main-button--green-border {
    border: 1px solid var(--main-green);
    background: inherit;
}

.main-button--no-hover {
    background: var(--main-green);
    color: #fff;
}

.main-button--no-hover:hover {
    background-color: #1f615c;
}

.main-button__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 5px;
}

.main-button--1 {
    width: 100%;
    height: 50px;
}

.main-button-1-wrap {
    width: 100%;
    height: 50px;
}

.main-button-1-wrap .main-button__text {
    font-size: 15px !important;
    font-weight: 500;
}

.main-button--1 {
    height: 60px !important;
}

@media (min-width: 480px) {
    .main-button {
        font-size: 12px;
    }
}
@media (min-width: 640px) {
    .main-button-1-wrap .main-button__text {
        font-size: 16px !important;
    }
}
@media (min-width: 768px) {
    .main-button--green {
        font-size: 14px;
    }
}
@media (min-width: 960px) {
    .main-button {
        height: 41px;
    }
}
@media (min-width: 1680px) {
    .main-button {
        font-size: 14px;
    }
}
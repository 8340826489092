.user-categories {
    color: #19191D;
}

.user-categories__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-categories__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-categories__header-title-img {
    width: 24px;
    height: 24px;
}

.user-categories__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-categories__header-btn-wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.user-categories__header-btn--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.user-categories__header-btn {
    display: flex;
    align-items: center; 
    gap: 13px;
    background: #2A7F77;
    padding: 8px 27px 8px 20px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
}

.user-categories__header-btn-img {
    width: 24px;
    height: 24px;
}

.user-categories__category-wrap {
    padding: 20px 0 30px;
}

.user-categories__category-title {
    font-size: 14px;
    color: var(--dark-charcoal);
    padding-bottom: 6px;
    border-bottom: 1px solid #D8D8D8;
}

.user-categories__category-card-wrap {

}

.user-categories__category-card {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: flex-start;
    padding: 15px 0;
    border-bottom: 1px solid #D8D8D8;
}

.user-categories__category-card-img {
    width: 70px;
    height: 70px;
}

.user-categories__category-card-no-photo {
    align-self: center;
    width: 70px;
    height: 70px;
    padding: 3px;
}

.user-categories__category-card-text-wrap {
    flex: 1 1;
}

.user-categories__category-card-text-name {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    color: var(--dark-charcoal);
    margin-bottom: 13px;
    font-weight: 500;
    cursor: pointer;
}

.user-categories__category-card-text-sub-category-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
}

.user-categories__category-card-text-sub-category {
    display: flex;
    align-items: center;
    gap: 2px;
    width: fit-content;
    padding-left: 6px;
    transition: .3s;
}

.user-categories__category-card-text-sub-category-name {
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.user-categories__category-card-text-sub-category-btn-create-wrap {
    width: 17px;
    height: 17px;
}

.user-categories__category-card-text-sub-category-btn-create {
    width: 17px;
    height: 17px;
    margin-left: 5px;
    cursor: pointer;
}

.user-categories__category-card-text-btn-wrap {
    margin-left: auto;
}

.user-categories__sub-category-card-text-btn-wrap {
    transform: translateY(3px);
}

.user-categories__modal-edite-btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
    transition: .3s;
}

.user-categories__category--hide {
    opacity: .5;
}

.user-categories__category-card-text-sub-category-hide-img {
    width: 18px;
    height: auto;
}

@media (min-width: 365px) {
    .user-categories__header {
        gap: 15px;
    }
    .user-categories__header-title-wrap {
        gap: 20px;
    }
}
@media (min-width: 768px) {
    .user-categories__header {
        width: calc(100% + 15px);
        margin-left: 0;
    }
    .user-categories__category-wrap {
        padding: 25px 0 30px 15px;
    }
    .user-categories__category-card {
        align-items: center;
    }
    .user-categories__category-card-text-sub-category-wrap {
        flex-direction: row;
        align-items: center;
    }
    .user-categories__category-card-text-btn-wrap {
    }
    .user-categories__sub-category-card-text-btn-wrap {
        margin-left: 5px;
        margin-right: 15px;
    }
    .user-categories__category-card-text-sub-category {
        padding-left: 0;
    }
    .user-categories__category-card-text-sub-category-btn-create {
        margin-left: 10px;
    }
}
@media (min-width: 1200px) {
    .user-categories__header {
        padding: 20px 30px;
    }
}
@media (min-width: 1680px) {
    .user-categories__category-card-text-sub-category-name {
        font-size: 14px;
    }
    .user-categories__category-card-text-name {
        font-size: 15px;
    }
}

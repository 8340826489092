
.color-result-wrap--card {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #D2D2D2;
}

.color-result-wrap--admin-card {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 0;
    border: 1px solid #49454F;
}

.color-result-active--card {
    width: 11px;
    height: 8.708px;
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
}

.color-result--card--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: .5px solid #c8c8c87d;
    border-radius: 50%;
}

.color-result--card {
    display: inline-block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.color-result--admin-card {
    border-radius: 0;
    width: 13px;
    height: 13px;
}

.color-result-wrap {
    display: inline-block;
    width: 48px;
    height: 48px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid #C8C8C8;
}

.color-result-active {
    border: 1px solid #000;
}

.color-result {
    display: inline-block;
    width: 100%;
    height: 100%;
    border: .5px solid #c8c8c87d;
}

.color-result__multicolor {
    border-radius: 50%;
    width: 100%;
}

.color-result__multicolor--product-view {
    width: 100%;
    border-radius: 0;
}

.color-result-wrap-active {
    border: 1px solid #49454F;
}

.color-result--card--wrap--is-admin {
    border: .5px solid #c8c8c87d;
    border-radius: 0;
}   
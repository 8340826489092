.product-card {
	display: flex;
	color: var(--dark-charcoal);
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.product-card:hover .product-card__btn-by {
	opacity: 1;
}

.product-card-wrap {
}

.product-card__img-link {
	width: 100%;
}

.product-card__img-wrap {
	position: relative;
	cursor: pointer;
	margin-bottom: 7px;
	transition: 0.3s;
}

.product-card__img-wrap:hover .product-card__img--1 {
	display: none;
}

.product-card__img-wrap:hover .product-card__img--2 {
	display: block;
}

.product-card__img {
	aspect-ratio: 1/1;
	object-fit: contain;
}

.product-card__img--1 {
}

.product-card__img--2 {
	display: none;
}

.product-card__title {
	font-weight: 500;
	font-size: 14px;
	margin-top: 5px;
	margin-bottom: 4px;
	line-height: 150%;
	cursor: pointer;
	color: var(--dark-charcoal);
}

.product-card__title-wrap {
	width: 100%;
}

.product-card__text {
	font-weight: 500;
	font-size: 14px;
	margin-top: 8px;
	line-height: 150%;
	cursor: pointer;
	color: var(--dark-charcoal);
}

.product-card__variations-select-wrap {
	margin-top: 11px;
	height: 34px;
}

.product-card__variations-select-color-wrap {
	display: flex;
	gap: 11px;
	align-items: center;
}

.product-card__variations-select-color-all-btn {
	display: flex;
	width: 33px;
	min-width: 33px;
	height: 33px;
	padding: 2px;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid #d2d2d2;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.product-card__variations-select .MuiOutlinedInput-notchedOutline {
	border: none;
}

.product-card__variations-select.MuiInputBase-root {
	border-radius: 0;
}

.product-card__variations-select .MuiNativeSelect-select {
	padding: 5px 14px;
}

.product-card__btn-wrap {
	margin-top: 10px;
}

.product-card__btn-by {
	display: flex;
	align-items: end;
	width: 100%;
	color: #3a3a3a;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-decoration-line: underline;
	margin-top: 10px;
	transition: 0.3s;
	opacity: 0;
	color: var(--main-green);
}

.product-card__mark {
	position: absolute;
	left: 0;
	bottom: 4px;
	display: inline-block;
	font-weight: 400;
	font-size: 12px;
	color: #fff;
	padding: 2px 6px;
}

.product-card__like-btn-wrap {
	position: absolute;
	top: 8px;
	right: 8px;
	display: none;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 50%;
	z-index: 2;
}

.product-card:hover .product-card__like-btn-wrap {
	display: flex;
}

.product-card__like-btn-wrap:hover .product-card__like-btn-1 {
}

.product-card__like-btn-wrap:hover .product-card__like-btn-2 {
}

.product-card__like-btn-wrap--active {
	display: flex;
}

.product-card__like-btn {
	width: 18.15px;
	height: 15.2px;
}

.product-card__like-btn-1 {
}

.product-card__like-btn-2 {
}

.product-card__rating-wrap {
	display: flex;
	align-items: flex-end !important;
	gap: 5px;
	width: 100% !important;
	margin-bottom: 7px;
}

.product-card__rating {
	padding-right: 5px;
}

.product-card__rating-text {
	font-size: 13px;
	font-weight: 600;
	line-height: 22px;
	transform: translateY(-1px);
}

.product-card__rating .MuiRating-decimal {
	color: rgba(58, 58, 58, 1);
	font-size: 17px;
}

.product-card__variations-count {
	line-height: 20px;
	font-size: 13px;
	font-weight: 500;
	color: var(--dark-charcoal);
	margin-top: 8px;
}

.product-card__variations-count-text {
	font-weight: 400;
}

.product-card__product-delete-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 33px;
	color: red;
	font-weight: 600;
}

.product-card__skeleton-wrap {
	position: absolute;
	z-index: 1;
	width: 48%;
	background: #fff;
}

.product-card-wrap--is-preloader {
	opacity: 0;
}

@media (min-width: 640px) {
	.product-card__skeleton-wrap {
		width: 32%;
	}
}

@media (min-width: 996px) {
	.product-card__like-btn-wrap {
		display: none;
	}

	.product-card__like-btn-wrap--active {
		display: flex;
	}

	.product-card:hover .product-card__like-btn-wrap {
		display: flex;
	}
}
@media (min-width: 1200px) {
	.product-card__title {
		font-size: 15px;
	}
	.product-card__text {
		font-size: 15px;
	}
	.product-card__skeleton-wrap {
		width: 24%;
	}
}
@media (min-width: 1680px) {
	.product-card__mark {
		font-weight: 500;
		font-size: 14px;
		padding: 2px 12px;
	}
	.product-card__title {
		font-size: 16px;
	}
	.product-card__text {
		font-size: 16px;
	}
	.product-card__rating-text {
		font-size: 14px;
	}
	.product-card__variations-count {
		font-size: 14px;
	}
}

.modal-window {
    position: fixed;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    transition: .3s;
}

.modal-window-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    transition: .3s;
}

.modal-window--wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(103 99 99);
    box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
    padding: 15px;
    z-index: 16;
    color: #000000;
    max-height: 100vh;
    overflow: auto;
    transition: .3s;
}

.modal-window__title {
    font-size: 14px;
    margin-bottom: 30px;
    font-weight: 500;
}

.modal-window__text {
    font-size: 16px;
    margin-bottom: 15px;
}

.modal-window__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    aspect-ratio: 9/12;
}

.modal-window__btn-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    margin-top: auto;
    margin-top: 15px;
}

.modal-window__btn {
    padding: 11.6px 20px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 500;
    transition: .3s;
}

.modal-window__btn1 {
    background: var(--main-green);
    border: 1px solid var(--main-green);
    transition: .3s;
}

.modal-window__btn2 {
    color: var(--dark-charcoal);
    border: 1px solid var(--main-green);
}

@media (min-width: 576px) {
    .modal-window {
        font-size: 14px;
    }
    .modal-window__title {
        font-size: 20px;
    }
    .modal-window--wrap {
        padding: 30px 20px;
    }
}
@media (min-width: 768px) {
    .modal-window--wrap {
        width: 100%;
    }
}
@media (min-width: 1200px) {
    .modal-window {
        font-size: 16px;
    }
}
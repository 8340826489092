.contacts-view {
    font-size: var(--text-size);
    color: #49454F;
}

.contacts-view--wrap {
}

.contacts-view__title-wrap {
    background-image: -webkit-linear-gradient(top, rgba(241,242,242,1), rgba(241,242,242,1));
    padding: 68px 20px;
}

.contacts-view__title {
    font-size: var(--main-title-size);
    color: #0f0f0f;
    font-weight: 700;
    max-width: 1200px;
    text-align: center;
    color: var(--dark-charcoal)
}

.contacts-view__main-wrap {
    padding: 0 15px;
    margin-bottom: 60px;
}

.contacts-view__main {
    max-width: 1200px;
}

.contacts-view__main-text {
    margin-top: 60px;
    margin-bottom: 60px;
    line-height: 1.55;
    font-size: var(--big-text-size);
    font-weight: 400;
}

.contacts-view__main-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 45px;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.contacts-view__main-img {
    max-height: 667px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.contacts-view__form-wrap {
    padding: 0 15px;
}

.contacts-view__form {
    max-width: 1200px;
    padding: 60px 0 20px;
}

.contacts-view__form--wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.contacts-view__form-info {
    display: flex;
    flex-direction: column;
    max-width: 630px;
    align-self: flex-start;
}

.contacts-view__form-info-title {
    color: var(--dark-charcoal);
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}

.contacts-view__form-info-text {
    margin-bottom: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: var(--big-text-size);
}

.contacts-view__form-info-text-link {
    color: var(--main-green);
}

.contacts-view__form-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
}

.contacts-view__form-input {
    height: 60px;
    padding: 0 20px;
    line-height: 1.33;
    width: 100%;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    max-width: 380px;
    border-radius: 0;
    -webkit-border-radius: 0;
}

.contacts-view__form-textarea {
    padding: 17px 20px 0;
    line-height: 1.33;
    width: 100%;
    max-width: 380px;
    border: 1px solid #c7c7c7;
    outline: none;
    font-size: var(--text-size);
    font-family: 'Montserrat';
    resize: none;
    border-radius: 0;
    font-size: 15px;
    -webkit-border-radius: 0;
    caret-color: #19191D;
}

.contacts-view__form-textarea:focus-visible {
    border: 1px solid #c7c7c7;
}

.contacts-view__banner-wrap {

}

.contacts-view__form-info-text-1 {
    font-weight: 500;
} 

.contacts-view__form-info-text-title {
    font-size: 15px; 
    color: #49454F;   
}

.contacts-view__form-info-text-phon {
    margin-bottom: 20px;
}

.contacts-view__form-error-wrap {
    position: relative;
    width: 100%;
}

.contacts-view__form-error {
    border: 1px solid red !important;
}

.contacts-view__form-error-text {
    color: red;
    font-size: 13px;
    margin-top: 5px;
}

.contacts-view__form-error-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    background: #f95d51;
    padding: 20px;
}

.contacts-view__form-error-banner-text {
    color: #fff;
    text-align: center;
}

.contacts-view__form-success {
    background: var(--main-green);
    font-size: 20px;
    padding: 20px;
    color: #fff;
    text-align: center;
    line-height: 1.5;
}

@media (min-width: 640px) {
    .contacts-view__form-input {
        width: 600px;
        max-width: 600px;
        font-size: 16px;
    }
    .contacts-view__form-textarea {
        max-width: 600px;
        font-size: 16px;
    }
    .contacts-view__form-input-wrap {
        max-width: 600px;
    }
}

@media (min-width: 960px) {
    .contacts-view__form--wrap {
        flex-direction: row;
        align-items: flex-start;
    }
    .contacts-view__form-info {
        align-items: flex-start;
        width: 49%;
    }
    .contacts-view__form-input-wrap {
        width: 49%;
    }
    .contacts-view__form-info-text {
        text-align: start;
        line-height: 23px;
    }
    .contacts-view__main-text {
        text-align: center;
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .contacts-view__main-title {
        margin-top: 70px;
    }
    .contacts-view__form {
        max-width: 1200px;
        padding: 70px 0 30px;
    }
    .contacts-view__main-wrap {
        margin-bottom: 70px;
    }
    .contacts-view__form-input {
        width: 100%;
    }
}
@media (min-width: 1200px) {
    .contacts-view__main-wrap {
        padding: 0 30px;
    }
    .contacts-view__form-wrap {
        padding: 0 30px;
    }
    .contacts-view__main-text {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1680px) {
    .contacts-view__main {
        max-width: 1500px;
    }
    .contacts-view__main-img {
        max-height: 100%;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .contacts-view__form {
        max-width: 1500px;
    }
    .contacts-view__form--wrap {
        align-items: center;
        justify-content: center;
    }
    .contacts-view__main-text {
        max-width: 1255px;
    }
}
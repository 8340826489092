.layout {
    position: relative;
    min-width: calc(100dvw - 17px);
    width: 100%;
    z-index: 100;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

.layout__modal {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    background: #fff;
    max-width: 670px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    overflow: auto;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
}

.layout__modal-btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
    transition: .3s;
}

.layout__modal-btn-close:hover {
    opacity: .6;
}

@media (min-width: 640px) {
    .layout {
        padding-top: 0;
    }
}
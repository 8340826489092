.app__show-message-wrap {
    position: relative;
    z-index: 100000000000000000;
}

.app__show-message-wrap .MuiPaper-root {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.app__show-message-wrap .MuiSvgIcon-root {
    color: var(--main-green);
}

.swiper-horizontal {
    touch-action: auto;
}
.user-product-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #D8D8D8;
}

.user-product-card--variations {
    padding-left: 30px;
}

.user-product-card--hide {
    opacity: .6;
}

.user-product-card__text {
    flex: 1 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    -webkit-text-size-adjust: 100%;
}

.user-product-card__text--is-user-products {
    flex: 1 1;
    display: flex;
    gap: 10px;
    align-items: center;
}

.user-product-card__text-img {
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.user-product-card__text-info {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.user-product-card__text-info-name-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 800px;
}

.user-product-card__text-info-name {
    min-width: 180px;
    color: var(--dark-charcoal);
    font-size: 14px;
    cursor: pointer;
    transition: .3s;
}

.user-product-card__text-info-name:hover {
    color: var(--main-green);
}

.user-product-card__text-info-variations-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-product-card__text-info-variations {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    color: #2A7F77;
    text-decoration: underline;
}

.user-product-card__text-info-category {
    display: inline-block;
    padding: 0;
    color: #2A7F77;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 150px;
}

.user-product-card__text-info-category-text {
    position: relative;
    display: inline-block;
    width: max-content;
}

.user-product-card__text-info-count {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.user-product-card__text-info-price {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.user-product-card__text-variations-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 190px;
}

.user-product-card__text-variations {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.user-product-card__text-info-variations-arrow {
    width: 20px;
    height: 20px;
    margin-top: -1px;
    transform: rotate(-90deg);
    transition: .3s;
}

.user-product-card__text-info-variations-arrow--active {
    transform: rotate(90deg) translateX(3px);
    transition: .3s;
}

.user-product-card__text-main-product {
    width: 131px;
    text-align: end;
    color: #2A7F77;
    font-size: 13px;
    margin-right: 5px;
}

.user-product-card__text-img--is-order-read-img-wrap {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.user-product-card__text-img--is-order-read {
    width: 120px;
    height: 120px;
}

.user-product-card__text-info-price-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.user-product-card__text-info-name--is-order-read {
    min-width: 164px;
    font-size: 15px;
    font-weight: 500;
}

.user-product-card__text-info-text {
    display: none;
}
.user-product-card__text-info-text--is-order-read {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}

.user-product-card__text-info-title--is-order-read {

}

.user-product-card__text-info-text-wrap--is-order-read {

}

.user-product-card__text-info-text-count--is-order-read {
    text-align: center;
}
.user-product-card__text-info-text-right--is-order-read {
    text-align: right;
}
.user-product-card__text-info-price-wrap--is-order-read {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.user-product-card__text-info-text-wrap--is-creating-collection {
    display: flex;
    gap: 5px;
    align-items: center;
}

.user-product-card__text-info-text--is-creating-collection {
    font-size: 12px;
}

.user-product-card__variations-products-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: fit-content;
    background: #F9F9F9;
}

.user-product-card__text-info-name--is-product {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
}

.user-product-card--is-user-products {
    width: auto;
}

.user-product-card__text-info-category--is-order-read {
    display: none !important;
}

.user-product-card__text-info--product-view {
    font-size: 13px;
    flex-direction: column;
    align-items: flex-start;
}

.user-product-card__text-info-variations--product-view {
    font-size: 13px;
}

.user-product-card--selected {
    background: #89898926;
}

.user-product-card__text-main-product-text {
    font-size: 13px;
    text-align: center;
}

.user-product-card__text-img--is-creating {
    margin-left: 5px;
}

.user-product-card__text-variations-wrap--mob {
}

.user-product-card__text-variations-wrap--des {
    display: none;
}

.user-product-card__text-info-variations-wrap--des {
    display: none;
}

.user-product-card__text-info-variations-wrap--mob {

}

.user-product-card--is-criete-product {
    gap: 5px;
}

.user-product-card__text-variations-title {
    font-size: 13px;
    font-weight: 500;
}

.user-product-card__text-variations-material-title-wrap {
    display: flex;
    gap: 3px;
}

.user-product-card__text-variations-material-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


@media (min-width: 768px) {
    .user-product-card__text-img--is-order-read {
        width: 60px;
        height: 60px;
    }
    .user-product-card__text {
        align-items: center;
        flex-direction: row;
    }
    .user-product-card__text-info-name--is-order-read {
        max-width: 164px;
    }
    .creating-collection__products--wrap {
        padding: 14px 0;
    }
    .user-product-card__text-info-name--is-order-read {
        font-weight: 400;
    }
    .user-product-card__text-info-category {
        padding: 3px 0;
        width: 150px;
    }
    .user-product-card__text-info-category--is-order-read {
        display: block !important;
    }
    .user-product-card__text-info--product-view {
        flex-direction: row;
        align-items: center;
    }
    .user-product-card__text-main-product {
        white-space: nowrap;
        font-size: 14px;
    }
    .user-product-card__text-info-name--is-product {
        width: 300px;
        font-weight: 400;
    }
    .user-product-card--is-user-products {
        min-width: 1040px;
    }
    .user-product-card--variations {
        min-width: 1040px;
    }
    .user-product-card__text-info-variations-arrow {
        width: 25px;
        height: 25px;
        margin-top: 0;
    }
    .user-product-card__text-info {
        gap: 10px;
    }
    .user-product-card__text-variations-wrap--mob {
        display: none;
    }
    .user-product-card__text-variations-wrap--des {
        display: block;
    }
    .user-product-card__text-info-variations-wrap--des {
        display: block;
    }
    .user-product-card__text-info-variations-wrap--mob {
        display: none;
        
    }
    .user-product-card--is-criete-product {
        min-width: 940px;
        gap: 10px;
    }
    .user-product-card__text-variations {
        font-size: 14px;
    }
    
}
@media (min-width: 1250px) {
    .user-product-card__text-img--is-order-read {
        width: 70px;
        height: 70px;
    }
    .user-product-card__text-info-name--is-order-read {
        max-width: 350px;
    }
}
@media (min-width: 1300px) {
    .user-product-card__text-variations-wrap--des {
        width: 290px;
    } 
}
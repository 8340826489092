.catalog-view {
	color: var(--dark-charcoal);
}

.catalog-view-wrap {
	padding: 0 10px;
}

.catalog-view__mobile-filter-accordion.MuiPaper-root {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
		0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
}

.catalog-view__mobile-filter-accordion.MuiPaper-root::before {
	display: none;
}

.catalog-view__mobile-filter-accordion {
	border-bottom: 1px solid #d8d8d8;
	width: 100%;
}

.catalog-view__mobile-filter-accordion.MuiPaper-root {
	margin: 0 !important;
}
.catalog-view__mobile-filter-accordion .MuiButtonBase-root.Mui-expanded {
	min-height: 54px !important;
}

.catalog-view__mobile-filter-accordion-items-wrap {
	display: flex;
	gap: 8px;
	flex-direction: column;
	max-height: 293px;
	overflow: hidden;
}

.catalog-view__banner-wrap {
	background: #f9f9f9;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 60px;
	opacity: 0;
	transition: 0.8s;
}

.catalog-view__mobile-filter-wrap {
	margin-bottom: 20px;
}

.catalog-view__mobile-filter-btn {
	display: flex;
	gap: 5px;
	margin-top: 15px;
	margin-bottom: 20px;
	align-items: center;
}

.catalog-view__mobile-filter-btn-img {
	width: 12px;
	height: 17px;
}

.catalog-view__mobile-filter-btn-text {
	font-weight: 500;
}

.catalog-view__mobile-filter-accordion-sort-wrap {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: baseline;
}

.catalog-view__mobile-filter-accordion-sort {
	width: 100%;
	text-align: inherit;
	padding: 3px 6px;
	font-size: 14px;
}

.catalog-view__mobile-filter-accordion-res {
	font-weight: 400;
}

.catalog-view__title {
	color: var(--dark-charcoal);
	font-size: 22px;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 5px;
}

.catalog-view__description {
	color: var(--purplish-gray);
	font-size: 13px;
	font-weight: 400;
	line-height: 21px;
	margin-top: 15px;
}

.catalog-view__sort-wrap {
	display: none;
	gap: 25px;
	justify-content: end;
	color: rgba(58, 58, 58, 1);
}

.catalog-view__sort-wrap .MuiInputBase-root {
	border-radius: 0;
}

.catalog-view__sort-wrap .MuiOutlinedInput-notchedOutline {
	border-color: rgba(121, 116, 126, 1);
}

.catalog-view__sort-select {
	min-width: 224px;
	height: 27.2px;
	font-size: 12px !important;
	background: #fff;
	font-family: Montserrat !important;
}

.catalog-view__sort-select .MuiSvgIcon-root {
	width: 16px !important;
	height: 16px !important;
	top: calc(50% - 0.3em) !important;
}

.catalog-view__sort-select .MuiNativeSelect-select {
	padding: 10px 14px 10px 20px;
	font-size: 12px;
}

.MuiPaper-root {
	border-radius: 0 !important;
	background-color: #fff !important;
}

.MuiButtonBase-root:hover {
	background-color: #fff !important;
	color: var(--main-green) !important;
}

.catalog-view__products-filter-wrap {
	position: relative;
	display: none;
	flex-direction: column;
	gap: 15px;
}

.catalog-view__products-filter {
	width: 230px;
}

.catalog-view__products-filter .MuiBox-root {
	width: 210px !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.catalog-view__modal-filter .MuiAccordionSummary-content {
	margin: 17.5px 0 !important;
}

.catalog-view__products-filter .MuiAccordionSummary-content {
	margin: 8px 0 !important;
}

.catalog-view__products-filter .MuiSvgIcon-root {
	width: 18px !important;
	height: 18px !important;
}

.catalog-view__products-filter .MuiAccordionDetails-root {
	padding: 5px 7px 18px !important;
}

.catalog-view__products-filter .MuiButtonBase-root {
	padding: 0;
	min-height: 35px !important;
}

.catalog-view__products-filter .MuiAccordionSummary-expandIconWrapper {
	color: var(--purplish-gray);
}

.catalog-view__products-filter .MuiPaper-root {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	box-shadow: none !important;
	border-bottom: 1px solid var(--neutral-gray);
}

.catalog-view__products-filter-title {
	font-size: 14px;
	font-weight: 600;
	line-height: 150%;
	color: var(--dark-charcoal);
}

.catalog-view__products-filter-title-price {
	margin-bottom: 4px;
}

.catalog-view__products-filter-label-mobile-wrap {
	display: flex;
	flex-direction: column;
	gap: 17px;
	width: 100%;
}

.catalog-view__products-filter-label-wrap {
	display: flex;
	flex-direction: column;
	gap: 17px;
	width: 100%;
	overflow: hidden;
}

.catalog-view__products-filter-label-all {
	max-height: 580px;
	overflow: auto;
}

.catalog-view__products-filter-label {
	display: flex;
	gap: 10px;
	align-items: center;
}

.catalog-view__products-filter-label-active {
	order: -1;
}

.catalog-view__products-filter-checkbox {
	position: absolute;
	z-index: -10;
	width: 0;
	height: 0;
	opacity: 0;
}

.catalog-view__products-filter-checkbox-custom {
	position: relative;
	height: 15px;
	min-height: 15px;
	width: 15px;
	min-width: 15px;
	border: 1px solid var(--neutral-gray);
}

.catalog-view__products-filter-checkbox-custom-active::after {
	content: '';
	position: absolute;
	left: 5px;
	top: 0;
	width: 5px;
	height: 11px;
	border: solid rgba(74, 75, 87, 1);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.catalog-view__products-filter-checkbox-custom-active.catalog-view__products-filter-checkbox-custom {
	opacity: 1;
}

.catalog-view__products-filter-text {
	line-height: 1.55;
	color: var(--purplish-gray);
	font-size: 14px;
	max-width: 300px;
}

.catalog-view__products-filter-all-btn {
	position: relative;
	z-index: 1;
	margin-top: 8px;
	color: rgba(74, 75, 87, 1);
	border-bottom: 1px dashed rgba(74, 75, 87, 1);
	padding-bottom: 2px;
	font-size: 13px;
}

.catalog-view__products-filter-price-result-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 3px;
	margin-top: 1px;
	margin-bottom: 7px;
}

.catalog-view__products-filter-price-result--wrap {
	position: relative;
	border: 1px solid var(--neutral-gray);
	width: 45%;
	padding: 10px 8px;
	transition: 0.3s;
}

.catalog-view__products-filter-price-result-title {
	position: absolute;
	top: -9px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 10px;
	background: #fff;
	padding: 0 3px;
	color: var(--purplish-gray);
}

.catalog-view__products-filter-price-result-btn {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 11px;
	height: 11px;
	cursor: pointer;
}

.catalog-view__products-filter-price-result {
	width: 100%;
	padding-right: 25px;
	font-family: 'Montserrat';
	font-size: 15px;
}

.catalog-view__products-filter-price-result-line {
	width: 10px;
	background-color: #000;
	height: 1px;
}

.catalog-view__products-filter-slider .MuiSlider-thumb {
	width: 18px;
	height: 18px;
	color: #fff;
}

.catalog-view__products-filter-slider {
	color: var(--main-green) !important;
	height: 2px !important;
}

.catalog-view__products-wrap {
	display: flex;
	gap: 20px;
	margin-bottom: 60px;
}

.catalog-view__products--wrap {
	flex: 1 1;
}

.catalog-view__products {
	position: relative;
	display: flex;
	gap: 0;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex: 1 1;
	width: calc(100% + 8px);
	margin-left: -4px;
	overflow: hidden;
}

.catalog-view__product {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	min-width: 0;
	width: 45%;
	padding: 4px;
	border: 1px solid #fff;
	cursor: pointer;
	margin-bottom: 30px;
	transition: 0.3s;
}

.catalog-view__product:hover {
	border: 1px solid var(--main-green);
}

.catalog-view__products-error {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(130, 130, 130, 0.5);
	color: rgba(130, 130, 130, 0.7);
	width: 100%;
	padding: 40px;
	height: 285px;
}

.catalog-view__products-create {
	text-align: center;
	margin-top: 20px;
}

.catalog-view__products-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 30px;
}

.catalog-view__sort--wrap {
	display: none;
	align-items: flex-start;
	gap: 20px;
	justify-content: space-between;
	position: sticky;
	top: 58px;
	background: #fff;
	z-index: 2;
	padding-bottom: 15px;
	padding-top: 15px;
	margin-left: -20px;
	padding-left: 20px;
	margin-bottom: 25px;
}

.catalog-view__sort--wrap1 {
	display: none;
	align-items: flex-start;
	gap: 20px;
	justify-content: space-between;
	position: sticky;
	top: 94.5px;
	background: #fff;
	z-index: 1;
	padding-top: 15px;
	margin-bottom: 5px;
}

.catalog-view__filter-search {
	display: flex;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;
}

.catalog-view__filter-search-title {
	font-size: 12px;
	font-weight: 400;
	line-height: 150%;
	color: var(--neutral-gray);
	margin-bottom: 15px;
}

.catalog-view__filter-search-item {
	display: flex;
	align-items: center;
	gap: 14px;
	border-radius: 50px;
	padding: 6px 16px;
	font-size: 12px;
	font-weight: 500;
	background: #ededf0;
}

.catalog-view__filter-search-item-img {
	width: 7px;
	height: 7px;
}

.catalog-view__filter-search-all-btn {
	border: 1px solid var(--main-green);
	border-radius: 50px;
	padding: 5.2px 16px;
	font-size: 12px;
}

.catalog-view__modal-filter-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100dvh;
	overflow: auto;
	outline: none !important;
	box-shadow: none !important;
	background: #fff;
}

.catalog-view__modal-filter-btn-wrap {
	width: 100%;
	z-index: 10;
	display: flex;
	align-items: flex-end;
	flex: 1 1;
	gap: 10px;
	justify-content: space-between;
	padding: 18px 15px 24px 15px;
	background: #fff;
}

.catalog-view__modal-filter-btn {
	width: 49%;
	padding: 13.3px 3px;
}

.catalog-view__modal-filter-header {
	width: 100%;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	padding: 15px 20px 15px 15px;
	background: #fff;
	border-top: 1px solid #d8d8d8;
	border-bottom: 1px solid #d8d8d8;
}

.catalog-view__modal-filter-header-text {
	flex: 1 1;
	font-size: 16px;
	font-weight: 700;
}

.catalog-view__modal-filter--wrap {
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.catalog-view__modal-filter {
	align-items: center;
	z-index: 99;
	padding: 15px;
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 15px 9px;
	height: calc(100vh - 155px);
	--webkit-height: calc(100vh - 155px);
	overflow: auto;
}

.catalog-view__modal-filter-btn-close {
	position: absolute;
	top: 20px;
	right: 34px;
	width: 12px;
	height: 12px;
	cursor: pointer;
	z-index: 100;
	transition: 0.3s;
}

.catalog-view__mobile-filter-accordion-res-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.catalog-view__mobile-filter-accordion-res {
	font-weight: 400;
}

.catalog-view__modal-filter-btn-close:hover {
	opacity: 0.6;
}

.catalog-view__link-category-parent-arrow-img {
	width: 20px;
	height: 20px;
	transform: rotate(90deg);
}

.catalog-view__link-category-parent-arrow-img-path {
	fill: #49454f;
	transition: 0.3s;
}

.catalog-view__mobile-filter-accordion-items-all-wrap {
	max-height: fit-content;
}

.catalog-view__modal-close {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 70px;
}

.catalog-view__modal-filter-is-safari-wrap {
}

.catalog-view__modal-filter-is-safari {
	height: calc(100dvh - 100px);
	--webkit-height: calc(100dvh - 100px);
}

.catalog-view__products-filter-radio-label {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 8px;
}

.catalog-view__products-filter-radio-checkbox {
	position: absolute;
	z-index: -10;
	width: 0;
	height: 0;
	opacity: 0;
}

.catalog-view__products-filter-radio-checkbox-custom {
	position: relative;
	height: 15px;
	width: 15px;
	border: 1px solid var(--neutral-gray);
	border-radius: 50%;
}

.catalog-view__products-filter-radio-checkbox-custom-active::after {
	content: '';
	position: absolute;
	left: 51%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 9px;
	height: 9px;
	background: var(--neutral-gray);
	border-radius: 50%;
}

.catalog-view__products-filter-radio-checkbox-custom-active.catalog-view__products-filter-checkbox-custom {
	opacity: 1;
}

.catalog-view__products-filter-radio-text {
	line-height: 1.55;
	color: var(--purplish-gray);
	font-size: 14px;
	max-width: 300px;
}

.catalog-view__products-filter-price-result--active-wrap {
	border: 1px solid var(--main-green);
}

.catalog-view__skeleton-title {
	width: 100%;
	max-width: 290px;
	height: 27.2px !important;
}

.catalog-view__skeleton-sub-title {
	width: 100%;
	max-width: 150px;
	height: 18px !important;
}

.catalog-view__products-filter-preloader {
	position: absolute;
	min-height: 200px;
	top: 133px;
	left: 0;
	background: #fff;
	width: 100%;
	z-index: 2;
}

.catalog-view__banner-wrap--active {
	animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.catalog-view__modal-filter-wrap--is-preloader {
	position: relative;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 12;
}

.catalog-view__product--is-preloader {
}

.catalog-view__products-filter-price-result--error {
	border: 1px solid red;
}

@media (min-width: 240px) {
	.catalog-view__product {
		width: 45.5%;
		max-width: 50%;
	}
}
@media (min-width: 640px) {
	.catalog-view__filter-search-item {
		font-size: 12px;
	}
	.catalog-view__filter-search-all-btn {
		font-size: 12px;
	}
	.catalog-view__product {
		width: 33.3%;
		max-width: 33.3%;
	}
	.catalog-view__products--wrap {
		margin-top: 16px;
	}
	.catalog-view-wrap {
		padding: 0 15px;
	}
	.catalog-view__banner-wrap {
		margin-left: -15px;
		margin-right: -15px;
	}
	.catalog-view__modal-filter-btn-wrap {
		padding: 13px 15px 25px 15px;
	}
}
@media (min-width: 960px) {
	.catalog-view__title {
		font-size: 24px;
	}
	.catalog-view__products-filter-text {
		font-size: 12px;
		max-width: 200px;
	}
	.catalog-view__sort-wrap {
		display: flex;
	}
	.catalog-view__products-filter-wrap {
		display: flex;
	}
	.catalog-view__mobile-filter-wrap {
		display: none;
	}
	.catalog-view__product {
		width: 34%;
		max-width: 33.3%;
	}
	.catalog-view__sort--wrap,
	.catalog-view__sort--wrap1 {
		display: flex;
	}
	.catalog-view__description {
		max-width: 70%;
	}
	.catalog-view__products--wrap {
		margin-top: 34px;
	}
	.catalog-view__modal-filter {
		gap: 11px;
	}
	.catalog-view__mobile-filter-accordion-sort {
		font-size: 16px;
	}
	.catalog-view__filter-search-item-img {
		width: 8px;
		height: 8px;
	}
	.catalog-view__skeleton-title {
		height: 29.6px !important;
	}
	.catalog-view__products-filter-preloader {
		height: 15000px;
	}
}
@media (min-width: 1200px) {
	.catalog-view__product {
		width: 24.5%;
		max-width: 25%;
	}
	.catalog-view__title {
		font-size: 25px;
	}
	.catalog-view__products-error {
		font-size: 18px;
	}
	.catalog-view-wrap {
		padding: 0 30px;
	}
	.catalog-view__banner-wrap {
		margin-bottom: 70px;
		margin-left: -30px;
		margin-right: -30px;
	}
	.catalog-view__sort--wrap1 {
		top: 100.5px;
		padding-top: 17px;
	}
	.catalog-view__products-wrap {
		margin-bottom: 70px;
	}
	.catalog-view__description {
		font-size: 13px;
	}
	.catalog-view__products-filter-text {
		font-size: 13px;
	}
	.catalog-view__products-filter {
		width: 235px;
	}
	.catalog-view__sort-select {
		font-size: 13px !important;
	}
	.catalog-view__sort-select .MuiNativeSelect-select {
		font-size: 13px;
	}
	.catalog-view__skeleton-title {
		height: 30.4px !important;
	}
	.catalog-view__products-filter-label-all {
		max-height: 580px;
		overflow: auto;
	}
}
@media (min-width: 1680px) {
	.catalog-view__title {
		font-size: 28px;
	}
	.catalog-view__filter-search-title {
		font-size: 14px;
	}
	.catalog-view__description {
		font-size: 14px;
	}
	.catalog-view__sort-select .MuiNativeSelect-select {
		padding: 10px 14px 10px 20px;
		font-size: 12px;
	}
	.catalog-view__filter-search-item {
		font-size: 14px;
		padding: 7px 16px;
	}
	.catalog-view__filter-search-all-btn {
		font-size: 14px;
		padding: 6.8px 16px;
		height: 30.8px;
	}
	.catalog-view__products-filter-title {
		font-size: 14px;
		font-weight: 600;
	}
	.catalog-view__products-filter-slider .MuiSlider-thumb {
		width: 25px;
		height: 25px;
	}
	.catalog-view__products-filter-title-price {
		margin-bottom: 10px;
	}
	.catalog-view__products-filter-price-result-title {
		font-size: 12px;
	}
	.catalog-view__products-filter-price-result-wrap {
		margin-top: 15px;
	}
	.catalog-view__products-filter-price-result--wrap {
		padding: 15px 8px;
	}
	.catalog-view__products-filter-text {
		font-size: 14px;
	}
	.catalog-view__products-filter-checkbox-custom {
		height: 18px;
		width: 18px;
	}
	.catalog-view__products-filter-checkbox-custom-active::after {
		left: 7px;
		top: 2px;
	}
	.catalog-view__sort-select {
		font-size: 14px !important;
	}
	.catalog-view__products-filter {
		width: 249px;
	}
	.catalog-view__products-filter-text {
		max-width: none;
	}
	.catalog-view__product {
		padding: 6px;
	}
	.catalog-view__products {
		width: calc(100% + 12px);
		margin-left: -6px;
	}
	.catalog-view__sort-select {
		height: 30.8px;
	}
	.catalog-view__skeleton-title {
		height: 34.4px !important;
	}
	.catalog-view__skeleton-sub-title {
		height: 21px !important;
	}
	.catalog-view__products-filter-preloader {
		top: 145px;
	}
}

.order-view__title-wrap {
	position: relative;
	color: #49454f;
	font-size: var(--text-size);
}

.order-view__title-img {
	height: 550px;
	min-height: 295px;
}

.order-view__main-text-btn--1--disabled {
	opacity: 0.6;
	pointer-events: none;
}

.order-view__title-text-wrap {
	position: absolute;
	top: -3px;
	left: 0;
	width: 100%;
	height: 248px;
	padding: 25px 15px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.order-view__title-text {
	line-height: 33px;
	font-size: 20px;
	line-height: 1.45;
	font-weight: 700;
	margin-bottom: 35px;
	max-width: 836px;
	color: var(--dark-charcoal);
	font-size: 21px;
	margin-bottom: 25px;
}

.order-view__who-wrap {
}

.order-view__who {
	padding: 60px 15px;
	max-width: 1200px;
	text-align: center;
}

.order-view__who-title {
	font-size: var(--main-title-size);
	font-weight: 700;
	margin-bottom: 45px;
	color: var(--dark-charcoal);
	line-height: 1.17;
}

.order-view__who-text {
	font-size: var(--title-size);
	line-height: 1.55;
	margin-bottom: 100px;
}

.order-view__who-items {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 60px;
}

.order-view__who-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.order-view__who-item-img {
	width: 75px;
	height: 75px;
}

.order-view__who-item-text {
	font-weight: 500;
	font-size: 15px;
	color: #49454f;
}

.order-view__how-wrap {
}

.order-view__how {
	padding: 60px 15px;
	max-width: 1200px;
}

.order-view__how-title {
	font-size: var(--main-title-size);
	font-weight: 700;
	margin-bottom: 45px;
	text-align: center;
	color: var(--dark-charcoal);
	line-height: 1.17;
}

.order-view__how-text {
	text-align: center;
	margin-bottom: 45px;
	font-size: 15px;
	color: #49454f;
	font-weight: 400;
}

.order-view__how-items {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.order-view__how-item {
	padding: 30px 30px;
	background-color: rgb(241, 242, 242);
	max-width: 362px;
	margin-left: auto;
	margin-right: auto;
}

.order-view__how-item-img {
	width: 65px;
	height: 65px;
	margin-bottom: 20px;
}

.order-view__how-item-title {
	font-size: var(--title-size);
	font-weight: 700;
	margin-bottom: 20px;
	color: var(--dark-charcoal);
}

.order-view__how-item-text {
	font-size: var(--text-size);
	line-height: 1.55;
	color: #49454f;
}

.order-view__category-wrap {
	max-width: 1200px;
	padding: 60px 15px 0;
}

.order-view__category-title {
	font-size: var(--main-title-size);
	font-weight: 700;
	text-align: center;
	margin-bottom: 45px;
	color: var(--dark-charcoal);
	line-height: 1.17;
}

.order-view__category-text {
	text-align: center;
	font-size: 15px;
	color: #49454f;
}

.order-view__form-wrap {
	background-color: #f1f2f2;
}

.order-view__form {
	max-width: 600px;
	padding: 60px 15px;
}

.order-view__form--wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.order-view__form-info {
	display: flex;
	flex-direction: column;
}

.order-view__form-info-title {
	font-weight: 700;
	margin-bottom: 45px;
	font-size: var(--main-title-size);
	color: var(--dark-charcoal);
	line-height: 1.17;
}

.order-view__form-input-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 600px;
	font-size: 15px;
}

.order-view__form-input {
	height: 40px;
	line-height: 1.33;
	width: 100%;
	font-size: 14px;
	border-bottom: 1px solid #2e2e2e;
	margin-bottom: 20px;
	border-radius: 0;
	-webkit-border-radius: 0;
	color: #49454f;
}

.order-view__form-input::placeholder {
	color: #8f9090;
}

.order-view__form-input:-webkit-autofill {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #f1f2f2 inset;
	caret-color: #19191d;
}

.order-view__form-input:-webkit-autofill:focus {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #f1f2f2 inset;
	caret-color: #19191d;
}

.order-view__form-input-upload-wrap {
	width: fit-content;
}

.order-view__form-input-upload {
	position: absolute;
	width: 0;
	height: 0;
	z-index: -100;
	opacity: 0;
}

.order-view__form-input-upload-lable {
	margin-bottom: 5px;
}

.order-view__form-input-upload-btn {
	margin-bottom: 20px;
	width: fit-content;
}

.order-view__upload-result-wrap {
	position: relative;
	width: fit-content;
}

.order-view__upload-result-del-btn {
	width: 20px;
	height: 20px;
}

.order-view__upload-result-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	background: #fff;
	z-index: 1;
}

.order-view__upload-result-img {
	width: 100%;
	max-width: 125px;
	max-height: 125px;
	height: 125px;
	object-fit: contain;
	object-position: top;
}

.order-view__form-input-sub-label {
	font-size: 14px;
	color: #8f9090;
	margin-bottom: 21px;
}

.order-view__upload-result--wrap {
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-bottom: 15px;
}

.order-view__category-cards-wrap {
	padding: 0 15px 20px;
}

.order-view__form-input-label {
	margin-bottom: 5px;
	color: #49454f;
}

.order-view__form-textarea {
	outline: none;
	border: none;
	background: #f1f2f2;
	min-height: 82px;
	resize: none;
	border-bottom: 1px solid #2e2e2e;
	font-size: 14px;
	font-family: 'Montserrat';
	margin-bottom: 20px;
	border-radius: 0;
	-webkit-border-radius: 0;
	color: #49454f;
	padding: 0;
	caret-color: #19191d;
}

.order-view__main-text-btn--1 {
	position: relative;
	font-weight: 500;
	max-width: 600px;
	width: 100%;
	font-size: 15px;
	height: 60px;
}

.order-view__title-img--1 {
}

.order-view__title-img--2 {
	display: none;
}

.order-view__form-input-label--phone {
}

.order-view__upload-result-img--wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.order-view__form-error-wrap {
	position: relative;
	width: 100%;
}

.order-view__form-error-text {
	color: red;
	font-size: 13px;
	margin-top: 5px;
	margin-bottom: 15px;
}

.order-view__form-error-text--phone {
	position: absolute;
	bottom: 0;
	left: 0;
	color: red;
	font-size: 13px;
}

.order-view__form-error {
	border-bottom: 1px solid red !important;
	margin-bottom: 0 !important;
}

.order-view__form-error--phone {
	margin-bottom: 15px;
}

.order-view__upload-result-title--error {
	color: red;
}

.order-view__loader-btn {
	display: none;
}

.order-view__loader-btn-send {
	width: 40px;
	display: inline-block;
	position: absolute;
	top: 10px;
	left: 46%;
	padding: 8px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: #25b09b;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}
@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.order-view__upload-result-img-error {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	padding: 2px;
	text-align: center;
	width: 85%;
	color: red;
}

.order-view__btn-del {
	opacity: 0;
	animation: fadeIn 0.3s ease-in 0.3s forwards;
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

@media (min-width: 480px) {
	.order-view__title-text-wrap {
		top: 6%;
		max-width: 460px;
	}
	.order-view__title-text {
		font-size: 22px;
	}
}
@media (min-width: 640px) {
	.order-view__title-text {
		font-size: var(--main-title-size);
	}
	.order-view__title-img {
		height: 600px;
	}
	.order-view__who-item-text {
		font-size: var(--medium-text-size);
	}
	.order-view__form-input-label {
		font-size: 16px;
	}
	.order-view__form-input {
		font-size: 15px;
	}
	.order-view__form-textarea {
		font-size: 15px;
	}
	.order-view__title-text-wrap {
		max-width: 817px;
	}
	.order-view__form {
		padding: 60px 0;
	}
	.order-view__form {
		padding: 70px 0;
	}
	.order-view__main-text-btn--1 {
		font-size: 16px;
	}
}

@media (min-width: 960px) {
	.order-view__who-items {
		flex-direction: row;
		justify-content: space-between;
	}
	.order-view__who-item {
		width: 27%;
	}
	.order-view__how-items {
		flex-direction: row;
		justify-content: space-between;
	}
	.order-view__how-item {
		width: 31%;
	}
	.order-view__who {
		padding: 70px 15px;
	}
	.order-view__how {
		padding: 70px 15px;
	}
	.order-view__form {
		padding: 70px 0;
	}
	.order-view__category-wrap {
		padding: 70px 15px 0;
	}
	.order-view__who-item-img {
		width: 100px;
		height: 100px;
	}
	.order-view__how-text {
		font-size: var(--text-size);
	}
	.order-view__category-text {
		font-size: var(--text-size);
	}
	.order-view__title-text {
		font-size: 27px;
	}
	.order-view__title-text-wrap {
		left: 6%;
	}
}
@media (min-width: 1200px) {
	.order-view__who {
		padding: 70px 30px;
	}
	.order-view__how {
		padding: 70px 30px;
	}
	.order-view__form {
		padding: 70px 0;
	}
	.order-view__category-wrap {
		padding: 70px 30px 0;
	}
	.order-view__title-img {
		height: auto;
		max-height: 877px;
	}
	.order-view__title-img--1 {
		display: none;
	}
	.order-view__title-img--2 {
		display: block;
	}
	.order-view__title-text-wrap {
		padding: 25px 30px 0;
		top: 9%;
	}
	.order-view__title-text {
		font-size: 32px;
		margin-bottom: 35px;
	}
	.order-view__how-text {
		font-size: 18px;
	}
	.order-view__who-item-text {
		font-size: 18px;
	}
	.order-view__category-cards-wrap {
		padding: 0 30px 20px;
		max-width: 2560px;
	}
	.order-view__category-text {
		font-size: 18px;
	}
}
@media (min-width: 1500px) {
	.order-view__title-text-wrap {
		max-width: 845px;
	}
	.order-view__title-text {
		font-size: 35px;
	}
	.order-view__title-img--2 {
		max-height: 625px;
	}
}
@media (min-width: 1680px) {
	.order-view__category-wrap {
		max-width: 1400px;
	}
	.order-view__how {
		max-width: 1400px;
	}
	.order-view__who {
		max-width: 1400px;
	}
	.order-view__how-item {
		padding: 30px 30px 40px;
		max-width: 400px;
	}
}

.delivery-view {
    font-size: var(--text-size);
    color: #49454F;
}

.delivery-view--wrap {
}

.delivery-view__title-wrap {
    background-image: -webkit-linear-gradient(top, rgba(241,242,242,1), rgba(241,242,242,1));
    padding: 68px 20px;
}

.delivery-view__title {
    font-size: var(--main-title-size);
    font-weight: 700;
    max-width: 1200px;
    text-align: center;
    color: var(--dark-charcoal)
}

.delivery-view__info-wrap {
    display: flex;
    flex-direction: column;
    gap: 120px;
    max-width: 1200px;
    padding: 60px 15px 20px;
}

.delivery-view__info {
    display: flex;
    flex-direction: column;
    gap: 45px;
    justify-content: space-between;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}
.delivery-view__info-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    color: var(--dark-charcoal);
    line-height: 1.17;
}
.delivery-view__info-text {
    line-height: 1.55;
}

.delivery-view__info-text-ul {
    padding-left: 17px;
}

.delivery-view__info-text-link {
    color: var(--main-green);
    margin-left: 5px;
}

.delivery-view__banner-wrap {

}

@media (min-width: 960px) {
    .delivery-view__info {
        flex-direction: row;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .delivery-view__info-text {
        max-width: 63%;
    }
    .delivery-view__info-wrap {
        gap: 140px;
        padding: 70px 15px 30px;
    }
}
@media (min-width: 1200px) {
    .delivery-view__info-wrap {
        padding: 70px 30px 30px;
    }
}
@media (min-width: 1680px) {
    .delivery-view__info-wrap {
        max-width: 1400px;
    }
}
.about-marketplace {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-green);
    color: #fff;
    padding: 30px 15px 30px 15px;
    min-height: 600px;
}

.about-marketplace__text-wrap {
}

.about-marketplace__text-title {
    line-height: 25px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.about-marketplace__text {
    margin-bottom: 30px;
    line-height: 22.5px;
    font-size: 15px;
    font-weight: 400;
}

.about-marketplace__img {
    width: 100%;
    object-fit: contain;
    height: auto;
    margin-bottom: 27px;
}

.about-marketplace__btn-wrap {
    width: fit-content;
    display: none;
    margin-top: 29px;
}

.about-marketplace__btn-mobile-wrap {
    width: fit-content;
    margin-right: auto;
}

.about-marketplace__text--1 {
    display: none;
}

.about-marketplace__img-mobile {
}

.about-marketplace__img-des {
    display: none;
}

@media (min-width: 640px) {
    .about-marketplace__text-title {
        line-height: 1.25;
        font-size: 24px;
        margin-bottom: 40px;
    }
    .about-marketplace {
        height: auto;
        min-height: auto;
        padding: 50px 15px 50px 15px;
    }
    .about-marketplace__img {
        margin-bottom: 0;
        width: 93%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    .about-marketplace {
        justify-content: space-between;
    }
    .about-marketplace__text {
        margin-bottom: 40px;
    }
}
@media (min-width: 750px) {
    .about-marketplace {
        flex-direction: row;
        padding: 30px 15px 30px 25px;
        height: 440px;
    }
    .about-marketplace__text-wrap {
        width: 45%;
    }
    .about-marketplace__img {
        width: 53%;
        max-width: 630px;
        height: auto;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
    .about-marketplace__btn-wrap {
        display: block;
    }
    .about-marketplace__btn-mobile-wrap {
        display: none;
    }
    .about-marketplace__img-mobile {
        display: none;
    }
    .about-marketplace__img-des {
        display: block;
    }
    .about-marketplace__text-title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 600;
    }
    .about-marketplace__text {
        margin-bottom: 20px;
    }
}
@media (min-width: 960px) {
    .about-marketplace {
        height: 460px;
        padding: 30px 15px 30px 48px
    }
    .about-marketplace__text-wrap {
        margin-right: 5%;
    }
    .about-marketplace__text-title {
        font-size: 28px;
    }
}
@media (min-width: 1200px) {
    .about-marketplace {
        padding: 45px 25px 45px 48px;
    }
    .about-marketplace__text-wrap {
        width: 45%;
    }
    .about-marketplace__img {
        width: 40%;
        max-width: 630px;
        margin-right: 5%;
    }
    .about-marketplace__btn-wrap {
        display: block;
    }
    .about-marketplace__btn-mobile-wrap {
        display: none;
    }
    .about-marketplace__text-title {
        font-size: 34px;
    }
    .about-marketplace__text {
        font-size: 15px;
    }
    .about-marketplace__text--1 {
        display: block;
    }
}
@media (min-width: 1680px) {
    .about-marketplace__text {
        font-size: 16px;
        line-height: 23px;
    }
    .about-marketplace {
        min-height: 525px;
    }
}
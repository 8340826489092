.producers-block {
    color: var(--dark-charcoal);
}

.producers-block__top {
    position: relative;
}

.producers-block__top-title {
    line-height: 27px;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 38px;
}

.producers-block__top-card {
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    height: 572px;
    display: flex;
    flex-direction: column;
}

.producers-block__top-card-img-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;
}

.producers-block__top-card-img {
    width: 100%;
    max-height: 265px;
    height: 265px;
    object-fit: cover;
}

.producers-block__top-card-img-text-wrap {

}

.producers-block__top-card-img-text-title {
    font-weight: 500;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.producers-block__top-card-img-text-sub-title {
    display: inline-block;
    color: var(--main-green);
    font-size: 14px;
    font-weight: 600;
    font-family: Montserrat;
}

.producers-block__top-card-text {
    flex: 1 1;
    width: 91%;
    min-height: 145px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}

.producers-block__top-swiper {
    width: 230px;
    margin-bottom: 60px;
    padding-bottom: 1px;
}

.producers-block__top-swiper .swiper-slide {
    height: 572px !important;
}

.producers-block__top-swiper .swiper-button-prev {
    left: -20px;
    opacity: 0;
}

.producers-block__top-swiper .swiper-button-next {
    right: 20px;
    opacity: 0;
}

.producers-block__top-swiper {
    overflow-x: hidden; 
    scroll-snap-type: block;
  }
  
  .producers-block__top-slider-wrap {
    scroll-snap-align: start;
  }

.producers-block__top-slider-wrap {
}

.producers-block__btn-wrap {
}

.producers-block__top-prev-btn {
    position: absolute;
    top: 49%;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 40px !important; 
    height: 40px !important;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}

.producers-block__top-prev-btn-img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    transform: rotate(180deg);
}

.producers-block__top-next-btn-img {
    width: 24px;
    height: 24px;
    transform: translateX(9px);
}

.producers-block__top-next-btn {
    position: absolute;
    top: 49%;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}

@media (min-width: 640px) {
    .producers-block__top-card-img-text-sub-title {
        font-size: 16px;
    }
    .producers-block__top-card {
        width: 230px;
        min-height: 553px;
    }
    .producers-block__top-swiper .swiper-slide {
        height: 572px !important;
    }
    .producers-block__top-swiper {
        width: 500px;
    }
    .producers-block__top-title {
        font-size: 24px;
    }
}
@media (min-width: 818px) {
    .producers-block__top-swiper {
        width: 89%;
    }
}
@media (min-width: 960px) {
    .producers-block__top-title {
        font-size: 24px;
    }
    .producers-block__top-swiper {
        width: 88%;
    }
}
@media (min-width: 1000px) {
    .producers-block__top-swiper {
        width: 80%;
    }
}
@media (min-width: 1200px) {
    .producers-block__top-title {
        font-size: 25px;
    }
    .producers-block__top-swiper {
        width: 85%;
    }
}
@media (min-width: 1400px) {
    .producers-block__top-swiper .swiper-slide {
        height: 572px !important;
    }
}

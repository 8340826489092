.creating-vendor {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    color: #19191D;
}

.creating-vendor__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-bottom: 2px solid #D8D8D8;
    height: auto;
    padding: 15px;
    gap: 10px;
}

.creating-vendor__input-wrap {
    max-width: 500px;
}

.creating-vendor__input-work-schedule {
    color: var(--dark-charcoal);
}

.creating-vendor__input-work-schedule .ql-editor {
    font-size: 14px !important;
}

.creating-vendor__input-lable {
    width: fit-content;
    font-size: 14px;
    color: var(--dark-charcoal);
    margin-bottom: 8px;
    font-weight: 500;
}

.creating-vendor__add-btn-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #D8D8D8;
    padding-top: 25px;
    padding-bottom: 20px;
}

.creating-vendor__add-keyword-btn-wrap {
    display: flex;
    flex-direction: column;
    margin-top: -2px;
}

.creating-vendor__add-btn--wrap {
    position: relative;
    width: fit-content;
    max-width: 500px;
    width: 100%;
}

.creating-vendor__add-btn {
    position: absolute;
    bottom: 0;
    right: 5px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 2;
}

.creating-vendor__add-result-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.creating-vendor__add-result-keyword-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.creating-vendor__add-result {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    border: 1px solid #89898999;
    padding: 11px 15px;
}

.creating-vendor__add-result-del-btn {
    width: 20px;
    height: 20px;
}

.creating-vendor__upload-result-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: fit-content
}

.creating-vendor__upload-result-doc-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: fit-content;
}

.creating-vendor__upload-result-background-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.creating-vendor__upload-result--wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.creating-vendor__upload-result-del-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #898989;
}

.creating-vendor__upload-result-del-btn-doc {
    width: 30px;
    height: 30px;
    border: 1px solid #898989;
}

.creating-vendor__upload-result-del-background-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #898989;
}

.creating-vendor__upload-img-logo-result {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
 }

 .creating-vendor__upload-img-background-result {
    width: 100%;
    max-height: 400px;
    min-height: 230px;
    object-fit: cover;
 }

 .creating-vendor__upload-result-new-doc {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 38px;
    height: 38px;
 }

 .creating-vendor__tab-0-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
}

.creating-vendor__tab-1-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.creating-vendor__tab-1-title {
    font-size: 21px;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 1px solid #D8D8D8;
}

.creating-vendor__tab-2-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    padding-top: 20px;
}


.creating-vendor__tab-3-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.creating-vendor__create-img--wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    gap: 5px;
}

.creating-vendor__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 15px;
}

.creating-vendor__create-img-text-wrap {
    position: relative;
    font-size: 14px;
}

.creating-vendor__create-img-text-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    background: #F2F4F7;
}

.creating-vendor__create-img-text-img {
    width: 20px;
    height: 20px;
}

.creating-vendor__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.creating-vendor__create-img-link-upload-lable {
    color: #2A7F77;
    cursor: pointer;
    font-weight: 600;
}

.creating-vendor__upload-result-new-doc-img {
    width: 50px;
    height: 50px;
}

.creating-vendor__vendor-accordion .MuiTypography-root {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: var(--dark-charcoal);
}

.creating-vendor__vendor-accordion.MuiPaper-root {
    box-shadow: none;
    margin: 0 !important;
    border-bottom: 1px solid #D8D8D8;
    min-height: 64px !important;
    width: 100%;
}

.creating-vendor__vendor-accordion .MuiButtonBase-root {
    padding: 0 !important;
    height: 64px;
}

.creating-vendor__vendor-accordion .MuiAccordionDetails-root {
    padding: 0 !important;
}

.creating-vendor__vendor-accordion.MuiPaper-root::before {
    display: none;
}

.creating-vendor__vendor-accordion-wrap {
    border-top: 1px solid #D8D8D8;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding-bottom: 20px;
}

.creating-vendor__vendor-accordion-arrow {
    transform: rotate(90deg);
}

.creating-vendor__input-sub-lable {
    font-size: 11px;
    color: #898989;
}

.creating-vendor__upload-result-new-doc-name {
    max-width: calc(100vw - 129px);
    overflow: auto;
}

.creating-vendor__tab-4__btn-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: fit-content;
    padding-top: 20px;
}

.creating-vendor__header-btn-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 600px;
    gap: 15px;
}

.creating-vendor__header-btn-title {
    font-weight: 500;
}

.creating-vendor__header-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    width: 176px;
    font-weight: 500;
    background: #2A7F77;
    padding: 8px 27px 8px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
}

.creating-vendor__input--vendor {
    border: 1px solid #898989;
}

.creating-vendor__create-img-title {
    font-weight: 500;
}

.creating-vendors__tab-select {
    width: 201px;
    height: 35px;
    border-radius: 0 !important;
}

.creating-vendors__tab-select.MuiInputBase-root {
    font-size: 14px !important;
}

.creating-vendors__text-tab--wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.creating-vendors__text-tab-wrap {
    display: none;
    white-space: nowrap;
}

.creating-vendors__text-tab-wrap::-webkit-scrollbar {
    height: 1px;
    background: #898989;
}

.creating-vendors__text-tab {
    width: fit-content;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.creating-vendor__create-img-title {
    font-weight: 500;
}

@media (min-width: 550px) {
    .creating-vendor__header-btn-wrap {
        flex-direction: row;
        align-items: center;
    }
}
@media (min-width: 768px) {
    .creating-vendor__header {
        width: calc(100% + 15px);
        margin-left: 0;
        gap: 20px;
        min-height: 83.6px;
        padding: 4px 15px 4px 15px;
    }
    .creating-vendors__text-tab--wrap  {
        transform: translateY(16px);
        justify-content: center;
        overflow-y: hidden;
        overflow-x: auto;
    }
    .creating-vendors__text-tab-wrap {
        display: flex;
    }
    .creating-vendor__input-sub-lable {
        font-size: 13px;
    } 
    .creating-vendor__vendor-accordion.MuiPaper-root {
        min-height: 68px !important;  
    }
    .creating-vendor__add-result-wrap {
        flex-direction: row;
    }
    .creating-vendor__add-result {
        width: auto;        
        max-width: none;        
        min-width: 250px;        
    }
    .creating-vendor__upload-img-logo-result {
        width: 160px;
        height: 160px;
     }
     .creating-vendor__upload-result-new-doc-name {
        max-width: calc(100vw - 221px);
    }
    .creating-vendor__upload-result-new-doc-name--is-open-menu {
        max-width: calc(100vw - 363px);
    }
    .creating-vendors__tab-select {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .creating-vendors__text-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
    .creating-vendor__header {
        padding: 4px 30px 4px 30px;
    }
}

.price-vyroby {
    color: var(--dark-charcoal);
    font-size: var(--text-size);
}

.price-vyroby--wrap {
    max-width: 1200px;
    padding: 0 15px;
}

.price-vyroby__title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
    line-height: 21px;
}

.price-vyroby__sub-title {
    text-align: start;
    margin-bottom: 40px;
}

.price-vyroby__info {
    padding: 15px 10px;
    background-color: #e8e8e8;
    box-shadow: 0px 0px 0px 0px rgba(255,255,255,0);
    margin-bottom: 30px;
}

.price-vyroby__info-title {
    font-size: var(--medium-text-size);
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 26px;
}

.price-vyroby__link-wrap {
    text-align: center;
}

.price-vyroby__link {
    color: var(--main-green);
    text-decoration: underline;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 700;
}

.price-vyroby__banner-wrap {
    padding: 0 15px;
}

.price-vyroby__info-text {
    font-size: 14px;
    line-height: 21px;
}

.price-vyroby__info-text--1 {
    margin-bottom: 25px;
}

.price-vyroby__info-text--2 {
    margin-bottom: 20px;
}

@media (min-width: 640px) {
    .price-vyroby__title {
        font-size: 28px;
    }
    .price-vyroby__info {
        padding: 15px 20px;
    }
    .price-vyroby__info-text {
        line-height: 24px;
    }
}

@media (min-width: 960px) {
    .price-vyroby__info-title {
        font-size: 18px;
    }
    .price-vyroby__info-text {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {
    .price-vyroby--wrap {
        max-width: 1160px;
        margin-left: auto;
        margin-right: auto;
    }
}

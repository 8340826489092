.user-collection {
    font-size: 14px;
    color: var(--dark-charcoal);
}

.user-collection__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    min-height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-collection__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-collection__header-title-img {
    width: 21px;
    height: 24px;
}

.user-collection__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-collection__header-btn-wrap {
    margin-left: auto;
}

.user-collection__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 8px 27px 8px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.user-collection__header-btn--save {
    display: flex;
    align-items: center;
    gap: 13px;
    min-width: 207px;
    background: #2A7F77;
    padding: 11px 27px 11px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.user-collection__header-btn-img {
    width: 24px;
    height: 24px;
}

.user-collection__header-btn-img--save {
    width: 18px;
    height: 18px;
}

.user-collection__main-wrap {
    padding: 5px 0 30px;
}

.user-collection__main-count-wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    padding: 15px 60px 15px 22px;
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}

.user-collection__main-count-wrap::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 3px;
    height: 30px;
    background: #2A7F77;
}

.user-collection__main-count-text {
    color: #49454F;
    font-weight: 400;
}

.user-collection__main-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #898989;
    border: 2px solid #F5F6F7;
}

.user-collection__blocks-result-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-collection__blocks-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    font-size: 18px;
    gap: 4px;
    padding: 8px 10px;
    border:  1px solid #D8D8D8;
}

.user-collection__blocks-result-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
    flex: 1 1;
    max-width: 58%;
    font-size: 13px;
}

.user-collection__blocks-result-text {
    color: #19191D;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.user-collection__blocks-result-text-title {
    font-weight: 400;
    color: var(--dark-charcoal);
}

.user-collection__blocks-result-text-count {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 400;
    color: #898989;
    white-space: nowrap;
}

.user-collection__blocks-result-btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-left: auto;
}

.user-collection__blocks-result-text-btn {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.user-collection__blocks-result-text-btn-del {
    width: 24px;
    height: 20px;
    cursor: pointer;
}

.user-collection__blocks-result-text--wrap {
    display: flex;
    gap: 10px;
}

.user-collection__blocks-result-img {
    cursor: pointer;
    width: auto;
    height: 80px;
    aspect-ratio: 16/9;
}

@media (min-width: 768px) {
    .user-collection__header-btn {
        font-size: 14px;
    }
    .user-collection__blocks-result {
        flex-wrap: wrap;
        padding: 12px 20px;
    }
    .user-collection__blocks-result-text-wrap {
        gap: 15px;
    }
    .user-collection__blocks-result-text--wrap {
        gap: 15px;
    }
    .user-collection__blocks-result-btn-wrap {
        flex-direction: row;
    }
    .user-collection__header {
        width: calc(100% + 15px);
        margin-left: 0;
        padding: 20px 15px 20px 15px;
        height: 83.6px;
    }
    .user-collection__main-wrap {
        padding: 5px 0 30px 15px;
    }
    .user-collection__header-btn--save {
        font-size: 14px;
    }
}
@media (min-width: 1200px) {
    .user-collection__header {
        padding: 20px 30px 20px 30px;
    }
}
@media (min-width: 1680px) {
    .user-collection__blocks-result-text-wrap {
        font-size: 14px;
    }
    .user-collection__blocks-result-text {
        font-size: 15px;
    }
}
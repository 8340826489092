.material-result-wrap {
    display: inline-block;
    width: 48px;
    height: 48px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid #C8C8C8;
    transition: .3s;
}

.material-result-active {
    border: 1px solid var(--purplish-gray);
}

.material-result__tooltip {
}

.MuiTooltip-tooltip {
    background: transparent !important;
    color: black !important;
}

.material-result {
    object-fit: cover;
    aspect-ratio: 1/1;
}

.material-result-modal {
    object-fit: cover;
    aspect-ratio: 1/1;
}

.material-result__title-wrap {

}

.material-result__title {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.material-result-circle {
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.material-result-circle--admin-view {
    border-radius: 0;
    width: 14px;
    height: 13px;
}

.material-result__not-photo {
    
}

.material-result__card-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    min-width: 34px;
    height: 34px;
    min-height: 34px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #D2D2D2;
}

.material-result__card-wrap--admin-view {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    padding: 1px;
    border-radius: 0;
    border: 1px solid #49454F;
}

.material-result__card {
    display: inline-block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.material-result__card-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 8.708px;
}

.material-result-wrap-modal {
    width: 90px;
    height: 90px;
}

.material-result__tooltip-desc {
    display: none;
}

.material-result__card-wrap-active {
    border: 1px solid #49454F;
}

@media (min-width: 640px) {
    .material-result__title {
        font-size: 15px;
    }
}
@media (min-width: 1200px) {
    .material-result__tooltip-desc {
        display: block;
    }
    .material-result-wrap:hover {
        border: 1px solid var(--purplish-gray);
    }
}
@media (min-width: 1680px) {
    .material-result__card-wrap {
        width: 33px;
        height: 33px;
        padding: 1px;
    }
}
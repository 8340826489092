.checkbox-wrap__wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 9px;
    align-items: flex-start;
    font-size: 13px;
}

@media (min-width: 440px) {
    .checkbox-wrap__wrap {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
}

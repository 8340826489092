.user-settings {
    font-size: 14px;
    color: #19191D;
}

.user-settings__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-settings__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-settings__header-title-img {
    width: 24px;
    height: 24px;
}

.user-settings__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-settings__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 11px 27px 11px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    margin-left: auto;
    font-weight: 500;
}

.user-settings__header-btn-img {
    width: 18px;
    height: 18px;
}

.user-settings__slider--wrap {
    padding: 22px 0;
}

.user-settings__slider-wrap {
}

.user-settings__slider-input-lable {
    margin-bottom: 10px;
    font-weight: 500;
}


.user-settings__create-img--wrap {
    flex: 1 1;
    min-width: 250px;
}

.user-settings__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 25px;
}

.user-settings__create-img-text-wrap {
    position: relative;
    font-size: 14px;
}

.user-settings__create-img-text-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    background: #F2F4F7;
}

.user-settings__create-img-text-img {
    width: 20px;
    height: 20px;
}

.user-settings__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.user-settings__create-img-link-upload-lable {
    color: #2A7F77;
    cursor: pointer;
    font-weight: 600;
}

.user-settings__result-img--wrap {
    display: flex;
    flex-direction: column;
    gap: 45px;
}

.user-settings__create-header--wrap {
    padding: 22px 0;
}

.user-settings__create-header-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.user-settings__create-header-input {

}

.user-settings__create-header-result-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-settings__create-header-result {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    padding: 12px 18px;
    border: 1px solid #898989;
}

.user-settings__create-header-result-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-settings__create-header-result-text-link {
    text-decoration: underline;
    color: #2A7F77;
}

.user-settings__create-header-result-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.user-settings__create-header-input-wrap {

}

.user-settings__create-header-input-lable {
    margin-bottom: 10px;
    font-weight: 500;
}

.user-settings__create-header-input-sub-lable {
    color: #898989;
    margin-top: 5px;
    font-size: 11px;
}

.user-settings__create-header-btn-wrap {
    width: fit-content;
}

.user-settings__create-header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 8px 27px 8px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.user-settings__create-header-btn-img {
    width: 24px;
    height: 24px;
}

.user-settings__create-header-form-control {
    
}
.user-settings__create-header-form-control .MuiTypography-root {
    font-weight: 500;
}

.user-settings__create-header-form-control .MuiSwitch-track {
    background-color: #2A7F77 !important;
}

.user-settings__create-header-form-control .Mui-checked {
    color: #2A7F77 !important;
}

.user-settings__create-header-form-control .MuiTypography-root {
    font-size: 14px;
}

.user-settings__create-header-result-text-title {
    font-size: 13px;
}

.user-settings__tab-3 {
    padding: 22px 0;
}

.user-settings__tab-3-btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.user-settings__tab-3-btn {
    min-width: 273px;
    text-align: center;
}

@media (min-width: 500px) {
    .user-settings__header-title-wrap {
        gap: 20px;
    }
}

@media (min-width: 768px) {
    .user-settings__create-header--wrap {
        padding: 22px 0 22px 15px;
    }
    .user-settings__header {
        width: calc(100% + 15px);
        margin-left: 0;
        height: 83.6px;
        gap: 20px;
    }
    .user-settings__slider--wrap {
        padding: 22px 0 22px 15px;
    }
    .user-settings__header-btn {
        font-size: 14px;
    }
    .user-settings__tab-3 {
        padding: 22px 0 22px 15px;
    }
}

@media (min-width: 1200px) {
    .user-settings__create-header-input-sub-lable {
        font-size: 13px;
    }
    .user-settings__create-header-result-text-title {
        font-size: 14px;
    }
    .user-settings__header {
        padding: 15px 30px;
    }
}
.footer {
    margin-top: 40px;
    color: var(--dark-charcoal);
}

.footer__bottom {
    position: relative;
    z-index: 10000;
    padding: 50px 0 16px 0;
    border-top: 1px solid #D8D8D8;
}

.footer__bottom-info {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 60px;
    padding: 0 80px;
}

.footer__bottom-info-social {
    margin-left: 10px;
}

.footer__bottom-info-social-logo-wrap {
    display: flex;
    flex-direction: column;
}

.footer__bottom-info-social-logo {
    width: 162px;
    height: 17px;
    margin-bottom: 20px;
}

.footer__bottom-info-social-link-wrap {
    display: flex;
    gap: 13px;
    margin-bottom: 10px;
}

.footer__bottom-info-social-link {
    width: 40px;
}

.footer__bottom-info-link--wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer__bottom-info-link--left {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer__bottom-info-link-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 130px;
}

.footer__bottom-info-link-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}

.footer__bottom-info-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000;
    transition: .3s;
}

.footer__bottom-info-link:hover {
    color: var(--main-green);
}

.footer__bottom-producer {
    border-top: 1px solid #D1D1D6;
    padding-top: 20px;
}

.footer__bottom-producer-text {
    font-size: 12px;
    padding-left: 15px;
    color: #000;
}

.footer__bottom-info-social-full {
    display: none;
}

.footer__bottom-info-social-logo-link {
    padding: 2px;
}

@media (min-width: 280px) {
    .footer__bottom-info {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 30px;
        padding: 0 15px;
    }
    .footer__bottom-info-social {
        margin-left: 0px;
    }
}

@media (min-width: 480px) {
    .footer__bottom-info-link--wrap {
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
    }
}

@media (min-width: 576px) {
    .footer__bottom-info-link--wrap {
        gap: 100px;
    }
}

@media (min-width: 640px) {
    .footer__bottom-info {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 15px 20px 15px;
    }
    .footer__bottom-info-link--wrap {
        gap: 80px;
        margin-left: auto;
        margin-right: auto;
    }
    .footer__bottom-producer {
        margin-left: 0px;
    }
}

@media (min-width: 885px) {
    .footer__bottom-info-link--wrap {
        align-items: baseline;
    }
    .footer__bottom-info-link--left {
        flex-direction: row;
        gap: 75px;
    }
}

@media (min-width: 960px) {
    .footer__bottom-info-link--wrap {
        gap: 50px;
    }
    .footer__bottom-info-link--left {
        gap: 50px;
    }
    .footer__bottom-info-social {
        padding-top: 5px;
    }
    .footer__bottom {
        padding: 60px 0 16px 0;
    }
    .footer__bottom-info {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .footer__bottom-info-social-full {
        display: flex;
        flex-direction: column;
    }
    .footer__bottom-info-social-logo-wrap {
        display: none;
    }
    .footer__bottom-info-link--wrap {
        gap: 80px;
    }
    .footer__bottom-info-link--left {
        gap: 80px;
    }
    .footer__bottom-info-social {
        padding-top: 0;
    }
    .footer__bottom-info-link {
        font-size: 14px;
    }
    .footer__bottom-info {
        padding: 0 30px 20px 30px;
    }
    .footer__bottom-producer-text {
        padding-left: 30px;
    }
}

@media (min-width: 1680px) {
    .footer__bottom-info-social-link {
        width: 46px;
    }
    .footer__bottom-info-link--wrap {
        margin-left: auto;
        gap: 100px;
    }
    .footer__bottom-info-link {
        font-size: 15px;
    }
    .footer__bottom-info-link-title  {
        font-size: 17px;
    }
    .footer__bottom-info-link--left {
        gap: 100px;
    }
    .footer__bottom-info-social-logo {
        width: 188px;
        height: 19.7px;
    }
}
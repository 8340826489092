.order-read {
    font-size: 14px;
    color: #19191D;
}

.order-read__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    min-height: 81.6px;
    padding: 22px 0 22px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-bottom: 2px solid #D8D8D8;
}

.order-read__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order-read__header-title-img {
    width: 28px;
    height: 28px;
}

.order-read__header-title {
    font-weight: 500;
    font-size: 16px;
}

.order-read__header-btn-wrap {

}

.order-read__header-btn--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.order-read__header-btn {
    display: flex;
    align-items: center; 
    gap: 13px;
    padding: 10px 15px 10px 20px;
    color: #898989;
    white-space: nowrap;
}

.order-read__header-btn-img {
    width: 15px;
    height: 15px;
}

.order-read-wrap {
    padding: 20px 0 6px;
}

.order-read__main {
    border: 1px solid #D8D8D8;
}

.order-read__main-top {
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    padding: 20px;
    font-size: 13px;
}
.order-read__main-top-text--wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;
}

.order-read__main-top-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 14px;
}
.order-read__main-top-text {
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #898989;
}

.order-read__main-top-tel-img-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.order-read__main-top-tel-img {
    width: 13px;
    height: 13px;
}

.order-read__main-top-email-img {
    margin-left: -2px;
    width: 20px;
    height: 15px;
}

.order-read__main-bottom {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
    border-bottom: 1px solid #D8D8D8;
    -webkit-text-size-adjust: 100%;
}

.order-read__main-bottom-text {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 14px;
    -webkit-text-size-adjust: 100%;
    width: fit-content;
}

.order-read__main-bottom-text-comment {
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-size: 14px;
    -webkit-text-size-adjust: 100%;
}

.order-read__main-bottom-text-title {
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #898989;
}

.order-read__main-bottom-text-title-mob {
    font-size: 14px;
    font-weight: 600;
    color: #898989;
}

.order-read__products-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 10px 0;
    overflow: hidden;
}

.order-read__products--wrap {
    overflow: auto;
    width: 100%;
}

.order-read__product-wrap {
    width: 100%;
    min-width: fit-content;
}

.order-read__top-select-wrap {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.order-read__main-comment {
    padding: 20px;
    -webkit-text-size-adjust: 100%;
}

.order-read__products-header--wrap {
    display: none;
    width: 100%;
    min-width: 895px;
    margin-top: 15px;
    transition: .15s;
}

.order-read__products-header-wrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    width: 100%;
    min-width: fit-content;
    -webkit-text-size-adjust: 100%;
}

.order-read__products-header-title {
    flex: 1 1;
    max-width: 233px;
}

.order-read__products-header-total {
    width: 100px;
}

.order-read__products-header-code {
    width: 100px;
}

.order-read__products-header-count {
    width: 68px;
}

.order-read__products-header-price {
    width: 82px;
}

.order-read__products-header-vendor {
    width: 100px;
}

.order-read__products-header-category {
    width: 150px;
}

.order-read__total-price-wrap {
    display: flex;
    justify-content: end;
    gap: 5px;
    flex-wrap: wrap;
    font-weight: 600;
}

.order-read__header-btn-text {
    display: none;
}

.order-read__total-price-count {
    font-weight: 600;
}

.order-read__product-error {
    padding: 20px 0;
}

.user-product-card__varyations-text-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.user-product-card__varyations-text {
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
}

.order-read__main-bottom-text--payment {
    width: 100%;
}

@media (min-width: 360px) {
    .order-read__header-title-wrap {
        gap: 20px;
    }
    .order-read__header {
        gap: 15px;
    }
}
@media (min-width: 640px) {
    .order-read__total-price-wrap {
        padding-right: 46px;
    }
}
@media (min-width: 768px) {
    .order-read__header-btn-text {
        display: block;
    }
    .order-read__header {
        padding: 22px 15px;
        width: calc(100% + 15px);
        margin-left: 0;
    }
    .order-read__header-btn {
        padding: 10px 0 10px 20px;
    }
    .order-read__header-btn-img {
        width: 18px;
        height: 18px;
    }
    .order-read-wrap {
        padding: 20px 0 20px 15px;
    }
    .order-read__main-top-text {
        display: block;
    }
    .order-read__main-top-text--wrap {
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }
    .order-read__top-select-wrap {
        position: static;
    }
    .order-read__main-bottom-text-title-mob {
        display: none;
    }
    .order-read__main-bottom-text-title {
        display: block;
    }
    .order-read__products-wrap {
        padding: 20px 0 20px 15px;
    }
    .order-read__total-price-wrap {
        padding-right: 32px;
        font-weight: 500;
    }
    .order-read__products-header--wrap {
        display: block;
    }
    .order-read__main-bottom {
        flex-direction: row;
        gap: 20px;
    }
    .order-read__main-bottom-text--delivery {
        margin-right: 40px;
    }
    .order-read__total-price-count {
        font-weight: 500;
    }
}
@media (min-width: 1200px) {
    .order-read__header {
        padding: 22px 30px;
    }
}
@media (min-width: 1250px) {
    .order-read__products-header-title {
        max-width: 429px;
    }
}
@media (min-width: 1680px) {
    .order-read__main-top-text--wrap {
        gap: 35px;
    }
    .order-read__main-bottom {
        gap: 35px;
    }
}

.category-cards {
    display: flex;
    gap: 15px;
    flex-direction: column;
}
.category-cards--wrap {
    display: flex;
    flex-direction: column;
}

.category-cards__title {
    line-height: 33px;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 38px;
}

.category-cards__card-wrap {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
}

.category-cards__card-bottom-wrap {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
}

.category-cards__card--wrap {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
}

.category-cards__card-bottom--wrap {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.category-cards__card {
    position: relative;
    flex: 1 1;
}

.category-cards__card-5 {
    position: relative;
    width: 100%;
}

.category-cards__card-img-1 {
    width: 100%;
    object-fit: cover;
    max-height: 324px;
}

.category-cards__card-img-3 {
    width: 100%;
    object-fit: cover;
    max-height: 340px;
}

.category-cards__card-img-4 {
    width: 100%;
    object-fit: cover;
    object-position: center;
    max-height: 340px;
    height: 340px;
    min-height: 200px;
}

.category-cards__card-img-5 {
    width: 100%;
    object-fit: cover;
    max-height: 340px;
}

.category-cards__card-img-6 {
    width: 100%;
    object-fit: cover;
    max-height: 340px;
    object-position: center;
}

.category-cards__card-text-wrap {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(128, 128, 128, 0.00) 0%, rgba(0, 0, 0, 0.65) 89.58%);
    width: 100%;
    height: 100%;
    padding: 10px 7px 30px 28px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: end;
}

.category-cards__card-text-title {
    width: fit-content;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    transition: .3s;
}

.category-cards__card-text {
    width: fit-content;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    transition: .3s;
    text-decoration: underline;
}

.category-cards__card-text:hover {
    text-decoration: underline;
}

.category-cards__card-text:active,
.category-cards__card-text:focus {
    color: var(--main-green);
}

@media (min-width: 480px) {
    .category-cards {
        gap: 20px;
    }
    .category-cards__card-wrap {
        gap: 20px;
    }
    .category-cards__card-bottom-wrap {
        display: flex;
        gap: 20px;
    }
    .category-cards__card--wrap {
        gap: 20px;
    }
    .category-cards__card-bottom--wrap {
        gap: 20px;
    }
    .category-cards__card-wrap {
        flex-direction: row;
        max-height: 674px;
        gap: 20px;
    }
    .category-cards__card--wrap {
        width: 40.5%;
        gap: 20px;
    }
    .category-cards__card-bottom--wrap {
        flex-direction: row;
        max-height: 720px;
    }
    .category-cards__card-5 {
        width: 37%;
    }
    .category-cards__card-text-title {
        font-size: 20px;
    }
    .category-cards__card-img-3 {
        max-height: 720px;
    }
    .category-cards__card-img-4 {
        max-height: 386px;
    }
    .category-cards__card-img-5 {
        height: 340px;
    }
    
    .category-cards__card-img-6 {
        height: 340px;
    }
    .category-cards__title {
        line-height: 24px;
    }
}
@media (min-width: 600px) {
    .category-cards__card-img-5 {
        max-height: 100%;
    }
    .category-cards__title {
        line-height: 23px;
    }
}
@media (min-width: 640px) {
    .category-cards__title {
        font-size: 24px;
    }
}       
@media (min-width: 960px) {
    .category-cards {
        gap: 20px;
    }
    .category-cards__card-wrap {
        gap: 20px;
    }
    .category-cards__card-bottom-wrap {
        display: flex;
        gap: 20px;
    }
    .category-cards__card--wrap {
        gap: 20px;
        width: 41.5%;
    }
    .category-cards__card-bottom--wrap {
        gap: 20px;
        max-height: 300px;
    }
    .category-cards__card-img-1 {
        max-height: 325px;
    }
    .category-cards__card-img-6 {
        height: 300px;
    }
    .category-cards__card-text {
        font-size: 15px;
    }
    .category-cards__card-5 {
        width: 41.5%;
    }
    .category-cards__card-img-4 {
        height: 350px;
    }
}
@media (min-width: 1100px) {
    .category-cards {
        flex-direction: row;
        gap: 15px;
    }
    .category-cards__card-wrap {
        gap: 15px;
    }
    .category-cards__card--wrap {
        gap: 15px;
        width: 40.5%;
    }
    .category-cards__card-bottom-wrap {
        gap: 15px;
    }
    .category-cards__card-bottom--wrap {
        gap: 15px;
    }
    .category-cards__card-5 {
        width: 37%;
    }
    .category-cards__card-img-4 {
        height: 346px;
    }
    .category-cards {
        gap: 25px;
    }
    .category-cards__card-wrap {
        gap: 25px;
    }
    .category-cards__card--wrap {
        gap: 25px;
    }
    .category-cards__card-bottom-wrap {
        gap: 25px;
    }
    .category-cards__card-bottom--wrap {
        gap: 25px;
    }
}
@media (min-width: 1200px) {
    .category-cards__title {
        font-size: 25px;
        line-height: 27px;
    }
    .category-cards__card-text {
        text-decoration: none;
    }
    .category-cards__card-text:focus {
        color: #fff;
    }
    .category-cards__card-text-wrap {
        gap: 10px;
    }
}
@media (min-width: 1350px) {
    .category-cards {
        max-height: 674px;
    }
    .category-cards__card-wrap {
        width: 58%;
    }
    .category-cards__card-bottom-wrap {
        width: 47%;
    }
}
@media (min-width: 1680px) {
    .category-cards__card-text-title {
        font-size: 24px;
    }
    .category-cards__card-text {
        font-size: 16px;
        line-height: 17px;
    }
    .category-cards {
        max-height: 740px;
    }
    .category-cards__card-wrap {
        max-height: 740px;
    }
    .category-cards__card-img-3 {
        max-height: 740px;
    }
    .category-cards__card-img-1 {
        max-height: 356px;
    }
    .category-cards__card-img-4 {
        height: auto;
        max-height: 410px;
    }
}
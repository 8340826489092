.about-view {
    font-size: var(--text-size);
    color: #49454F;
}

.about-view--wrap {
}

.about-view__main-wrap {
    background: #f2f2f2;
}

.about-view__main {
    padding: 60px 15px 60px;
}

.about-view__main-text-wrap {
}

.about-view__main-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    line-height: 34px;
    margin-bottom: 45px;
    color: var(--dark-charcoal)
}

.about-view__main-img-1 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.about-view__main-img-2 {
    display: none;
    width: 51%;
    max-width: 1035px;
    max-height: 652px;
    height: 100%;
}

.about-view__main-text {
    font-size: var(--text-size);
    line-height: 25px;
    margin-bottom: 30px;
    font-weight: 400;
}

.about-view__buyers-wrap {

}

.about-view__buyers {
    max-width: 1200px;
    padding: 60px 15px 60px;
}

.about-view__buyers-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    padding-top: 50px;
    margin-bottom: 45px;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.about-view__buyers-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.about-view__buyers-card {
    width: 100%;
    min-height: 213px;
    max-width: 380px;
    height: 265px;
    padding: 30px 30px 40px;
    background-color: rgb(245, 245, 247);
}

.about-view__buyers-card-title {
    font-size: var(--title-size);
    font-weight: 700;
    margin-bottom: 20px;   
    color: var(--dark-charcoal)
}

.about-view__buyers-card-text {
    line-height: 1.55;
    font-size: var(--big-text-size);
}

.about-view__sellers-wrap {
    background: #f5f5f7;
}

.about-view__sellers {
    max-width: 1200px;
    padding: 60px 15px 60px;
}

.about-view__sellers-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 45px;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.about-view__sellers-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.about-view__sellers-card {
    width: 100%;
    min-height: 238px;
    max-width: 380px;
    height: 262px;
    padding: 30px 30px 40px;
    background-color: rgb(255, 255, 255);
}

.about-view__sellers-card-title {
    font-size: var(--title-size);
    font-weight: 700;
    margin-bottom: 20px;  
    color: var(--dark-charcoal)
}

.about-view__sellers-card-text {
    line-height: 1.55;
    font-size: var(--big-text-size);
}

.about-view__form-wrap {

}

.about-view__form {
    max-width: 1200px;
    padding: 60px 15px 20px;
}

.about-view__form-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 40px;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.about-view__form--wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.about-view__form-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.about-view__form-info-text {
    margin-bottom: 12px;
    font-size: var(--big-text-size);
}

.about-view__form-info-text-li {
    list-style-type: disc;
    margin-left: 20px;
}

.about-view__form-info-text-link {
    color: var(--main-green);
    margin-bottom: 26px;
}

.about-view__form-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    max-width: 500px;
}

.about-view__form-input {
    height: 60px;
    max-width: 380px;
    padding: 0 20px;
    line-height: 1.33;
    width: 100%;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
}

.about-view__form-textarea {
    padding: 17px 20px 0;
    line-height: 1.33;
    width: 100%;
    max-width: 380px;
    border: 1px solid #c7c7c7;
    outline: none;
    font-family: Montserrat;
    font-size: var(--text-size);
    resize: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    caret-color: #19191D;
}

.about-view__form-textarea:focus-visible {
    border: 1px solid #c7c7c7;
}

.about-view__main-text-btn {
    font-weight: 500;
}

.about-view__main-text-btn--1 {
    font-weight: 400;
    max-width: 380px;
    width: 100%;
    font-size: 15px;
    height: 60px;
}

@media (min-width: 640px) {
    .about-view__main-title {
        margin-bottom: 30px;
    }
    .about-view__form-input {
        width: 600px;
        max-width: 600px;
        font-size: 16px;
    }
    .about-view__form-textarea {
        max-width: 600px;
    }
    .about-view__main-text-btn--1 {
        width: fit-content;
        margin-right: auto;
        height: 60px;
        padding: 0 60px;
        font-size: 16px;
    }
    .about-view__form-input-wrap {
        max-width: 600px;
    }
}

@media (min-width: 810px) {
    .about-view__sellers-cards {
        justify-content: flex-start;
        max-width: 780px;
        margin-left: auto;
        margin-right: auto;
    }
    
}
@media (min-width: 960px) {
    .about-view__main-img-1 {
        width: 70%;
    }
    .about-view__main {
        padding: 70px 15px 70px;
    }
    .about-view__buyers {
        max-width: 100%;
        padding: 70px 15px 70px;
    }
    .about-view__sellers {
        max-width: 100%;
        padding: 70px 15px 70px;
    }
    .about-view__form {
        max-width: 100%;
        padding: 70px 15px 30px;
    }
    .benefits-block__item {
        margin-bottom: 0;
    }
    .benefits-block__items {
        gap: 40px;
    }
    .about-view__buyers-title {
        padding-top: 70px;
    }
    
}
@media (min-width: 1000px) {
    .benefits-block__item {
        max-width: 380px;
    }
}
@media (min-width: 1200px) {
    .about-view__main {
        display: flex;
        align-items: center;
        padding: 70px 30px 78px;
    }
    .about-view__main-img-1 {
        display: none;
    }
    .about-view__main-img-2 {
        display: block;
    }
    .about-view__buyers {
        max-width: 1200px;
        padding: 70px 30px 70px;
    }
    .about-view__sellers {
        max-width: 1200px;
        padding: 70px 30px 70px;
    }
    .about-view__form {
        max-width: 1200px;
        padding: 70px 30px 30px;
    }
    .about-view__main-title {
        margin-bottom: 38px;
    }
    .about-view__buyers-card {
        width: 32%;
    }
    .about-view__sellers-card {
        width: 32%;
    }
    .about-view__sellers-cards {
        max-width: 100%;
    }
    .about-view__form--wrap {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
    .about-view__main-text-btn--1 {
        width: fit-content;
        margin-right: auto;
    }
    .about-view__buyers-cards {
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;
    }
    .about-view__sellers-cards {
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;
    }
    .about-view__form-input-wrap {
        max-width: 600px;
    }
    .about-view__form-info {
        max-width: 655px;
    }
}
@media (min-width: 1300px) {
    .about-view__main-text-wrap {
        padding-left: 35px;
    }
}
@media (min-width: 1680px) {
    .about-view__main-img-2 {
        width: 47%;
    }
    .about-view__buyers {
        max-width: 1500px;
    }
    .about-view__buyers-cards {
        max-width: 1500px;
    }
    .about-view__sellers-cards {
        max-width: 1230px;
    }
    .about-view__buyers {
        max-width: 1500px;
    }
    .about-view__sellers {
        max-width: 1500px;
    }
    .about-view__form {
        max-width: 1500px;
    }
    .about-view__sellers-cards  {
        gap: 45px;
    }
    .about-view__buyers-cards {
        gap: 45px;
    }
    .about-view__buyers-card {
        min-height: 263px;
    }
    .about-view__form--wrap {
        gap: 185px;
    }
}
.user-products {
    font-size: 14px;
    color: var(--dark-charcoal);
}

.user-products__btn-download-link {
    border: 2px solid black;
    background-color: white;
    padding: 4px 28px;
    font-size: 21px;
    cursor: pointer;
    border-color: #0d48ac;
    color: #0d48ac;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    transition: .3s;
}

.user-products__btn-download-link:hover {
    background: #0d48ac;
    color: white;
}

.user-products__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-products__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-products__header-title-img {
    width: 24px;
    height: 24px;
}

.user-products__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-products__header-btn-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: auto;
}

.user-products__header-btn--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.user-products__download-btn {
    text-align: end;
}

.user-products__header-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    height: 40px;
    background: #2A7F77;
    padding: 8px 20px 8px 15px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.user-products__header-btn-img {
    width: 24px;
    height: 24px;
}

.user-products__filter-wrap {
    border-bottom: 2px solid #D8D8D8;
    padding: 20px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
}

.user-products__products--wrap {
    padding: 5px 0 30px 0;
    overflow: auto;
}

.user-products__products-title-wrap {
    display: none;
    min-width: 1040px;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 7px;
    padding-right: 40px;
    font-size: 13px;
    font-weight: 500;
}

.user-products__products-title {
    flex: 1 1;
    max-width: 922px;
}

.user-products__products-title-characteristic {
    width: 190px;
}

.user-products__products-title-category {
    width: 150px;
}

.user-products__products-title-price {
    width: 100.5px;
}

.user-products__products-title-code {
    width: 100px;
}

.user-products__products-wrap {
}

.user-products__product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 14px 0 20px;
    border-bottom: 1px solid #D8D8D8;
}

.user-products__product-text {
    flex: 1 1;
    display: flex;
    gap: 10px;
}

.user-products__product-text-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.user-products__product-text-info {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.user-products__product-text-info-name-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 13px;
    max-width: 800px;
}

.user-products__product-text-info-name {
    min-width: 200px;
    color: var(--dark-charcoal);
    font-size: 14px;
    cursor: pointer;
}

.user-products__product-text-info-variations-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-products__product-text-info-variations {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    color: #7D8FB3;
}

.user-products__product-text-info-variations-btn-edite {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.user-products__product-text-info-variations-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.user-products__product-text-info-category {
    display: inline-block;
    padding: 1px 12px;
    color: #2A7F77;
    border: 1px solid #2A7F77;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 150px;
    text-align: center;
}

.user-products__product-text-info-price {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-products__products-error {
    font-size: 26px;
    font-weight: 500;
    color: #545D69;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.user-products__products-pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 15px;
}

.user-products__filter-mob-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-products__filter-des-wrap {
    display: none;
}

.user-products__modal-filter-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    outline: none !important;
    box-shadow: none !important;
    background: #fff;
}

.user-products__modal-filter--wrap {
    height: calc(100vh - 51px);
    overflow: auto;
    padding: 10px 10px 35px;
}

.user-products__filter-mob--wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 560px) {
    .user-products__header-btn-wrap {
        flex-direction: row;
    }
    .user-products__header {
        padding: 20px 15px;
        min-height: 83.6px;
    }
}
@media (min-width: 600px) {
    .user-products__header-btn {
        padding: 8px 27px 8px 20px;
    }
}
@media (min-width: 768px) {
    .user-products__filter-mob--wrap {
        display: none;
    }
    .user-products__filter-des-wrap {
        display: block;
    }
    .user-products__header {
        width: calc(100% + 15px);
        margin-left: 0;
    }
    .user-products__filter-wrap {
        width: calc(100% + 16px);
        margin-left: 0;
    }
    .user-products__products-title-wrap {
        display: flex;
    }
    .user-products__header-btn {
        font-size: 14px;
    }
    .user-products__products--wrap {
        padding: 18px 0 30px 15px;
    }
  
}
@media (min-width: 800px) {
    .user-products__product-text-info {
        flex-wrap: nowrap;
    }
}
@media (min-width: 1200px) {
    .user-products__header {
        padding: 20px 30px;
    }
    .user-products__filter-wrap {
        padding: 20px 30px;
    }
}
@media (min-width: 1300px) {
    .user-products__products-title-wrap  {
        min-width: 1096px;
    }
    .user-products__products-title-characteristic {
        width: 290px;
    }
    .user-products__products-title {
        max-width: 875px;
    }
}
@media (min-width: 1680px) {
    .user-products__products-title-wrap {
        font-size: 14px;
    }
}
    

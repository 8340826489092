.checkout-view__header--wrap {
	padding: 0 15px 25px 15px;
}
.checkout-view__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1200px;
	padding: 30px 0;
	border-bottom: 1px solid #d8d8d8;
	font-size: var(--text-size);
}

.checkout-view__header-logo {
	width: 170px;
}

.checkout-view__card-form-wrap {
}

.checkout-view__form-input-label {
	font-weight: 500;
	font-size: 15px;
}

.checkout-view__card-form-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 35px;
	min-height: 100vh;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
}

.checkout-view__card-form--wrap {
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.checkout-view__card-form {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	background: #fff;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.checkout-view__buy--wrap {
	width: 100%;
}

.checkout-view__buy-wrap {
	position: relative;
}

.checkout-view__form-price-wrap {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.checkout-view__card-form-btn-close {
	width: 30px;
	height: 30px;
	cursor: pointer;
	transition: 0.3s;
}

.checkout-view__card-form-btn-close:hover {
	opacity: 0.6;
}

.checkout-view__card-form-title-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
}

.checkout-view__card-form-title {
	font-size: 20px;
	font-weight: 600;
	margin-top: 25px;
	margin-bottom: 25px;
}

.checkout-view__card-form-sub-title {
	font-size: 18px;
	font-weight: 600;
}

.checkout-view__product--wrap:not(:first-child) {
	margin-top: 50px;
}

.checkout-view__product-error {
	padding-top: 50px;
	padding-bottom: 50px;
	font-size: var(--big-text-size);
	text-align: center;
	border: 1px solid #d8d8d8;
}

.checkout-view__card-form-text {
	line-height: 1.55;
	margin-bottom: 30px;
	font-weight: 400;
	font-size: 15px;
}

.checkout-view__form-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.checkout-view__form-input--wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
}

.checkout-view__form-input-wrap {
	position: relative;
}

.checkout-view__form-input-wrap-addres {
	position: relative;
	width: 100%;
}

.checkout-view__form-input {
	height: 50px;
	line-height: 1.33;
	width: 100%;
	border-bottom: 1px solid #d8d8d8;
	border-radius: 0;
	margin-bottom: 20px;
	font-size: 15px;
	color: #19191d;
}

.checkout-view__form-input::placeholder {
	color: #898989;
	font-size: 14px;
}

.checkout-view__form-input:-webkit-autofill {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	caret-color: #19191d;
}

.checkout-view__form-input:-webkit-autofill:focus {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	caret-color: #19191d;
}

.checkout-view__form-price {
	font-size: var(--title-size);
	font-weight: 600;
	text-align: start;
	margin-top: 25px;
	margin-bottom: 25px;
}

.checkout-view__form-bottom {
	margin-top: 20px;
	text-align: start;
	margin-bottom: 20px;
	font-size: 12px;
}

.checkout-view__form-bottom-link {
	color: #1155cc;
}

.checkout-view__card-product-info-price-wrap {
	display: flex;
	align-items: center;
	gap: 15px;
	font-size: var(--text-size);
}

.checkout-view__card-product-info-price-count-btn {
	font-size: var(--text-size);
	width: 18px;
	height: 18px;
	border: 1px solid #19191d;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.6;
	transition: 0.3s;
}

.checkout-view__card-product-info-price-count-btn:hover {
	opacity: 1;
}

.checkout-view__card-products {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	margin-bottom: 10px;
}

.checkout-view__card-product-wrap {
}

.checkout-view__card-product-vendor-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	font-size: var(--medium-text-size);
	font-weight: 600;
	border-bottom: 1px solid #d8d8d8;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.checkout-view__card-product-vendor {
	font-size: 14px;
	font-weight: 400;
}

.checkout-view__card-product-vendor-total {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 600;
	white-space: nowrap;
}

.checkout-view__card-product--wrap {
	border-bottom: 1px solid #d8d8d8;
	padding-bottom: 20px;
}

.checkout-view__card-product {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 7px;
}

.checkout-view__card-product-img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	cursor: pointer;
}

.checkout-view__card-product-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	gap: 40px;
	flex: 1 1;
	min-height: 70px;
}

.checkout-view__card-product-info-name-wrap {
}

.checkout-view__card-product-info-name {
	font-size: 14px;
	font-weight: 500;
	vertical-align: middle;
	color: #19191d;
	margin-bottom: 10px;
}

.checkout-view__card-product-info-name-code {
	color: var(--purplish-gray);
	font-size: 12px;
	font-weight: 400;
}

.checkout-view__card-product-info-price--wrap {
	display: flex;
	align-items: center;
	gap: 20px;
}

.checkout-view__card-product-info-price {
	line-height: 1.55;
	font-size: var(--text-size);
	white-space: nowrap;
}

.checkout-view__card-product-delete-btn {
	width: 23px;
	min-width: 23px;
	height: 23px;
	opacity: 0.6;
	transition: 0.3s;
}

.checkout-view__card-product-delete-btn:hover {
	opacity: 1;
}

.checkout-view__back-btn {
	display: flex;
	align-items: center;
	gap: 1px;
	font-size: 14px;
	transition: 0.3s;
}

.checkout-view__back-btn p {
	margin-left: 10px;
}
.checkout-view__back-btn:hover p {
	color: #2a7f77;
}

.checkout-view__back-btn:hover .checkout-view__back-btn-img {
	transform: translateX(-1px);
}

.checkout-view__back-btn-img {
	width: 20px;
	height: 20px;
	transition: 0.3s;
}

.checkout-view__form-send-btn-buy {
	border: 1px solid var(--main-green);
	background: var(--main-green);
	color: #fff;
	width: 100%;
	font-size: 15px;
	font-weight: 500;
	line-height: 0;
	padding: 10px 20px;
	height: 50px;
	transition: 0.3s;
}

.checkout-view__form-send-btn-buy:hover {
	background: #fff;
	color: var(--dark-charcoal);
}

.checkout-view__form-error-wrap {
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.checkout-view__form-error-text--phone {
	color: red;
	font-size: 13px;
	margin-top: 5px;
	margin-bottom: 15px;
}

.checkout-view__product--wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 15px 10px;
	border: 1px solid #d8d8d8;
	margin-top: 30px;
}

.checkout-view__card-form-delivery-wrap {
	margin-top: 25px;
}

.checkout-view__buy--wrap-mob {
}

.checkout-view__buy--wrap-des {
	display: none;
}

.checkout-view__back-btn-text-wrap {
	display: flex;
	align-items: center;
	gap: 5px;
}

.checkout-view__back-btn-text {
	display: none;
}

.checkout-view__form-error-banner {
	margin-bottom: 20px;
}

@media (min-width: 450px) {
	.checkout-view__card-product-info-price--wrap {
		flex-direction: row;
		align-items: center;
		min-width: 200px;
	}
	.checkout-view__card-product-info-price--wrap {
		gap: 30px;
	}
	.checkout-view__card-product {
		gap: 10px;
	}
}

@media (min-width: 576px) {
	.checkout-view__form-input--wrap {
		gap: 20px;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.checkout-view__form-input-wrap {
		width: 47%;
	}
	.checkout-view__form-error-wrap {
		max-width: 47%;
		margin-bottom: 0;
	}
}
@media (min-width: 620px) {
	.checkout-view__card-product-info {
		flex-direction: row;
		align-items: center;
	}
	.checkout-view__card-product-delete-btn {
		align-self: center;
	}
}

@media (min-width: 640px) {
	.checkout-view__back-btn-text {
		display: block;
	}
	.checkout-view__back-btn {
		width: 175px;
	}
	.checkout-view__card-form-text {
		font-size: 16px;
	}
	.checkout-view__card-product-vendor-total {
		flex-direction: row;
		align-items: center;
		gap: 5px;
		font-size: 15px;
	}
	.checkout-view__card-product-vendor {
		font-size: 15px;
	}
}

@media (min-width: 960px) {
	.checkout-view__card-product-info-name {
		font-size: 14px;
	}
}

@media (min-width: 1182px) {
	.checkout-view__buy--wrap-des {
		display: block;
	}
	.checkout-view__card-form-wrap {
		flex-direction: row;
		align-items: flex-start;
	}
	.checkout-view__buy--wrap {
		position: sticky;
		--webkit-position: sticky;
		top: 0px;
		width: 425px;
	}
	.checkout-view__buy--wrap-mob {
		display: none;
	}
	.checkout-view__header--wrap {
		display: flex;
		flex-wrap: wrap;
		overflow: auto;
		height: 100dvh;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
	.checkout-view__header--wrap::-webkit-scrollbar {
		width: 0;
	}
	.checkout-view__card-form-wrap {
		width: 59%;
		margin-left: 0;
	}
	.checkout-view__card-form {
		overflow: visible;
	}
	.checkout-view__header--wrap {
		padding: 0 15px 0 15px;
	}
}

.shopping-cart {
	position: fixed;
	top: 169px;
	right: 50px;
	z-index: 100;
	font-family: Montserrat;
	cursor: pointer;
}

.shopping-cart__modal-form-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	height: 100%;
}

.shopping-cart__modal-form--wrap {
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.shopping-cart__modal-form {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	background: #fff;
	max-width: 670px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 54px 15px 84px;
	height: 100%;
}

.shopping-cart__modal-form::-webkit-scrollbar {
	width: 5px;
	background-color: rgb(217, 214, 214);
}

.shopping-cart__modal-form-btn-close {
	position: absolute;
	top: 30px;
	right: 30px;
	width: 22px;
	height: 22px;
	cursor: pointer;
	z-index: 100;
	transition: 0.3s;
}

.shopping-cart__modal-form-btn-close:hover {
	opacity: 0.6;
}

.shopping-cart__modal-form-btn-close--desktop {
	display: none;
}

.shopping-cart__modal-form-btn-close--mobile-wrap {
	padding: 5px;
}

.shopping-cart__modal-form-btn-close--mobile {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 15px;
	height: 15px;
}

.shopping-cart__modal-form-title {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	background: #fff;
	padding: 15px 10px 15px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	font-size: 18px;
	font-weight: 600;
	border-top: 1px solid #d8d8d8;
	border-bottom: 1px solid #d8d8d8;
}

.shopping-cart__product-error {
	padding-top: 130px;
	padding-bottom: 130px;
	font-size: 22px;
	text-align: center;
	border: 1px solid #d8d8d8;
}

.shopping-cart__form-input-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 600px;
}

.shopping-cart__form-price {
	font-size: 18px;
	font-weight: 600;
	text-align: end;
	margin-top: 25px;
	margin-bottom: 25px;
}

.shopping-cart__modal-product-info-price-wrap {
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 16px;
}

.shopping-cart__modal-product-info-price-count-btn {
	font-size: 16px;
	width: 18px;
	height: 18px;
	border: 1px solid var(--purplish-gray);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.6;
	transition: 0.3s;
}

.shopping-cart__modal-product-info-price-count-btn:hover {
	opacity: 1;
}

.shopping-cart__modal-products {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 15px 8px 15px 0;
	margin-top: 20px;
	margin-bottom: 30px;
	margin-right: -8px;
	flex: 1 1;
	overflow: auto;
}

.shopping-cart__modal-products::-webkit-scrollbar {
	width: 5px;
	background-color: rgb(217, 214, 214);
}

.shopping-cart__modal-products::-webkit-scrollbar-thumb {
	background-color: rgb(171, 171, 171);
	border-radius: 3px;
}

.shopping-cart__modal-product-wrap {
	border-bottom: 1px solid #d8d8d8;
	padding-bottom: 20px;
}

.shopping-cart__modal-product--wrap {
}

.shopping-cart__modal-product {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 10px;
}

.shopping-cart__modal-product-img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	cursor: pointer;
}

.shopping-cart__modal-product-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;
	flex: 1 1;
}

.shopping-cart__modal-product-info-name-wrap {
}

.shopping-cart__modal-product-info-name {
	font-size: 15px;
	font-weight: 500;
	vertical-align: middle;
	color: var(--dark-charcoal);
	margin-bottom: 10px;
}

.shopping-cart__modal-product-info-name-code {
	color: var(--purplish-gray);
	font-size: 12px;
	font-weight: 400;
}

.shopping-cart__modal-product-info-price--wrap {
	display: flex;
	align-items: center;
	gap: 20px;
}

.shopping-cart__modal-product-info-price-wrap {
}

.shopping-cart__modal-product-info-price {
	line-height: 1.55;
	font-size: 16px;
	white-space: nowrap;
}

.shopping-cart__modal-product-delete-btn {
	width: 23px;
	min-width: 23px;
	height: 23px;
	opacity: 0.6;
	transition: 0.3s;
}

.shopping-cart__modal-product-delete-btn:hover {
	opacity: 1;
}

.shopping-cart__form-send-btn-wrap {
	margin-left: auto;
}

.shopping-cart__form-send-btn--wrap {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	gap: 15px;
}

.shopping-cart__form-btn-back {
	display: none;
	border: 1px solid var(--main-green);
	color: var(--dark-charcoal);
	font-size: 15px;
	font-weight: 500;
	padding: 10px 20px;
	height: 45px;
}

.shopping-cart__modal-product-info-variations {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 12px;
	font-weight: 400;
	color: var(--purplish-gray);
}

.shopping-cart__form-send-btn-buy {
	background: var(--main-green);
	width: 100%;
	text-align: center;
	font-size: 15px;
	color: #fff;
	height: 50px;
	font-weight: 500;
	padding: 13.3px 30px;
	border: 1px solid var(--main-green);
	transition: 0.3s;
}

.shopping-cart__form-send-btn-buy:hover {
	background: #fff;
	color: var(--dark-charcoal);
}

.shopping-cart__modal-form--is-safari {
	padding-bottom: 110px;
}

@media (min-width: 450px) {
	.shopping-cart__form-send-btn--wrap {
		flex-direction: row;
		align-items: center;
	}
}
@media (min-width: 450px) {
	.shopping-cart__modal-product-info-price--wrap {
		flex-direction: row;
		align-items: center;
		min-width: 200px;
	}
}

@media (min-width: 620px) {
	.shopping-cart__modal-product-info {
		flex-direction: row;
		align-items: center;
		min-height: 70px;
	}
	.shopping-cart__modal-product-info-name-wrap {
		align-self: flex-start;
	}
	.shopping-cart__modal-form {
		padding: 40px;
	}
	.shopping-cart__modal-product-delete-btn {
		align-self: center;
	}
	.shopping-cart__form-btn-back {
		display: block;
	}
	.shopping-cart__form-send-btn-buy {
		width: auto;
		height: 45px;
	}
}

@media (min-width: 670px) {
	.shopping-cart__modal-form {
		height: calc(100dvh - 40px);
		padding: 0 40px 40px;
	}
	.shopping-cart__modal-form-title {
		background: #fff;
		position: sticky;
		top: 0;
		padding: 40px 0 30px;
		z-index: 2;
		border-top: 0 solid #d8d8d8;
		border-bottom: 1px solid #d8d8d8;
		font-size: 20px;
	}
	.shopping-cart__modal-products {
		margin-top: 0;
	}
	.shopping-cart__modal-products {
		border-top: 0 solid #d8d8d8;
		margin-top: 30px;
	}
	.shopping-cart__modal-form--is-safari {
		height: calc(100dvh - 160px);
		margin-top: -20px;
	}
}

@media (min-width: 790px) {
	.shopping-cart__modal-form-btn-close--desktop {
		display: block;
	}
	.shopping-cart__modal-form-btn-close--mobile {
		display: none;
	}
}

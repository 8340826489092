.pagination-swiper {
    overflow: hidden;
}
 
.pagination-swiper .react-carousel-dots-dot.active {
    background-color: var(--main-green);
}

.pagination-swiper .dot-holder {
    width: 8px !important;
    height: 8px !important;
    margin: 0 5px !important;
}
.pagination-swiper .react-carousel-dots-dot.small {
    transform: scale(1) !important;
}

.pagination-swiper__bullet-wrap {
    display: flex;
    align-items: center;
    height: 19px;
    width: fit-content;
}

.pagination-swiper__bullet {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #fff;
    border: 1px solid #49454F;
    border-radius: 50%;
    opacity: 1;
    margin: 0 6px !important;
    transform: scale(1);
}

.pagination-swiper__bullet--active {
    position: relative;
    background: var(--main-green);
}
.pagination-swiper--1 {
    transform: translateY(-2px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
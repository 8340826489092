.product-variations {

}

.product-variations--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
}

.product-variations__select-wrap {
    width: 100%;
    background: #fff;
    padding: 24px 15px;
}

.product-variations__select-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 5px;
}

.product-variations__select-sub-title {
    font-size: 13px;
    color: #49454F;
    margin-bottom: 5px;
}

.product-variations__select-btn-wrap {
    display: flex;
    align-items: center;
    color: #2A7F77;
    font-size: 14px;
    font-weight: 400;
}

.product-variations__select-btn-img {
    width: 30px;
    height: 15px;
}

.product-variations__select-variations-wrap {
    display: flex;
    flex-direction: column;
}

.product-variations__modal-btn-wrap {
    display: flex;
    gap: 10px;
    justify-content: end;
    margin-top: 20px;
}

.product-variations__modal-btn-close {
    border: 1px solid #2A7F77;
    padding: 12px 20px;
    font-size: 14px;
}

.product-variations__modal-btn-add {
    background: #2A7F77;
    color: #fff;
    padding: 12px 20px;
    font-size: 14px;
}

.product-variations__products {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.product-variations__products-header {
    display: none;
    gap: 10px;
    justify-content: space-between;
    padding-right: 145px;
    font-weight: 500;
    width: 100%;
    min-width: 940px;
    white-space: nowrap;
    font-size: 13px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    padding-top: 25px;
}

.product-variations__product-img {
    width: 100px;
    aspect-ratio: 1/1; 
}

.product-variations__product-text {
    flex: 1 1;
    max-width: 880px;
    min-width: 359px;
}

.product-variations__product-text-variation {
    width: 190px;
}

.product-variations__product-text-price {
    width: 100px;
}

.product-variations__product-text-code {
    width: 100px;
}

.product-variations__btn-close {
    width: 20px;
    height: 20px;
}

.product-variations__btn-close--mob {
    width: 15px;
    height: 15px;
}

.product-variations__btn-close--des {
    display: none;
}

.product-variations__modal-wrap {
    overflow: auto;
}

.product-variations__produc-accordion {
    border-top: 1px solid #D8D8D8;
}

.product-variations__produc-accordion .MuiAccordionSummary-root {
    border-bottom: 1px solid #D8D8D8;
}

.creating-variations__characteristics-result-color-title {
    max-width: 280px;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: var(--dark-charcoal);
}

.product-variations__label-checkbox {
    margin-bottom: 20px;
}

@media (min-width: 600px) {
    .product-variations__btn-close--mob {
        display: none;
    }
    .product-variations__btn-close--des {
        display: block;
    }
    .product-variations__btn-close {
        position: fixed;
        top: 25px;
        right: 25px;
    }
}
@media (min-width: 768px) {
    .product-variations__products-header {
        display: flex;
    }
    .product-variations__select-wrap {
        padding: 24px;
    }
    .product-variations__btn-close--des {
        width: 20px;
        height: 20px;
    }
    .product-variations__select-title {
        font-size: 21px;
    }
    .product-variations__select-sub-title {
        font-size: 14px;
    }
}
@media (min-width: 1300px) {
    .product-variations__product-text-variation {
        width: 290px;
    }
}
.input-phone {
    position: relative;
    width: 100%;
    max-width: 380px;
}

.input-phone .flag-dropdown {
    display: flex;
    align-items: center;
    background: inherit !important;
    border: none !important;
}

.react-tel-input .form-control {
    font-size: 14px !important;
}

.react-tel-input .selected-flag {
    padding: 0 0 0 13px !important;
    height: 40px !important;
    margin-left: 2px !important;
}

.input-phone .form-control {
    width: 100% !important;
    max-width: 380px;
    height: 60px !important;
    border-radius: 0 !important;
    font-size: 14px;
    background: inherit !important;
}

.input-phone .form-control::placeholder {
    position: absolute;
    top: 100px;
    z-index: -10;
    opacity: 0;
}

.input-phone__placeholder {
    position: absolute;
    top: 50%;
    left: 91px;
    transform: translateY(-50%);
    color: #757575;
    font-size: 15px;
}

.input-phone__order {
    margin-bottom: 20px;
    width: 100% !important;
    max-width: 600px !important;
}

.input-phone__order .selected-flag {
    background: inherit !important;
}

.input-phone__order .form-control {
    width: 100% !important;
    max-width: 600px !important;
    height: 40px !important;
    border: none !important;
    font-size: 15px !important;
    border-bottom: 1px solid #2e2e2e !important;
}

.input-phone__partner-registration {
    max-width: 600px !important;
}

.input-phone__partner-registration .form-control {
    width: 100% !important;
    max-width: 600px !important;
    height: 55px !important;
    border: none !important;
    font-size: 15px !important;
    border-bottom: 1px solid #2e2e2e !important;
    padding-left: 38px !important;
}

.input-phone__partner-registration .selected-flag {
    padding-left: 0 !important;
}

.input-phone__partners {
    margin-bottom: 20px;
}

.input-phone__partners .input-phone__placeholder {
    color: rgb(147 190 186);
}

.input-phone__partners .form-control {
    border: none !important;
    border-bottom: 1px solid #fff !important;
    height: 50px !important;
}

.input-phone__partners .arrow {
    border-top: 4px solid #a29b9b;
}

.input-phone__partners .selected-flag  {
    background: inherit !important;
}

.input-phone__partners .country  {
    color: #000;
}

.input-phone__product-view {
    margin-bottom: 15px;
}

.input-phone__product-view  .flag-dropdown  {
    transform: translateX(-14px);
}

.input-phone__product-view .form-control {
    border: none !important;
    border-bottom: 1px solid #D8D8D8 !important;
    padding-left: 35px !important;
}

.input-phone__vendor-view {
    max-width: 500px;
}

.input-phone__vendor-view .form-control {
    max-width: 500px;
    font-size: 15px !important;
}

.input-phone__error {
    
}

.input-phone__error .form-control {
    border: 1px solid red !important;
}

.input-phone__order-veiw-error .form-control {
    border: 0 solid red !important;
    border-bottom: 1px solid red !important;
}

.input-phone__product-view-error .form-control {
    border: 0 solid red !important;
    border-bottom: 1px solid red !important;
}

.input-phone__partners-veiw-error .form-control {
    border: 0 solid #ff7b7b !important;
    border-bottom: 1px solid #ff7b7b !important;
}

.input-phone__placeholder--product-view {
    left: 75px;
}

.input-phone__checkout {
    max-width: 100%;
}

.input-phone__checkout .form-control {
    border: 0 solid #D8D8D8 !important;
    border-bottom: 1px solid #D8D8D8 !important;
    height: 50px !important;
    font-size: 15px !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 35px !important;
}

.input-phone__checkout .selected-flag {
    padding: 0 !important;
    margin-left: 0 !important;
    width: 30px;
}

.input-phone__placeholder--checkout-view {
    left: 77px;
}

.input-phone__checkout-view-error .form-control {
    border: 0 solid #ff7b7b !important;
    border-bottom: 1px solid #ff7b7b !important;
}

.input-phone__placeholder--vendor-view {

}

.input-phone__placeholder--partner-registration {
    left: 81px;
    color: #757575;
}


@media (min-width: 640px) {
    .input-phone {
        max-width: 600px;
    }
    .input-phone .form-control {
        max-width: 600px;
    }
    .react-tel-input .form-control {
        font-size: 16px !important;
    }
    .input-phone__placeholder {
        top: 50%;
        font-size: 16px;
    }
    .input-phone__order .form-control {
        font-size: 15px !important;
    }
    .input-phone__order .input-phone__placeholder {
        top: 50%;
    }
    .input-phone__placeholder--vendor-view {
        font-size: 15px;
    }
}

@media (min-width: 960px) {
    .input-phone__partner-registration {
        max-width: 620px !important;
    }
    .input-phone__partner-registration .form-control {
        max-width: 620px !important;
    }
}
@media (min-width: 1200px) {
    .input-phone__partner-registration {
        max-width: 800px !important;
    }
    .input-phone__partner-registration .form-control {
        max-width: 800px !important;
    }
}
.skeleton-wrap__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: 100%; 
    z-index: 1;
    background: #fff;
}

.skeleton-wrap {
    width: 50%;
    height: auto;
    padding: 4px 4px 85px 4px;
    margin-bottom: 30px;
}

.skeleton-wrap__img {
    width: 100%;
    height: auto !important;
    aspect-ratio: 1/1 !important;
}

.skeleton-wrap__variations {
    width: 100%;
    height: 47px !important;
    margin-top: 7px;
}

.skeleton-wrap__text {
    height: 22px !important;
    margin-top: 7px;
}

.skeleton-wrap__text-1 {
    width: 100%;
}
.skeleton-wrap__text-2 {
    width: 30%;
}
.skeleton-wrap__text-3 {
    width: 50%;
}

@media (min-width: 640px) {
    .skeleton-wrap {
        width: 33.3%;
    }
}
@media (min-width: 1200px) {
    .skeleton-wrap {
        width: 25%;
        max-width: 25%;
    }
}
.main-banner--wrap {
}

.main-banner-wrap {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    padding: 40px 15px;
    font-size: 12px;
    z-index: 1;
}

.main-banner__text-wrap {
    width: 100%;
}

.main-banner__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 120%; 
    color: var(--dark-charcoal);
    margin-bottom: 25px;
}

.main-banner__swiper {
    width: 100%; 
    z-index: 0 !important;
}

.main-banner__swiper.swiper {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 393px;
    padding-bottom: 20px;
}

.main-banner__swiper .swiper-slide {
    width: 300px !important;
}

.main-banner__swiper-slider {
    max-width: 400px !important;
    display: flex;
    align-items: flex-start;
}

.main-banner__swiper-slider-img {
    width: 300px;
    height: 373px;
    object-fit: cover;
}

.main-banner__swiper .swiper-button-prev {
    display: none;
} 

.main-banner__swiper .swiper-pagination {
    transform: translate(0, 11px);
}
.main-banner__swiper .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #fff;
    border: 1px solid #49454F;
    opacity: 1;
    margin: 0 10px !important;
}

.main-banner__swiper .swiper-pagination-bullet-active {
    position: relative;
    background: var(--main-green);
}

.main-banner__swiper {
    overflow-x: hidden;
    scroll-snap-type: block;
    white-space: nowrap;
  }
  
  .main-banner__swiper-slider {
    scroll-snap-align: start;
  }

@media (min-width: 576px) {
    .main-banner__text {
        font-size: 24px;
    }
}
@media (min-width: 640px) {
    .main-banner-wrap {
        flex-direction: column-reverse;
        padding: 40px 0 40px 15px;
        font-size: 14px;
        gap: 44px;
    }
    .main-banner__swiper .swiper-button-next {
        transform: translateX(-5px);
    }
    .main-banner__text {
        margin-bottom: 29px;
    }
    .main-banner-wrap {
        flex-direction: row;
        height: 516px;
        gap: 10px;
        padding: 44px 15px;
    }
    .main-banner__swiper.swiper {
        width: 300px;
        height: 440px;
        margin-right: 0;
        padding-bottom: 0;
    }
    .main-banner__text-wrap {
        max-width: 48%;
    }
    .main-banner__swiper-slider-img {
        width: 300px;
        height: auto;
    }
    .main-banner__swiper .swiper-slide {
        width: 300px !important;
    }
    .main-banner__swiper .swiper-pagination {
        transform: translate(0 , -8px);
    }
    .main-banner__swiper .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
    }
    .main-banner__swiper .swiper-pagination {
        transform: translate(0, -8px);
    }
}
@media (min-width: 700px) {
    .main-banner__text {
        font-size: 25px;
    }
}
@media (min-width: 730px) {
    .main-banner-wrap {
        width: 93%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 800px) {
    .main-banner-wrap {
        width: 720px;
    }
}
@media (min-width: 750px) {
    .main-banner__swiper {
        display: block !important;
        width: 337px;
    }
    .main-banner__text {
        max-width: 553px;
    }
    .main-banner__swiper.swiper {
        margin-right: 0;
    }
}
@media (min-width: 960px) {
    .main-banner__text {
        font-size: 28px;
    }
    .main-banner__swiper.swiper {
        width: 617px;
    }
    .main-banner__text-wrap {
        width: 34%;
        padding-top: 30px;
    }
    .main-banner__swiper .swiper-pagination {
        transform: translate(162px , -8px);
    }
    .main-banner-wrap {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1100px) {
    .main-banner__text-wrap {
        padding-left: 20px;
    }
}
@media (min-width: 1120px) {
    .main-banner__text-wrap {
        width: 38%;
    }
}
@media (min-width: 1200px) {
    .main-banner__swiper {
        width: 687px;
        right: 15px;
    }
    .main-banner__text {
        max-width: 700px;
        font-size: 35px;
    }
    .main-banner--wrap {
        padding-left: 60px;
    }
    .main-banner__text-wrap {
        padding-left: 0;
    }
}
@media (min-width: 1265px) {
    .main-banner__swiper {
        width: 684px;
    }
}
@media (min-width: 1370px) {
    .main-banner__text-wrap {
        width: 49%;
    }
}
@media (min-width: 1552px) {
    .main-banner__text-wrap {
        width: 33%;
    }
    .main-banner__swiper.swiper {
        width: 934px;
    }
    .main-banner__swiper .swiper-pagination {
        transform: translate(160px , -8px);
    }
}
@media (min-width: 1680px) {
    .main-banner__text {
        font-size: 40px;
    }
    .main-banner__swiper {
        width: 1040px;
    }
    .main-banner__text-wrap {
        width: 39%;
    }
}
@media (min-width: 2100px) {
    .main-banner__swiper {
        width: 1271px;
    }
    .main-banner__swiper.swiper {
        width: 1271px;
    }
    .main-banner__swiper .swiper-pagination {
        transform: translate(11px , -8px);
    }
}
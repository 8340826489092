
.be-seller {
    display: flex;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    background-image: url('./../../assets/tablet2.png');
}

.be-seller-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 30px 15px;
    background-color: #f9f9f9;
}

.be-seller-text-title {
    width: 100%;
    line-height: 35px;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
}

.be-seller-text-text {
    margin-bottom: 20px;
    line-height: 22.5px;
    font-size: 15px;
    font-weight: 400;
}

.be-seller-text-btn--wrap {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.be-seller-text-btn-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.be-seller-text-img {
    width: 290px;
    margin-left: auto;
    object-fit: cover;
    margin-right: auto;
    margin-bottom: 25px;
    height: 360px;
}

@media (min-width: 640px) {
    .be-seller-text-title {
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 30px;
    }
    .be-seller-text-wrap {
        padding: 50px 30px 50px 15px;
    }
    .be-seller-text-text {
        margin-bottom: 27px;
    }
    .be-seller-text-img {
        width: 350px;
        height: 430px;
        margin-bottom: 30px;
    }
}
  
@media (min-width: 750px) {
    .be-seller-text-img {
        display: none;
    }
    .be-seller {
        height: 440px;
    }
    .be-seller-text-wrap {
        width: 50%;
        height: calc(100% + 2px);
        transform: translateX(-1px);
        background-color: #ffffffd4;
        padding: 30px 15px 30px 25px;
    }
    .be-seller-text-title {
        font-weight: 600;
    }
    
}
@media (min-width: 960px) {
    .be-seller {
        width: 100%;
        height: 460px;
        padding-left: 0;
    }
    .be-seller-text-wrap {
        width: 51%;
        max-width: 805px;
        padding: 100px 30px 100px 48px;
    }
    .be-seller-text-title {
        font-size: 28px;
        font-weight: 600;
    }
}
@media (min-width: 1200px) {
    .be-seller-text-title {
        font-size: 34px;
    }
}
@media (min-width: 1680px) {
    .be-seller-text-text {
        font-size: 16px;
        line-height: 23px;
    }
    .be-seller {
        height: 525px;
    }
}
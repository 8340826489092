.how-buy-view {
    font-size: var(--text-size);
    color: #49454F;
}

.how-buy-view__modal-zoom-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 100vw;
    height: 100vh;
    min-width: -webkit-fill-available;
    min-height: -webkit-fill-available;
}

.how-buy-view__modal-zoom-wrap--is-safari {
    padding-bottom: 85px;
}

.how-buy-view__modal-zoom-bg {
}

.how-buy-view__modal-zoom-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.how-buy-view__modal-zoom-btn-close-img {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    z-index: 100;
    transition: .3s;
}

.how-buy-view__modal-zoom-btn-close-img:hover {
    opacity: .6;
}

.how-buy-view--wrap {
}

.how-buy-view__title-wrap {
    background-image: -webkit-linear-gradient(top, rgba(241,242,242,1), rgba(241,242,242,1));
    padding: 68px 20px;
}

.how-buy-view__title {
    font-size: var(--main-title-size);
    font-weight: 700;
    max-width: 1200px;
    text-align: center;
    color: var(--dark-charcoal)
}

.how-buy-view__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 60px 15px;
    max-width: 1200px;
}

.how-buy-view__content-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
}

.how-buy-view__content-card-text-wrap {
    line-height: 1.55;
}

.how-buy-view__content-card-text-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.how-buy-view__content-card-text {
    line-height: 1.55;
    max-width: 788px;
    width: 100%;
}

.how-buy-view__content-card-text-li {
    list-style-type: disc;
    margin-left: 20px;
}

.how-buy-view__content-card-img {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.how-buy-view__content-card-img:hover {
    cursor: zoom-in;
}

.how-buy-view__product-wrap {
    background-color: #f1f2f2;
    padding-top: 60px;
    padding-bottom: 60px;
}

.how-buy-view__product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    max-width: 1200px;
    padding: 0 15px;
}

.how-buy-view__product-text-wrap {
    width: 100%;
}

.how-buy-view__product-text-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--dark-charcoal)

}

.how-buy-view__product-text {
    line-height: 1.55;
    max-width: 788px;
    width: 100%;
}

.how-buy-view__product-swiper-wrap {
    height: 100%;
}

.how-buy-view__product-swiper-wrap .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.how-buy-view__product-swiper-wrap .swiper-pagination-bullet {
    position: relative;
}    

.how-buy-view__product-swiper-wrap .swiper-pagination-bullet::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -6px;
    width: 20px;
    height: 20px;
}

.how-buy-view__product-swiper {
    max-width: 600px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.how-buy-view__product-swiper-img-wrap {
    height: auto;
    object-position: left;
}

.how-buy-view__product-swiper-img {
}

.how-buy-view__product-swiper-img:hover {
    cursor: zoom-in;
}

.how-buy-view__product-img {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.how-buy-view__product-img:hover {
    cursor: zoom-in;
}

.how-buy-view__info-wrap {
    display: flex;
    flex-direction: column;
    gap: 120px;
    max-width: 1200px;
    padding: 60px 15px 20px;
}

.how-buy-view__info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.how-buy-view__info-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    color: var(--dark-charcoal);
    line-height: 1.17;
}

.how-buy-view__info-text {
    line-height: 1.55;
}

.how-buy-view__banner-wrap {

}

.how-buy-view__content-card-text--1 {
    padding-top: 2px;
}

.how-buy-view__modal-zoom-swiper-wrap {
    height: 90vh;
}

.how-buy-view__modal-zoom-swiper-wrap .swiper-pagination {
    bottom: 0 !important;
}

.how-buy-view__modal-zoom-swiper-wrap .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.how-buy-view__modal-zoom-swiper-wrap .swiper-pagination-bullet {
    position: relative;
}    

.how-buy-view__modal-zoom-swiper-wrap .swiper-pagination-bullet::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -6px;
    width: 20px;
    height: 20px;
}

.how-buy-view__product-swiper-img  {
    object-position: left;
}

@media (min-width: 640px) {
    .how-buy-view__content-card-text-title {
        margin-bottom: 45px;
    }
    .how-buy-view__product-text-title {
        margin-bottom: 45px;
    }
    .how-buy-view__info {
        gap: 45px;
    }
}
@media (min-width: 960px) {
    .how-buy-view__content-card {
       flex-direction: row; 
       max-width: 100%;
    }
    .how-buy-view__content-card-text-wrap {
        padding-top: 5px;
        width: 42%;
    }
    .how-buy-view__content-card-img {
        width: 49%;
    }
    .how-buy-view__content-card-img--2 {
        margin-left: 0;
    }
    .how-buy-view__content-card-text {
        margin-bottom: 0;
    }
    .how-buy-view__product-text-wrap {
        width: 42%;
    }
    .how-buy-view__product {
        flex-direction: row;
        gap: 30px;
    }
    .how-buy-view__product-swiper-wrap {
        width: 49%;
        margin-left: auto;
        margin-right: auto;
    }
    .how-buy-view__product-img {
        width: 49%;
    }
    .how-buy-view__product-wrap {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .how-buy-view__info {
        flex-direction: row;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .how-buy-view__info-text {
        max-width: 65%;
    }
    .how-buy-view__content {
        padding: 70px 15px;
        gap: 140px;
    }
    .how-buy-view__info-wrap {
        padding: 70px 15px 30px;
        gap: 140px;
    }
}

@media (min-width: 1200px) {
    .how-buy-view__content {
        padding: 70px 30px;
    }
    .how-buy-view__info-wrap {
        padding: 70px 30px 30px;
    }
    .how-buy-view__product {
        padding: 0 30px;
    }
    .how-buy-view__content-card-img--2 {
        margin-right: -16px;
    }
    .how-buy-view__content-card-text-wrap {
        width: 50%;
    }
    .how-buy-view__content-card {
        gap: 10px;
    }
    .how-buy-view__product-text-wrap {
        width: 50%;
    }
    .how-buy-view__product {
        gap: 10px;
    }
}
@media (min-width: 1680px) {
    .how-buy-view__content {
        max-width: 1500px;
    }
    .how-buy-view__product {
        max-width: 1500px;
    }
    .how-buy-view__info-wrap {
        max-width: 1500px;
    }
    .how-buy-view__content-card {
        gap: 35px;
    }
    .how-buy-view__content-card-img {
        max-width: 740px;
    }
    .how-buy-view__content-card-text-wrap {
        padding-top: 9px;
    }
    .how-buy-view__content-card-img--2 {
        max-width: 740px;
    }
    .how-buy-view__product-swiper-wrap .swiper-pagination {
        left: 45px !important;
    }
    .how-buy-view__product-swiper {
        max-width: 740px;
    }
}
.offer-view {
	font-size: 18px;
	padding: 80px 0;
	max-width: 1000px;
	margin: 0 auto;
	font-size: 18px;

	h1 {
		font-size: 25px;
		line-height: 36px;
		margin-bottom: 36px;
	}

	.column {
		padding: 8px 0;
	}

	p {
		margin-bottom: 16px;
		line-height: 28px;
	}

	strong {
		font-weight: 600;
	}

	a {
		color: var(--Bubibo-Purple, #3700ff);
		text-decoration: underline;
		line-height: 28px;
	}

	sup {
		font-size: small;
		top: -6px;
		position: relative;
	}

	span {
		line-height: 28px;
	}

	ul {
		list-style: none;

		> li {
			margin-left: 16px;
		}
	}

	table,
	th,
	td {
		border: 1px solid black;
	}

	td {
		padding: 8px;
	}
}

.offer-view--wrap {
}

.offer-view__public-offer {
	width: 100vw;
	height: 100vh;
}

@media (max-width: 1024px) {
	.offer-view {
		padding: 40px 16px;
	}
}

.benefits-block__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 70px;
}

.benefits-block__item {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 15px;
    width: 287px;
}

.benefits-block__item-img {
    width: 50px;
    height: 50px;
}

@media (min-width: 640px) {
    .benefits-block__item {
        font-size: 16px;
        width: 46%;
        max-width: 302px;
        margin-bottom: 20px;
    }
    .benefits-block__items {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 50px;
    }
}
@media (min-width: 976px) {
    .benefits-block__item {
        width: 23.4%;
        max-width: 375px;
    }
}
@media (min-width: 1200px) {
    .benefits-block__items {
        justify-content: space-between;
    }
}
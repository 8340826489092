.product-view {
	color: var(--dark-charcoal);
	background: #fff;
	max-width: 100vw;
	min-height: 100vh;
}

.product-view__drawer {
}

.product-view__drawer .MuiBackdrop-root {
	background-color: transparent;
}

.product-view__drawer .MuiPaper-root {
	border-left: 1px solid #d8d8d8;
}

.product-view__drawer-result--wrap {
	min-width: 280px;
	max-width: 460px;
	transform: translateY(-1px);
}

.product-view__drawer-result-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px;
}

.product-view__drawer-result-title-wrap {
	display: flex;
	gap: 10px;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 20px;
	border-bottom: 1px solid #d8d8d8;
}

.product-view__drawer-result-title {
	flex: 1 1;
	text-align: center;
	font-weight: 600;
	font-size: 16px;
}

.product-view__drawer-result-title-btn {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.product-view__variations-select-color-btn-all {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 48px;
	min-width: 48px;
	height: 48px;
	padding: 4px;
	cursor: pointer;
	border: 1px solid #c8c8c8;
}

.product-view__modal-form-wrap {
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-height: 100dvh;
	width: 100%;
}

.product-view__modal-form--wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
}

.product-view__modal-form {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	background: #fff;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-size: 15px;
	overflow: auto;
}

.product-view__form-input-label {
	color: #898989;
	margin-left: -1px;
}

.product-view__modal-form-text-wrap {
	padding: 20px 15px 55px;
	height: calc(100vh - 99px);
	overflow: auto;
}

.product-view__modal-form-btn-close {
	position: absolute;
	top: 21px;
	right: 18px;
	width: 20px;
	height: 20px;
	cursor: pointer;
	z-index: 100;
	transition: 0.3s;
}

.product-view__modal-form-btn-close:hover {
	opacity: 0.6;
}

.product-view__modal-form-btn-close--desktop {
	display: none;
}

.product-view__modal-form-text--wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	z-index: 2;
	height: 100dvh;
	padding-top: 50px;
}

.product-view__modal-form-title-wrap {
	display: flex;
	--webkit-display: flex;
	z-index: 1;
	background: #fff;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #d8d8d8;
	padding: 10.5px 20px 10.5px 15px;
	width: 100%;
}

.product-view__modal-form-title {
	font-size: 16px;
	font-weight: 600;
}

.product-view__modal-form-text {
	line-height: 1.55;
	margin-bottom: 12px;
}

.product-view__form-input-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 600px;
	margin-top: 25px;
	padding-bottom: 35px;

	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	transform: scale(1);
}

.product-view__form-input {
	height: 50px;
	line-height: 1.33;
	width: 100%;
	border-bottom: 1px solid #d8d8d8;
	margin-bottom: 15px;
	-webkit-border-radius: 0;
	border-radius: 0;
	font-size: 15px;
}

.product-view__form-input::placeholder {
	color: #898989;
}

.product-view__form-input:-webkit-autofill {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	caret-color: #19191d;
}

.product-view__form-input:-webkit-autofill:focus {
	-webkit-text-fill-color: #19191d;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	caret-color: #19191d;
}

.product-view__form-count-wrap {
	display: flex;
	align-items: center;
	gap: 25px;
	padding: 0 15px;
	font-size: 20px;
	margin-top: 3px;
	margin-bottom: 20px;
}

.product-view__form-count-btn {
	font-size: 28px;
	padding: 3px;
}

.product-view__form-count-text {
	display: flex;
	border-bottom: 1px solid #d8d8d8;
	width: 70px;
	align-items: center;
	height: 50px;
	justify-content: center;
}

.product-view__form-textarea {
	resize: none;
	border: none;
	border-bottom: 1px solid #d8d8d8;
	height: auto;
	outline: none;
	margin-top: 12px;
	font-family: 'Montserrat' !important;
	font-size: 15px;
	padding: 0;
	caret-color: #19191d;
}

.product-view__form-textarea::placeholder {
	font-family: 'Montserrat' !important;
	font-size: 15px !important;
}

.product-view-wrap {
	padding: 0 10px;
	margin-left: auto;
	margin-right: auto;
}

.product-view__product {
	display: flex;
	flex-direction: column;
	gap: 9px;
	margin-left: auto;
	margin-right: auto;
}

.product-view__product-swiper-wrap {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 9px;
}

.product-view__product-swiper-2-wrap {
	position: relative;
	width: calc(100% + 20px);
	height: calc(100% - 35px);
	margin-left: -10px;
	padding-bottom: 20px;
}

.product-view__product-slider-2-mark-wrap {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	background: var(--dark-charcoal);
	font-weight: 400;
	font-size: 12px;
	color: #fff;
	padding: 1px 8px;
	z-index: 5;
}

.product-view__product-swiper-1-wrap .swiper-slide {
	aspect-ratio: 1/1;
}
.product-view__product-swiper-2-wrap .swiper-slide {
}

.product-view__product-swiper-1-wrap .swiper-slide {
	cursor: pointer;
	width: 80px !important;
	height: 80px !important;
	margin-bottom: 10px;
}

.product-view__product-swiper-2-wrap .swiper-button-next {
	display: none;
	width: 30px !important;
	height: 30px !important;
	background-color: rgb(255, 255, 255);
	border-radius: 50%;
	filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
	transition: 0.3s;
}

.product-view__product-swiper-2-wrap .swiper-button-next::after {
	font-size: 14px !important;
	color: #000;
	margin-left: 3px;
	margin-top: 1px;
}

.product-view__product-swiper-1--wrap {
	justify-content: flex-start;
	width: 100%;
	display: flex;
	gap: 15px;
}

.product-view__product-swiper-1-wrap {
	display: none;
	position: relative;
	width: 100%;
	height: 100%;
}

.product-view__product-swiper-1-wrap .swiper-slide {
	border: 1px solid transparent;
}

.product-view__product-swiper-1-wrap .swiper-slide-thumb-active {
	border: 1px solid #000;
}

.product-view__product-swiper-1-wrap .swiper-wrapper {
	display: flex;
	flex-direction: row;
}

.product-view__product-swiper-2-wrap .swiper-button-prev {
	display: none;
	width: 30px !important;
	height: 30px !important;
	transition: 0.3s;
}

.product-view__product-swiper-2-wrap .swiper-button-prev::after {
	font-size: 14px !important;
	color: #000;
}

.product-view__product-swiper-1-wrap .swiper-button-prev {
	position: absolute;
	z-index: -10 !important;
	opacity: 0;
	height: 91px !important;
	background-color: rgb(255, 255, 255);
	position: absolute;
	top: 74%;
	left: -40px;
	transform: translateY(-50%);
	border-radius: 0 !important;
	filter: none !important;
}

.product-view__product-swiper-1-wrap .swiper-button-prev::after {
	font-size: 16px;
	color: #000;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view__product-swiper-1-wrap .swiper-button-next {
	position: absolute;
	z-index: -10 !important;
	opacity: 0;
	height: 91px !important;
	width: 44px !important;
	background-color: rgb(255, 255, 255);
	position: absolute;
	right: -40px;
	top: 75%;
	border-radius: 0 !important;
	transform: translateY(-50%);
	filter: none !important;
}

.product-view__product-swiper-1-wrap .swiper-button-next::after {
	font-size: 16px;
	color: #000;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view__product-swiper-1-video-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 70px;
	gap: 0;
}

.product-view__product-swiper-1-video {
}

.product-view__product-swiper-1-video--shorts {
	background: #000;
}

.product-view__product-swiper-model-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 70px;
}

.product-view__product-swiper-model {
	border: 1px solid var(--neutral-gray);
	width: 65px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-8px);
}

.product-view__product-swiper-model-img {
	width: 32px;
	height: 32px;
}

.product-view__product-swiper-1-video-wrap .react-player__play-icon {
	display: none;
	justify-content: flex-end;
}

.product-view__product-swiper-1-video-block {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.product-view__product-swiper-1-video-block-img {
	width: 42px;
	height: 42px;
	transform: translateY(-10px);
}

.product-view__product-swiper-1-video-text {
	border-bottom: 1px solid;
	font-size: 12px;
	transition: 0.3s;
}

.product-view__product-swiper-1-video-text--video {
	border-bottom: 1px solid;
	font-size: 12px;
	margin-top: 8px;
	transition: 0.3s;
}

.product-view__product-swiper-1-video-wrap:hover
	.product-view__product-swiper-1-video-text--video,
.product-view__product-swiper-model-wrap:hover
	.product-view__product-swiper-1-video-text {
	color: var(--main-green);
}

.product-view__product-swiper-img-none-wrap {
	aspect-ratio: 1/1;
	max-width: calc(100% - 90px);
	max-width: 650px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.product-view__product-slider-video-2-zoom-wrap {
	aspect-ratio: 1/1;
	margin-left: auto;
	margin-right: auto;
	max-width: 650px;
}

.product-view__product-swiper-2-wrap .swiper-pagination {
	bottom: 2px;
}

.product-view__product-swiper-2-wrap .swiper-pagination-bullet {
	position: relative;
	width: 8px;
	height: 8px;
	background: #fff;
	border: 1px solid #49454f;
	opacity: 1;
	margin: 0 6px !important;
	transform: scale(1);
}

.product-view__product-swiper-2-wrap .swiper-pagination-bullet::after {
	content: '';
	position: absolute;
	top: -5px;
	left: -6px;
	width: 20px;
	height: 20px;
}

.product-view__product-swiper-2-wrap .swiper-pagination-bullet-active {
	position: relative;
	background: var(--main-green);
}

.product-view__product-swiper-2-wrap .swiper-button-next,
.product-view__product-swiper-2-wrap .swiper-button-prev {
	z-index: -2 !important;
	opacity: 0 !important;
	width: 0 !important;
	height: 0 !important;
}

.product-view__product-text-wrap {
	width: 100%;
}

.product-view__product-text-title {
	line-height: 120%;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 5px;
	color: var(--dark-charcoal);
}

.product-view__product-text-code {
	color: var(--neutral-gray);
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 10px;
}

.product-view__product-text-sub-title {
	color: var(--dark-charcoal);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 10px;
}

.product-view__product-text-vendor {
	text-decoration: underline;
	margin-bottom: 10px;
	font-weight: 400;
	cursor: pointer;
	transition: 0.3s;
}

.product-view__product-text-vendor:hover {
	color: var(--main-green);
}

.product-view__product-text-price {
	display: flex;
	gap: 5px;
	font-weight: 500;
	color: var(--dark-charcoal);
	font-size: 22px;
	margin-top: 20px;
	margin-bottom: 8px;
}

.product-view__product-text-time {
	font-size: 14px;
	font-weight: 400;
	color: var(--main-green);
	margin-bottom: 20px;
}

.product-view__rating-wrap {
	display: flex;
	gap: 5px;
	align-items: flex-start !important;
}

.product-view__rating {
	transform: translateY(3px);
}

.product-view__rating .MuiRating-decimal {
	color: rgba(58, 58, 58, 1);
	font-size: 15px;
}

.product-view__reviews {
	text-decoration: underline;
	font-size: 14px;
	font-weight: 600;
	line-height: 150%;
	cursor: pointer;
	transition: 0.3s;
}

.product-view__reviews:hover {
	color: var(--main-green);
}

.product-view__variations-select-wrap {
	margin-top: 20px;
	margin-bottom: 10px;
}

.product-view__variations-select-wrap .MuiInputBase-root {
	height: 45px;
}
.product-view__variations-select-wrap
	.Mui-focused
	.MuiOutlinedInput-notchedOutline {
	border-width: 1px !important;
}

.product-view__variations-select-title {
	display: flex;
	align-items: center;
	color: var(--dark-charcoal);
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 11.5px;
}

.product-view__variations-select-title-variations {
	margin-bottom: 4px;
}

.product-view__variations-select-title-text {
	font-weight: 400;
}

.product-view__variations-select-title-text--mobile {
	position: relative;
	cursor: pointer;
	color: var(--main-green);
	text-decoration: underline;
}

.product-view__variations-select-title-text--desc {
	display: none;
}

.product-view__variations-select-color-wrap {
	display: flex;
	gap: 10px;
	align-items: flex-start;
	height: 48px;
}

.product-view__variations-select {
	width: 174px;
	height: 35px;
	border-radius: 0 !important;
}

.product-view__variations-select .MuiNativeSelect-select {
	padding: 10px 14px;
}
.product-view__variations-select .MuiOutlinedInput-notchedOutline.Mui-focused {
	border-width: 1px !important;
}

.product-view__variations-select .MuiOutlinedInput-notchedOutline {
	border-color: var(--neutral-gray);
}

.product-view__product-text-buy-btn-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 13px;
}

.product-view__product-text-buy-btn-count-wrap {
	display: flex;
	align-items: center;
	gap: 5px;
	min-width: 121px;
	justify-content: space-between;
	border: 1px solid var(--neutral-gray);
	padding: 0 15px;
	font-size: 16px;
	font-weight: 500;
	height: 45px;
}

.product-view__product-text-buy-btn-count-wrap-none {
	pointer-events: none;
	opacity: 0.5;
}

.product-view__product-text-buy-btn-count {
	font-size: 33px;
}

.product-view__product-text-buy-btn-count-del {
	position: relative;
	width: 18px;
	height: 40px;
}

.product-view__product-text-buy-btn-count-del::after {
	content: '';
	position: absolute;
	top: 51%;
	left: 0;
	transform: translateY(-50%);
	width: 13px;
	height: 2px;
	background: #000;
}

.product-view__like-btn-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	width: 45.1px;
	border: 1px solid var(--neutral-gray);
	padding: 7px 7.5px;
}

.product-view__like-btn-wrap:hover .product-view__like-btn-1 {
	display: none;
}

.product-view__like-btn-wrap:hover .product-view__like-btn-2 {
	display: block;
}

.product-view__like-btn {
	width: 22.5px;
	height: 19px;
}

.product-view__like-btn-1 {
}

.product-view__like-btn-2 {
	display: none;
}

.product-view__product-text-buy {
	padding: 13.3px 25px;
	min-width: 172px;
	flex: 1 1;
}

.product-view__product-text-request-btn-wrap {
	border: 1px solid #d8d8d8;
	padding: 0 15px;
}

.product-view__product-text-request-btn {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 15px 0;
	font-family: Montserrat;
	transition: 0.3s;
}

.product-view__product-text-request-btn:hover
	.product-view__product-text-request-btn-text {
	color: var(--main-green);
}

.product-view__product-text-request-btn:hover
	.product-view__product-text-request-btn-text-img--green,
.product-view__product-text-request-btn:hover
	.product-view__product-text-request-btn-text-img-arrow--green {
	display: block;
}

.product-view__product-text-request-btn:hover
	.product-view__product-text-request-btn-text-img,
.product-view__product-text-request-btn:hover
	.product-view__product-text-request-btn-text-img-arrow {
	display: none;
}

.product-view__product-text-request-btn--border {
	border-top: 1px solid #d8d8d8;
}

.product-view__product-text-request-btn-text-wrap {
	display: flex;
	gap: 10px;
	align-items: center;
}

.product-view__product-text-request-btn-text {
	color: var(--dark-charcoal);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	transition: 0.1s;
}

.product-view__product-text-request-btn-text-img {
	width: 15px;
	height: 15px;
}

.product-view__product-text-request-btn-text-img--green {
	display: none;
	width: 15px;
	height: 15px;
}

.product-view__product-text-request-btn-text-img-arrow {
	width: 17px;
	height: 17px;
}

.product-view__product-text-request-btn-text-img-arrow--green {
	display: none;
	width: 17px;
	height: 17px;
}

.product-view__product-text-description {
	line-height: 1.55;
	font-size: 14px;
	color: var(--dark-charcoal);
	white-space: pre-wrap;
	font-family: 'Montserrat';
}

.product-view__text-tab--wrap {
	display: none;
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
}

.product-view__text-tab-wrap {
	display: flex;
	margin-top: 60px;
	border-bottom: 1px solid #d8d8d8;
}

.product-view__text-tab {
	max-width: 230px;
	width: 100%;
	padding-top: 14px;
	padding-bottom: 14px;
	font-size: 14px;
	transition: 0.3s;
	font-weight: 500;
	line-height: 20px;
	font-family: Montserrat;
	border-bottom: 2px solid transparent;
}

.product-view__text-tab:hover {
	border-bottom: 2px solid var(--main-green);
	position: relative;
	z-index: 2;
}

.product-view__text-tab-active {
	border-bottom: 2px solid var(--main-green);
	position: relative;
	color: var(--main-green);
	z-index: 2;
	font-weight: 500;
	font-family: Montserrat;
}

.product-view__text-tab-1 {
	border-top: 1px solid #d8d8d8;
	padding: 10px;
	transform: translateY(-1px);
	font-size: 14px;
	line-height: 1.55;
	font-weight: 500;
	overflow: auto;
}

.product-view__text-tab-1-title {
	font-size: 15px;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 5px;
}

.product-view__text-tab-1-value {
	font-weight: 400;
}

.product-view__text-tab-3 {
	padding: 10px;
	line-height: 1.55;
	border-top: 1px solid #d8d8d8;
	transform: translateY(-1px);
	overflow: auto;
	height: calc(100dvh - 137px);
}

.product-view__text-tab-2 {
	display: flex;
	flex-direction: column;
	gap: 40px;
	border-top: 1px solid #d8d8d8;
	padding: 10px;
	transform: translateY(-1px);
	overflow: auto;
}

.product-view__text-tab-2-title {
	font-size: 15px;
	font-weight: 500;
	line-height: 150%;
}

.product-view__text-tab-2-ul {
	line-height: 1.55;
	margin-top: 3px;
}

.product-view__text-tab-2-li {
	list-style-type: disc;
	margin-left: 20px;
	font-size: 14px;
}

.product-view__products-pagination {
	margin-bottom: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view__banner-wrap {
	margin-top: 100px;
}

.product-view__cards-title-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 15px;
	margin-bottom: 32px;
}

.product-view__cards-title {
	line-height: 33px;
	font-size: 22px;
	font-weight: 700;
}

.product-view__cards {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: calc(100% + 8px);
	margin-bottom: 30px;
	flex-wrap: wrap;
	margin-left: -4px;
	flex: 1 1;
}

.product-view__card-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	width: 45.5%;
	max-width: 50%;
	padding: 4px;
	border: 1px solid #fff;
	cursor: pointer;
	transition: 0.3s;
	margin-bottom: 2px;
}

.product-view__card-wrap:hover {
	border: 1px solid var(--main-green);
}

.product-view__cards-catalog-btn {
	text-decoration: underline;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.product-view__vendor {
	display: flex;
	flex-direction: column;
}

.product-view__vendor-info-wrap {
	display: flex;
	gap: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 48px 0;
}

.product-view__vendor-line-top {
	display: none;
	margin-top: 60px;
	height: 1px;
	background: #d8d8d8;
}

.product-view__vendor-line-bottom {
	height: 1px;
	margin-bottom: 60px;
	/* background: #d8d8d8; */
}

.product-view__vendor-info-logo-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	width: 100%;
	max-width: 440px;
}

.product-view__vendor-info-logo {
	object-fit: cover;
	width: 160px;
	height: 160px;
	border-radius: 50%;
}

.product-view__vendor-info-logo-text-wrap {
}

.product-view__vendor-info-logo-number {
	font-size: 32px;
	font-weight: 600;
	line-height: 120%;
	margin-bottom: 6px;
	width: fit-content !important;
}

.product-view__vendor-info-logo-rating-wrap {
	justify-content: flex-start;
	margin-bottom: 16px;
	width: fit-content !important;
}

.product-view__vendor-info-logo-product-view__rating {
}

.product-view__vendor-info-logo-rating .MuiRating-decimal {
	color: var(--dark-charcoal);
	font-size: 25px;
}

.product-view__vendor-info-logo-text {
	position: relative;
	display: inline-block;
	line-height: 150%;
	font-size: 14px;
}

.product-view__vendor-info-logo-text::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 3px;
	border-top: 1px solid;
	width: 100%;
}

.product-view__vendor-info-description-wrap {
	max-width: 872px;
}

.product-view__vendor-info-description-name {
	font-weight: 700;
	font-size: 22px;
	line-height: 120%;
	margin-bottom: 0px;
	margin-top: 48px;
}

.product-view__vendor-info-description {
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 1.55;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 12;
	-webkit-box-orient: vertical;
}

.product-view__vendor-info-description-link {
	margin-top: 12px;
	text-decoration: underline;
	font-size: 14px;
	transition: 0.3s;
}

.product-view__vendor-info-description-link:hover {
	color: var(--main-green);
}

.product-view__vendor-info-description-btn {
	display: block;
	margin-bottom: 12px;
}

.product-view__product-slider-2-zoom-wrap {
	height: 100%;
}

.product-view__product-slider-2-zoom-img {
	object-fit: contain;
	width: 100%;
	aspect-ratio: 1/1;
}

.product-view__product-model-wrap {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 17px;
}

.product-view__product-model-title-wrap {
	color: var(--dark-charcoal);
	font-size: 14px;
	line-height: 150%;
}

.product-view__product-model-title {
	font-weight: 600;
}

.product-view__product-model-text {
	font-weight: 400;
}

.product-view__product-model-link {
	text-decoration: underline;
	transition: 0.3s;
}

.product-view__product-model-link:hover {
	color: var(--main-green);
}

.product-view__text-mobile-tab-wrap {
	margin-top: 20px;
}

.product-view__text-mobile-tab-btn {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16.6px 0;
	border-bottom: 1px solid #d8d8d8;
}

.product-view__text-mobile-tab-btn-text {
	color: var(--dark-charcoal);
	font-size: 15px;
	font-weight: 400;
	line-height: 150%;
	font-family: Montserrat;
	font-weight: 600;
}

.swiper-pagination-bullet-active {
	background: var(--main-green);
}

.product-view__text-mobile-tab-btn-text-img-arrow {
	width: 20px;
	height: 20px;
	transform: rotate(90deg);
}

.product-view__text-modal-tab-wrap {
	position: relative;
	z-index: 2;
	height: 92vh;
	background: #fff;
	overflow: hidden;
	margin-top: 88px;
}

.product-view__text-modal-tab-title-wrap {
	display: flex;
	gap: 10px;
	font-family: Montserrat;
	font-weight: 600;
	justify-content: space-between;
	align-items: center;
	padding: 10.5px 20px 10.5px 10px;
}

.product-view__text-modal-tab-btn-close-wrap {
	padding: 8px;
}

.product-view__text-modal-tab-btn-close {
	width: 12px;
	height: 12px;
	margin-left: auto;
	cursor: pointer;
}

.product-view__product-swiper-1-btn-wrap {
	display: none;
	flex-direction: row;
}

.product-view__product-swiper-1-btn {
	width: 40px;
	height: 80px;
}
.product-view__product-swiper-1-btn-1-wrap {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #fff;
	transition: 0.3s;
}

.product-view__product-swiper-1-btn-1-wrap:hover {
	border: 1px solid #d8d8d8;
}
.product-view__product-swiper-1-btn-2-wrap {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #fff;
	transition: 0.3s;
}

.product-view__product-swiper-1-btn-2-wrap:hover {
	border: 1px solid #d8d8d8;
}

.product-view__product-swiper-1-btn-1 {
	width: 20px;
	height: 20px;
	margin-left: auto;
	margin-right: auto;
	transform: rotate(-90deg);
}
.product-view__product-swiper-1-btn-2 {
	width: 20px;
	height: 20px;
	margin-left: auto;
	margin-right: auto;
	transform: rotate(90deg);
}

.product-view__text-modal-tab-is-safari-wrap {
	height: 90dvh;
	padding-bottom: 80px;
}

.product-video-modal {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	width: 100vw;
	height: 100vh;
	min-width: -webkit-fill-available;
	min-height: -webkit-fill-available;
}

.product-video-modal-player {
	width: 100%;
	max-height: 500px;
	aspect-ratio: 1/1;
}

.product-video-modal-close-img {
	position: absolute;
	top: 30px;
	right: 30px;
	width: 22px;
	height: 22px;
	cursor: pointer;
	z-index: 100;
	transition: 0.3s;
}

.product-video-modal-close-img:hover {
	opacity: 0.6;
}

.product-view__form-input-is-safari-wrap {
	padding-bottom: 80px;
}

.product-view__text-modal-tab-title-material {
	margin-left: auto;
	margin-right: auto;
	font-size: 16px;
	font-weight: 600;
}

.product-view__text-modal-material-result-wrap {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.product-view__text-modal-material-result-img {
	width: 200px;
	height: 200px;
	margin-left: auto;
	margin-right: auto;
}

.product-view__text-modal-material-result-title-wrap {
	font-size: 14px;
}

.product-view__text-modal-material-result-title {
	font-weight: 500;
}

.product-view__modal-zoom-btn-1--wrap {
	position: fixed;
	top: 50%;
	left: 20px;
	transform: translateY(-50%) rotate(-90deg);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #fff;
	z-index: 5;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	transition: 0.3s;
}

.product-view__modal-zoom-btn-1--wrap:hover {
	border: 1px solid #d8d8d8;
}

.product-view__modal-zoom-btn-1-wrap {
}

.product-view__modal-zoom-btn-1 {
	width: 30px;
	height: auto;
}

.product-view__modal-zoom-btn-2--wrap {
	position: fixed;
	top: 50%;
	right: 20px;
	transform: translateY(-50%) rotate(90deg);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #fff;
	z-index: 5;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	transition: 0.3s;
}

.product-view__modal-zoom-btn-2--wrap:hover {
	border: 1px solid #d8d8d8;
}

.product-view__modal-zoom-btn-2-wrap {
}

.product-view__modal-zoom-btn-2 {
	width: 30px;
	height: auto;
}

.product-view__modal-zoom-swiper-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 86vh;
	max-height: 86vh;
}

.product-view__modal-zoom-swiper-wrap .swiper-button-next,
.product-view__modal-zoom-swiper-wrap .swiper-button-prev {
	display: none;
	position: absolute;
	z-index: -100;
	opacity: 0;
	width: 0;
	height: 0;
}

.product-view__modal-zoom-swiper-wrap .swiper-wrapper {
	align-items: center;
}

.product-view__variations-select-item {
	font-family: 'Montserrat' !important;
	overflow-y: auto;
	white-space: break-spaces !important;
}

.product-view__variations-select-item:hover {
	background: #d8d8d899 !important;
	color: var(--dark-charcoal) !important;
}

.product-view__variations-select-item-active {
	background: #d8d8d899 !important;
	color: var(--dark-charcoal) !important;
}

.product-view__product-swiper-2-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px !important;
	height: 30px !important;
	background-color: rgb(255, 255, 255);
	border-radius: 50%;
	filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
	transition: 0.3s;
}

.product-view__product-swiper-2-btn-prew {
	display: none;
	position: absolute;
	top: 50%;
	left: 11px;
	transform: translateY(-50%);
	z-index: 2000;
}

.product-view__product-swiper-2-btn-next {
	display: none;
	position: absolute;
	top: 50%;
	right: 11px;
	transform: translateY(-50%);
	z-index: 2000;
}

.product-view__product-swiper-2-btn-arrow-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view__like-btn--is-liked {
	width: 22.5px;
	height: 19px;
}
.product-view__text-tab-1--mob {
	height: calc(100dvh - 137px);
	padding-bottom: 60px;
}

.product-view__text-tab-2--mob {
	height: calc(100dvh - 137px);
	padding-bottom: 60px;
}

.product-view__text-tab-3--mob {
	height: calc(100dvh - 137px);
	padding-bottom: 60px;
}

.product-view__product-swiper-pagination-wrap {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 5px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view__product-swiper-wrap .slick-arrow {
	position: relative;
	display: none !important;
}

.product-view__product-swiper-mob--wrap {
	position: relative;
	/* margin-left: -10px; */
}

.product-view__product-swiper-mob-wrap {
}

.product-view__form-error--phone {
	position: relative;
	width: 100%;
	margin-bottom: 15px;
}

.product-view__form-error-text--phone {
	position: absolute;
	bottom: -5px;
	left: 0;
	color: red;
	font-size: 13px;
}

.product-view__form-error-banner {
	margin-bottom: 20px;
}

.product-view__form-input-label--not-adaptl-form {
	margin-left: 0;
}

.product-view__text-tab-1-conditions_delivery {
	font-size: 14px;
	margin: 10px 0;
}

.product-view__product-video-wrap {
	position: absolute;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1;
}

.product-view__product-video--wrap {
	position: relative;
	width: 100%;
	max-width: 650px;
	margin-right: auto;
	margin-left: auto;
}

.product-view__product-swiper-2-wrap--opacity {
	opacity: 0;
}

.product-view__product-swiper-img-none-wrap {
	position: relative;
}

.product-view__result-is-hide-variations {
	opacity: 0.7;
	pointer-events: none;
}

.product-view__modal-zoom-wrap {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	width: 100dvw;
	height: 100dvh;
	overflow: scroll;
	outline: none;
}

.product-view__modal-zoom-wrap::-webkit-scrollbar {
	height: 0px;
	width: 0px;
}

.product-view__modal-zoom-img-wrap {
	position: absolute;
	z-index: 3;
	overflow: auto;
	max-height: 100vh;
}

.product-view__modal-zoom-img-wrap2::-webkit-scrollbar {
	height: 0px;
	width: 0px;
}

.product-view__modal-zoom-img-wrap2 {
	overflow: auto;
}

.product-view__modal-zoom-img {
	object-fit: contain;
	width: 100%;
	height: 100%;
	cursor: zoom-in;
}

.product-view__img-is-zoom {
	cursor: zoom-out;
	width: 87vw;
	height: auto;
}

.product-view__modal-zoom-img-is-zoom {
	max-width: 87vw;
	width: 87vw;
	max-height: 100vh;
	height: 100vh;
}

.product-view__modal-zoom-btn-close-img {
	position: fixed;
	top: 30px;
	right: 30px;
	width: 22px;
	height: 22px;
	cursor: pointer;
	z-index: 100;
	transition: 0.3s;
}

.product-view__modal-zoom-btn-close-img:hover {
	opacity: 0.6;
}

.product-view__modal-zoom-swiper-is-zoom {
	opacity: 0;
}

@media (min-width: 240px) {
	.product-view__vendor-info-logo-wrap {
		flex-direction: row;
	}
}
@media (min-width: 400px) {
	.product-view__product-text-buy-btn-wrap {
		gap: 11;
	}
}
@media (min-width: 480px) {
	.product-view__vendor-info-logo-wrap {
		flex-direction: row;
	}
	.product-view__variations-select-color-wrap {
		gap: 16px;
	}
	.product-view__product-text-title {
		font-size: 22px;
	}
	.product-view__cards-title {
		font-size: 24px;
		line-height: 23px;
	}
}
@media (min-width: 560px) {
	.product-view__modal-form-wrap {
		align-items: center;
	}
	.product-view__modal-form {
		max-width: 460px;
		font-size: 16px;
	}
	.product-view__modal-form-title-wrap {
		position: static;
		height: auto;
		border-bottom: none;
		padding: 20px 20px 0;
		--webkit-padding: 20px 20px 0;
		font-size: 18px;
	}
	.product-view__modal-form-text-wrap {
		padding: 15px 20px 20px;
		height: auto;
	}
	.product-view__modal-form-btn-close--desktop {
		display: block;
	}
	.product-view__form-input-wrap {
		margin-top: 25px;
		padding-bottom: 0;
	}
	.product-view__modal-form-text--wrap {
		padding: 50px;
		height: 100vh;
		overflow: auto;
	}
	.product-view__modal-form-text {
		font-size: 15px;
	}
	.product-view__form-input-label {
		font-size: 15px;
	}
	.product-view__form-input-is-safari-wrap {
		padding-bottom: 0;
	}
	.product-view__text-modal-tab-btn-close-wrap {
		display: none;
	}
}
@media (min-width: 600px) {
	.product-view__product-swiper-1-wrap {
		display: block;
	}
	.product-view__product-swiper-1-btn-wrap {
		display: flex;
	}
	.product-view__product-swiper-2-wrap .swiper-pagination {
		display: none;
	}
	.product-view__product-swiper-2-wrap .swiper-button-prev {
		display: flex;
	}
	.product-view__product-swiper-2-wrap .swiper-button-next {
		display: flex;
	}
	.product-view__product-swiper-2-wrap {
		padding-bottom: 0;
	}
	.product-view__product-swiper-model {
		width: 78.4px;
		height: 78.4px;
	}
	.product-view__product-swiper-wrap {
		gap: 25px;
	}
	.product-view__product-swiper-1--wrap {
		display: flex;
	}
	.product-view__product-swiper-2-btn-prew,
	.product-view__product-swiper-2-btn-next {
		display: block;
	}
	.product-view__product-swiper-pagination-wrap {
		display: none;
	}
}
@media (min-width: 630px) {
	.product-view__modal-form-btn-close--mobile {
		display: none;
	}
}
@media (min-width: 640px) {
	.product-view__cards-title {
		line-height: 23px;
	}
	.product-view__modal-form-title-wrap {
		padding: 40px 45px 0;
	}
	.product-view__modal-form-text-wrap {
		padding: 15px 45px 40px;
	}
	.product-view__product-swiper-2-wrap {
		width: 100%;
		max-width: 650px;
		margin-left: auto;
		margin-right: auto;
	}
	.product-view-wrap {
		padding: 0 20px;
	}
	.product-view__text-tab {
		font-size: 15px;
	}
	.product-view__vendor-info-description-name {
		font-size: 24px;
	}
	.product-view__modal-form-title {
		font-size: 18px;
	}
	.product-view__modal-form-text {
		font-size: 16px;
	}
	.product-view__form-input-label {
		font-size: 16px;
	}
	.product-view__form-textarea::placeholder {
		font-size: 15px;
	}
	.product-view__text-tab-1--mob {
		padding-bottom: 0;
	}
	.product-view__text-tab-2--mob {
		padding-bottom: 0;
	}
	.product-view__text-tab-3--mob {
		padding-bottom: 0;
	}
}
@media (min-width: 760px) {
	.product-view__vendor-info-wrap {
		align-items: center;
		flex-direction: row;
	}
	.product-view__text-tab--wrap {
		display: block;
		max-width: 100%;
	}
	.product-view__text-mobile-tab-wrap {
		display: none;
	}
	.product-view__product-text-buy {
		padding: 10px 2px;
		font-size: 14px;
		min-width: 150px;
	}
	.product-view__vendor-line-top {
		display: block;
	}
	.product-view__card-wrap {
		width: 25%;
	}
	.product-view__text-modal-tab-btn-close {
		display: none;
	}
	.product-view__text-tab-3,
	.product-view__text-tab-2 {
		height: auto;
	}
}
@media (min-width: 960px) {
	.product-view__product {
		flex-direction: row;
		align-items: flex-start;
		gap: 30px;
		max-width: 100%;
	}
	.product-view__product-swiper-wrap {
		flex-direction: row-reverse;
		width: 58%;
	}
	.product-view__product-text-wrap {
		width: 41%;
		transform: translateY(-4px);
	}
	.product-view__vendor-info-wrap {
		padding: 36px 20px 48px;
	}
	.product-view__vendor-info-description-wrap {
		width: 65%;
	}
	.product-view__product-swiper-1--wrap {
		flex-direction: column;
		justify-content: flex-start;
		width: 75px;
	}
	.product-view__product-swiper-1-wrap {
		width: 75px;
		margin-right: 25px;
		padding-left: 0;
	}
	.product-view__product-swiper-1-wrap .swiper-wrapper {
		flex-direction: column;
	}
	.product-view__product-swiper-1-wrap .swiper-button-prev {
		position: absolute !important;
		z-index: -10 !important;
		width: calc(100% + 2px) !important;
		height: 40px !important;
		background-color: rgb(255, 255, 255);
		position: absolute;
		top: calc(100% + 2px);
		left: 50%;
		transform: translate(-50%, 24px);
		padding: 1px;
	}

	.product-view__product-swiper-1-wrap .swiper-button-prev::after {
		display: flex;
		align-items: center;
		justify-content: center;
		transform: rotate(-90deg);
	}
	.product-view__product-swiper-1-wrap .swiper-button-next {
		position: absolute !important;
		z-index: -10 !important;
		transform: translate(-50%, -30px);
		opacity: 0;
		width: calc(100% + 8px) !important;
		height: 40px !important;
		background-color: rgb(255, 255, 255);
		position: absolute;
		left: 53%;
		top: 0;
		padding: 1px;
	}
	.product-view__product-swiper-1-wrap .swiper-button-next::after {
		display: flex;
		align-items: center;
		justify-content: center;
		transform: rotate(-90deg);
	}
	.product-view__product-swiper-1-video-wrap {
		transform: translateX(3px);
	}
	.product-view__product-swiper-model-wrap {
		margin-top: 10px;
		width: 75px;
	}
	.product-view__vendor-line-top {
		margin-left: -55px;
	}
	.product-view__vendor-line-bottom {
		margin-left: -55px;
	}
	.product-view__drawer-result--wrap {
		max-width: 460px;
		width: 100%;
	}
	.product-view__text-tab-3,
	.product-view__text-tab-2,
	.product-view__text-tab-1 {
		width: 70%;
	}
	.product-view__product-swiper-1-btn-wrap {
		flex-direction: column;
	}
	.product-view__product-swiper-1-btn {
		height: 40px;
		width: 100%;
	}
	.product-view__product-swiper-1-btn-2 {
		transform: rotate(180deg);
	}
	.product-view__product-swiper-1-btn-1 {
		transform: rotate(0deg);
	}
	.product-view__card-wrap {
		width: 23.5%;
		max-width: 25%;
	}
	.product-view-wrap {
		padding: 0 15px;
	}
	.product-view__product-swiper-1-wrap .swiper-slide {
		width: 75px !important;
		height: 75px !important;
	}
	.product-view__product-swiper-wrap {
		gap: 15px;
		align-items: flex-start;
		justify-content: space-between;
	}
	.product-view__product-swiper-model {
		width: 75px;
		height: 75px;
	}
	.product-view__product-swiper-1-btn {
		display: flex;
		align-items: flex-start;
	}
	.product-view__product-swiper-1-btn--end {
		display: flex;
		align-items: flex-end;
	}
	.product-view__product-swiper-2-btn-prew {
		transform: translateY(-50%) rotate(-90deg);
	}

	.product-view__product-swiper-2-btn-next {
		transform: translateY(-50%) rotate(-90deg);
	}
	.product-view__product-swiper-mob--wrap {
		width: calc(100% - 90px);
	}
	.product-view__product-slider-2-zoom-img {
		cursor: zoom-in;
	}
	.product-view__product-swiper-img-none-wrap {
		max-width: calc(100% - 90px);
	}
}
@media (min-width: 1200px) {
	.product-view__product-text-buy {
		padding: 14px 25px;
	}
	.product-view__product-text-title {
		font-size: 25px;
	}
	.product-view__product-text-price {
		font-size: 25px;
	}
	.product-view__product-text-description {
		font-size: 14px;
	}
	.product-view__product {
		gap: 40px;
	}
	.product-view-wrap {
		padding: 0 30px;
	}
	.product-view__product-text-request-btn {
		padding: 17px 0;
	}
	.product-view__vendor-line-top {
		margin-left: -30px;
	}
	.product-view__vendor-line-bottom {
		margin-left: -30px;
	}
	.product-view__vendor-info-description {
		width: 90%;
	}
	.product-view__cards-title {
		font-size: 25px;
		line-height: 27px;
	}
	.product-view__product-swiper-2-wrap {
		max-width: 785px;
	}
	.product-view__modal-form {
		max-width: 560px;
	}
	.product-view__variations-select-title-text--mobile {
		display: none;
	}
	.product-view__variations-select-title-text--desc {
		display: block;
	}
	.product-view__drawer-result--wrap {
		max-width: 570px;
	}
	.product-view__product-swiper-wrap {
		width: 59%;
	}
	.product-view__product-text-wrap {
		width: 40%;
	}
	.product-view__vendor-info-description-name {
		font-size: 25px;
	}
	.product-view__product-video--wrap {
		max-width: 785px;
	}
	.product-view__product-slider-video-2-zoom-wrap {
		max-width: 785px;
	}
	.product-view__product-swiper-img-none-wrap {
		max-width: 785px;
	}
}
@media (min-width: 1300px) {
	.product-view__img-is-zoom {
		width: 90vw;
	}
	.product-view__modal-zoom-img-is-zoom {
		max-width: 90vw;
		width: 90vw;
	}
	.product-view__modal-zoom-swiper-wrap {
		max-width: 92vh;
		max-height: 92vh;
	}
}
@media (min-width: 1400px) {
	.product-view__product-text-description,
	.product-view__text-tab-2-li,
	.product-view__text-tab-1 {
		font-size: 15px;
	}
	.product-view__vendor-info-description {
		font-size: 15px;
	}
	.product-view__vendor-info-description-link {
		font-size: 15px;
	}
	.product-view__text-tab-1-conditions_delivery {
		font-size: 15px;
	}
}
@media (min-width: 1680px) {
	.product-view__product-slider-2-mark-wrap {
		font-weight: 500;
		padding: 2px 12px;
		font-size: 14px;
	}
	.product-view__product-text-title {
		font-size: 28px;
	}
	.product-view__product-text-price {
		font-size: 28px;
	}
	.product-view__product-text-code {
		font-size: 14px;
	}
	.product-view__rating .MuiRating-decimal {
		font-size: 16px;
	}
	.product-view__reviews {
		font-size: 14px;
	}
	.product-view__product-text-time {
		font-size: 14px;
	}
	.product-view__product-text-price {
		margin-top: 20px;
	}
	.product-view__product-text-time {
		margin-bottom: 25px;
	}
	.product-view__variations-select-title {
		font-size: 14px;
	}
	.product-view__product-text-buy {
		font-size: 14px;
	}
	.product-view__product-text-buy-btn-wrap {
		margin-bottom: 20px;
	}
	.product-view__product-text-request-btn-text-img {
		width: 18px;
		height: 18px;
	}
	.product-view__product-text-request-btn-text-img--green {
		display: none;
		width: 18px;
		height: 18px;
	}
	.product-view__product-text-request-btn-text-img-arrow {
		width: 20px;
		height: 20px;
	}
	.product-view__product-text-request-btn-text-img-arrow--green {
		display: none;
		width: 20px;
		height: 20px;
	}
	.product-view__product-text-request-btn-text {
		font-size: 14px;
	}
	.product-view__product-model-title-wrap {
		font-size: 14px;
	}
	.product-view__text-tab-wrap {
		margin-top: 44px;
	}
	.product-view__text-tab-1-title {
		margin-bottom: 8px;
	}
	.product-view__vendor-info-description {
		font-size: 15px;
	}
	.product-view__vendor-info-description-link {
		font-size: 16px;
	}
	.product-view__vendor-info-wrap {
		padding: 48px 20px;
	}
	.product-view__vendor-info-description-link {
		font-size: 15px;
	}
	.product-view__cards-catalog-btn {
		font-size: 16px;
	}
	.product-view-wrap {
		max-width: 1700px;
	}
	.product-view__card-wrap {
		padding: 6px;
	}
	.product-view__cards {
		width: calc(100% + 12px);
		margin-left: -6px;
	}
}

.creating-product {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #19191D;
}

.creating-product__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    padding: 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-bottom: 2px solid #D8D8D8;
}

.creating-product__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.creating-product__header-title-img {
    width: 24px;
    height: 24px;
}

.creating-product__header-title {
    font-weight: 500;
    font-size: 16px;
}

.creating-product__header-btn-wrap {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: min-content;
    margin-left: auto;
}

.creating-product__header-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #2A7F77;
    padding: 11px 27px 11px 18px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.creating-product__header-btn-img {
    width: 24px;
    height: 24px;
}

.creating-product__header-btn-img-save {
    width: 18px;
    height: 18px;
}

.creating-product__header-btn-img {
    width: 18px;
    height: 18px;
}

.creating-product--wrap {
    padding: 20px 0;
}

.creating-product__main-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.creating-product__input-wrap {
    display: flex;
    gap: 23px;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 50px;
}

.creating-product__input--wrap {
    min-width: 230px;
}

.creating-product__input-lable {
    font-size: 14px;
    margin-bottom: 8px;
    width: fit-content;
    max-width: 500px;
    font-weight: 500;
    color: var(--dark-charcoal);
}

.creating-product__input {
    max-width: 500px;
    width: 100%;
}

.creating-product__input .MuiFormLabel-root {
    color: #888;
    font-size: 14px;
}

.creating-product__input .Mui-focused {
    color: #2A7F77 !important;
}

.creating-product__input .MuiInputBase-root {
    font-size: 14px;
}

.creating-product__input .MuiInputBase-input {
    padding: 13.5px 14px;
}

.creating-product__input .Mui-focused .MuiOutlinedInput-notchedOutline  {
    border-color: #2A7F77 !important;
}

.creating-product__input-video-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
}

.creating-product__input-sub-title {
    font-size: 11px;
    color: #888;
    margin-top: 8px;
}

.creating-product__seo-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.creating-product__select-wrap {
    min-width: 230px;
}

.creating-product__mark--wrap {
    display: flex;
    gap: 23px;
    justify-content: space-between;
    max-width: 500px;
    flex-wrap: wrap;
}

.creating-product__select {
    width: 100%;
    border-radius: 0 !important;
}

.creating-product__characteristics--wrap {
    margin-top: 15px;
}

.creating-product__characteristics-wrap {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
}

.creating-product__characteristics-wrap.Mui-expanded {
    margin: 0 !important;
}

.creating-product__characteristics-wrap.MuiPaper-root::before {
    display: none;
}

.creating-product__characteristics-wrap.MuiPaper-root {
    box-shadow: none;
}

.creating-product__input-characteristics-lable-wrap {

}

.creating-product__input-characteristics-lable {
    font-size: 15px;
    color: var(--dark-charcoal);
    font-weight: 500;
}

.creating-product__characteristics {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 23px;
}

.creating-product__characteristics-result {
    width: 100%;
}

.creating-product__add-btn--wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 500px;
}

.creating-product__add-btn {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    cursor: pointer;
    z-index: 2;
}

.creating-product__add-result-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.creating-product__add-result {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 2px 7px 2px 11px;
    background: #EDEDF0;
    border-radius: 45px;
}

.creating-product__add-result-del-btn {
    width: 8px;
    height: 8px;
}

.creating-product__upload-result--wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
}

.creating-product__result-img--wrap {
    width: 100%;
}

.creating-product__result-img--container-img-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.creating-product__result-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    height: 40px;
    background: #2A7F77;
    padding: 8px 20px 8px 15px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
}

.creating-product__result-img--container-class {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
}

.creating-product__container-img-result {
    width: 90px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.creating-product__result-img--item-class {
    position: relative;
    width: fit-content;
}

.creating-product__upload-result-del-btn {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
}

.creating-product__upload-img-result {
   width: 125px;
   aspect-ratio: 1/1;
   object-fit: contain;
}

.creating-product__material-wrap {

}

.creating-product__material-label-wrap {
    display: flex;
    align-items: flex-end;
    gap: 6.5px;
    width: 100%;
}

.creating-product__material-result-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    width: 100%;
    max-width: 166.5px;
}

.creating-product__material-result {
    width: 100%;
    height: 100%;
    border: 1px solid #93939a;
    padding: 3px;
    aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 166.5px;
    min-height: 166.5px;
}

.creating-product__material-input-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.creating-product__create-error {
    border: 1px solid red !important;
}

.creating-product__select-mark--wrap {
    min-width: 200px;
    border-radius: 0 !important;
    width: 100%;
    height: 40.4px;
}

.creating-product__select-mark--wrap .MuiOutlinedInput-notchedOutline {
    border-color: #89898999 !important;
}

.creating-product__select-mark--wrap .MuiSelect-select {
    padding: 6.5px 14px;
    font-size: 13px;
}

.creating-product__select .MuiNativeSelect-select {
    padding: 10.5px 14px;
}

.creating-product__select-mark-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.creating-product__color-result-wrap {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.creating-product__create-img--wrap {
    flex: 1 1;
    min-width: 250px;
}

.creating-product__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 166.5px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 15px;
}

.creating-product__create-product-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 15px;
}

.creating-product__create-img-text-wrap {
    position: relative;
    font-size: 14px;
}

.creating-product__create-img-text-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    background: #F2F4F7;
}

.creating-product__create-img-text-img {
    width: 20px;
    height: 20px;
}

.creating-product__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.creating-product__create-img-link-upload-lable {
    color: #2A7F77;
    cursor: pointer;
    font-weight: 600;
}
 
.creating-product__upload-result-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 125px; 
    aspect-ratio: 1/1;
 }
 
 .creating-product__upload-result-del-img-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: #fff;
 }

 .creating-product__upload-result-del-img-btn--droppable {
    position: absolute;
    top: -2px;
    right: 0;
 }

 .creating-product__upload-result-del-img-btn-img {
     width: 20px;
     height: 20px;
 }
 
 .creating-product__swiper {
    margin-top: 15px;
 }

 .creating-product__slider {
     
}

.creating-product__slider.swiper-slide {
}

.creating-product__variations-wrap {
    width: 100%;
}

.creating-product__input-error {
    border-color: red !important;
}

.creating-product__mark-wrap {
    gap: 15px;
    width: 100%;
}

.creating-product__input-top {
    display: flex;
    flex-direction: column;
    gap: 23px;
    max-width: 500px;
    width: 100%;
}

.creating-product__input-bottom {
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    max-width: 500px;
}

.creating-product__produc-accordion.MuiButtonBase-root {
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 0;
}

.creating-product__produc-accordion .MuiButtonBase-root {
    padding: 0;
    height: 64px !important;
}

.creating-product__produc-accordion .MuiTypography-root {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-charcoal);
}

.creating-product__produc-accordion .MuiAccordionSummary-content {
    margin: 25px 0;
}

.creating-product__produc-accordion.MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: none;
    margin: 0 !important;
    width: 100%;
}

.creating-product__produc-accordion.MuiPaper-root::before {
    display: none;
}

.creating-product__produc-accordion-wrap {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    border-top: 1px solid rgb(216, 216, 216);
}

.creating-product__produc-accordion-arrow {
    transform: rotate(90deg);
}

.creating-product__produc-accordion .MuiAccordionDetails-root {
    padding: 0 0 38px 0;
}

.creating-product__produc-accordion-heder-wrap {
    width: 100%;
}

.creating-product__produc-accordion-heder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.creating-product__produc-accordion-heder-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #2A7F77;
}

.creating-product__produc-accordion-heder-btn-img {
    width: 20px;
    height: 20px;
}

.creating-product__characteristics-result-color-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
}

.creating-product__characteristics-result-color-title {
    width: 100%;
    font-weight: 500;
    color: var(--dark-charcoal);
}

.creating-product__color-result--wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.creating-product__text-tab--wrap {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
}

.creating-product__text-tab {
    max-width: 230px;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.creating-product__header-btn--update {
    min-width: auto;
}

.creating-product__select-category--wrap {
    width: 100%;
    min-width: 300px;
    color: var(--dark-charcoal) !important;
}

.creating-product__select-color--wrap .MuiSelect-select {
    font-size: 15px !important;
}

.creating-product__select-color-criete--wrap {
    min-width: 300px;
    color: var(--dark-charcoal) !important;
}

.creating-product__select-color-criete--wrap .MuiSelect-select {
    font-size: 15px !important;
}

.creating-product__input-characteristic {
    max-width: none !important;
    font-size: 13px !important;
    min-width: 300px;
    height: 40.4px;
}

.creating-product__input-characteristic::placeholder {
    font-size: 13px !important;
}

.creating-product__input-characteristics-title {
    font-size: 12px;
    color: #888888;
    margin-top: 8px;
    font-weight: 400;
}

.creating-product__produc-accordion-title.MuiTypography-root {
    font-weight: 600;
}

.creating-product__select-mark--type-wrap .MuiSelect-select {
    font-size: 14px !important;
    color: var(--dark-charcoal);
}

.creating-product__mark-color-picker-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 294px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.creating-product__mark-color-picker {
    width: 100%;
}

.creating-product__mark-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 11px 27px 11px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
}

.creating-product__input-lable-material {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 23px;
    font-size: 15px;
}

.creating-product__material-img--wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
}

.creating-category__input-material {
    flex: 1 1;
    max-width: none !important;
    min-width: 300px;
}

.select-characteristic__input-characteristics-lable-textarea {
    align-self: flex-start;
}

.creating-product__create-img-material-wrap {
    margin-bottom: 0;
}

.creating-product__create-material-popover-img-wrap {
    width: max-content;
    background: #fff;
    transform: translate(-80px, -20px) !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.creating-product__create-material-popover-img {
    max-width: 80vw;
    width: 100%;
    height: auto;
}

.creating-product__select-color-wrap {
    margin-top: 10px;
}

.creating-product__input-lable-color {
    font-size: 15px;
}

.creating-product__characteristics-color-wrap {
    margin-top: 23px;
}

.creating-product__characteristics-color-criete-wrap {
    margin-top: 30px;
    align-items: flex-end;
    justify-content: space-between;
}

.creating-product__select-color-btn {
    min-width: auto !important;
    padding: 11px 20px !important;
    height: 40px !important;
    margin-left: 0 !important;
}

.creating-product__color-picker-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.creating-product__color-result-btn {
    margin-left: 5px;
}

.creating-product__produc-accordion-heder-title {
    font-weight: 600;
}

.creating-product__produc-accordion-heder-btn-text {
    display: none;
}

.select-characteristic__input-characteristics-lable-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1 1;
}

.creating-product__input-color-wrap {
    position: relative;
}

.creating-product__input-color {
    padding: 11px 23px 11px 11px !important;
}

.creating-product__input-color-btn-close {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    width: 15px;
    height: auto;
    cursor: pointer;
}

.creating-product__characteristic-add-result {
    border-radius: 0 !important;
    height: 40.4px;
}

.creating-product__textarea {
    resize: vertical;
    --webkit-resize: vertical;
}

.creating-product__text-tab--criete {
    padding-bottom: 37px !important;
}

.creating-product__header-btn-img--save {
    width: 15px;
    height: 15px;
}

.creating-product__header-btn-img-all--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #95bfbb;
}

.creating-product__header-btn-img-all {
    width: 15px;
    height: 15px;
}

.creating-product__select-tabs {

}

.creating-product__select-tabs .MuiSelect-select {
    font-size: 14px !important;
}

.creating-product__input-mark--wrap { 
    width: 100%;
}

.creating-product__characteristics-delete-btn--desk {
    display: none;
}

.creating-product__characteristics-delete-btn--mob {

}
.creating-category__input-characteristic {
    max-width: none !important;
}

.react-grid-layout {
    max-height: 600px !important;
}

.creating-product__select--wrap {
}

.creating-product__select--error {
    
}

.creating-product__select--error .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}

.creating-product__select-mark-item {
    transition: .3s;
}

.creating-product__select-mark-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-product__select-mark-item-active {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-product__main-wrap--hidden {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -200;
}

.creating-product__material-result-img-error {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 2px;
    text-align: center;
    width: 85%;
    color: red;
}

@media (min-width: 500px) {
    .creating-product__mark-wrap {
        width: 48%;
    }
    .creating-product__input-mark--wrap {
        width: 48%;
    }
    .creating-product__mark--wrap {
        gap: 5px;
    }
    .creating-product__mark-color-picker-wrap {
        transform: translateX(-50%);
        width: 294px;
    }
}
@media (min-width: 557px) {
    
    .creating-product__header-btn {
        gap: 13px;
        padding: 11px 27px 11px 20px;
    }
}
@media (min-width: 600px) {
    .creating-product__select-color--wrap {
        max-width: calc(100% - 260px);
    }
    .creating-product__input-characteristic {
        max-width: calc(100% - 260px) !important;
    }
    .creating-product__select-category--wrap {
        width: calc(100% - 260px) !important;
    }
    .creating-category__input-material {
        max-width: calc(100% - 260px) !important;
    }
    .creating-product__select-color-criete--wrap {
        max-width: calc(100% - 260px);
    }
    .creating-category__input-characteristic {
        max-width: calc(100% - 260px) !important;
    }
    .creating-product__produc-accordion-heder-btn-text {
        display: block;
    }
}
@media (min-width: 690px) {
    .creating-product__text-tab--wrap {
    }
}
@media (min-width: 768px) {
    .creating-product__header-btn-wrap {
        flex-direction: column;
        margin-left: 0;
    }
    .creating-product__header-btn {
        font-size: 14px;
    }
    .creating-product__header {
        width: calc(100% + 15px);
        margin-left: 0;
        padding: 4px 15px 4px 15px;
        flex-wrap: nowrap;
        min-height: 83.6px;
    }
    .creating-product--wrap {
        padding: 20px 0 20px 15px;
    }
    .creating-product__text-tab--wrap {
        overflow: auto;
        transform: translateY(16px);
        justify-content: center;
    }
    .creating-product__input-sub-title {
        font-size: 13px;
    }
    .creating-product__select-tabs {
        display: none !important;
    }
    .creating-product__text-tab-wrap--criete {
        transform: translateY(20px) !important;
        justify-content: center;
    }
    .creating-product__characteristics-result-color-title {
        max-width: 100%;
    }
    .creating-product__create-material-popover-img-wrap {
        transform: translate(-20px, -20px) !important;
    }
    .creating-product__create-material-popover-img {
        max-width: 600px;
    }
    .creating-product__create-img-wrap {
        width: 100%;
    }
    .creating-product__characteristics-color-wrap {
        margin-top: 30px;
    }
    .creating-product__select-color--wrap {
        max-width: calc(100% - 270px);
        min-width: 300px;
    }
    .creating-product__input-characteristic {
        max-width: calc(100% - 270px) !important;
    }
    .creating-product__select-category--wrap {
        width: calc(100% - 270px) !important;
    }
    .creating-category__input-material {
        max-width: calc(100% - 270px) !important;
    }
    .creating-category__input-characteristic {
        max-width: calc(100% - 270px) !important;
        min-width: 300px;
    }
    .creating-product__select-color-criete--wrap {
        max-width: calc(100% - 270px);
    }
}

@media (min-width: 870px) {
    .creating-product__select-category--wrap {
        width: calc(100% - 310px) !important;
    }
    .creating-product__input-characteristic {
        max-width: calc(100% - 310px) !important;
    }
    .creating-product__select-color--wrap {
        max-width: calc(100% - 310px);
    }
    .creating-category__input-material {
        max-width: calc(100% - 310px) !important;
    }
    .creating-category__input-characteristic {
        max-width: calc(100% - 310px) !important;
    }
    .creating-product__select-color-criete--wrap {
        max-width: calc(100% - 277px);
    }
    .creating-product__characteristics-delete-btn--desk {
        display: block;
        margin-bottom: 8px;
    }
    .creating-product__characteristics-delete-btn--mob {
        display: none;
    }
}
@media (min-width: 1020px) {
    .creating-category__input-material {
        max-width: 100% !important;
    }
    .creating-product__material-img--wrap {
        flex-direction: row-reverse;
        flex-wrap: nowrap;
    }
    .creating-product__material-label-wrap {
        width: fit-content;
        align-items: center;
        max-width: 290px;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 25px;
    }
    .creating-product__material-result {
        max-width: 174.5px;
        min-height: 174.5px;
    }
}
@media (min-width: 1170px) {
    .creating-product__characteristics-result-color-title {
        max-width: 280px;
    }
    .creating-product__select-category--wrap {
        width: calc(100% - 598px) !important;
    }
    .creating-product__input-characteristic {
        width: calc(100% - 598px) !important;
    }
    .creating-product__select-color--wrap {
        width: calc(100% - 598px);
    }
    .creating-product__select-color-criete--wrap {
        max-width: calc(100% - 565px);
    }
    .creating-category__input-characteristic {
        max-width: calc(100% - 598px) !important;
    }
    .creating-product__material-label-wrap {
        padding-top: 0;
    }
    .select-characteristic__input-characteristics-lable-textarea {
        margin-top: 11px;
    }
    .creating-product__material-result-wrap {
        width: 100px;
    }
    .creating-product__material-result {
        max-width: 100px;
        max-height: 100px;
        min-height: 100px;
    }
}
@media (min-width: 1200px) {
    .creating-product__header-btn-wrap {
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .creating-product__header {
        height: 83.6px;
        padding: 4px 30px 4px 30px;
    }
    .creating-product__text-tab--wrap {
        transform: translateY(16px);
    }
    .creating-product__text-tab {
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .creating-product__text-tab-wrap--criete {
        transform: translateY(16px) !important;
    }
    .creating-product__text-tab--criete {
        padding-bottom: 30px !important;
    }
}
.shopping-comment-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.shopping-comment__label {
    font-size: 15px;
    font-weight: 600;
}

.shopping-comment__textarea {
    outline: none;
    padding: 7px;
    border-color: #D8D8D8;
    border-radius: 0;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #19191D;;
}
.layout-user {
    position: relative;
}

.layout-user--wrpa {
}

.layout-user__sidenav {
    width: 100%;
    position: static;
    top: 0;
    left: 0;
    height: 100%;
    padding: 20px 15px 42px 0;
    flex: 0 0 215px;
    top: 88px;
    background: #fff;
    overflow: hidden;
    transition: .15s;
}

.layout-user__sidenav--open {
    flex: 0 0 215px;
    position: fixed;
    top: 57.8px;
    left: 0;
    padding: 20px 15px 30px;
    z-index: 12;
    overflow: auto;
    min-height: calc(100vh - 97px);
    padding-bottom: 55px;
    transition: 0.15s;
}

.layout-user__sidenav--open::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(217, 214, 214);
}

.layout-user__sidenav--close {
    display: none;
    position: static;
    flex: 0 0 61px;
    transition: .15s;
}

.layout-user__sidenav-link {
    position: relative;
    padding: 10px 0px 10px 11px;
    text-decoration: none;
    font-size: 16px;
    color: #19191D;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    height: 44px;
    margin-top: 5px;
    margin-bottom: 8px;
    transition: 0.15s;
}

.layout-user__sidenav-link:hover {
    background: #d8d8d899;
    color: var(--main-green);
}

.layout-user__sidenav-link.active {
    background: #EDEDF0;
    color: var(--main-green);
}

.layout-user__sidenav .active {
    background: #d8d8d899;
}

.layout-user__sidenav-link-text {
    position: absolute;
    left: 55px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
}

.layout-user__sidenav-link-text--close {
    z-index: -100;
    opacity: 0;
    pointer-events:none;
}

.layout-user__sidenav-link-text--open {
    opacity: 1;
    z-index: 10;
    pointer-events: all;
}

.layout-user__sidenav-link-icon {
    width: 24px;
    height: 24px;
}

.layout-user__sidenav-link-icon-hover {
    width: 24px;
    height: 24px;
    display: none;
}

.layout-user__sidenav-link-icon-hover--vendor {
    width: 18px;
    height: 18px;
    margin-left: 4px;
}

.layout-user__sidenav-link-icon-hover--notifications {
    margin-bottom: 4.2px;
}

.layout-user__sidenav-link.active .layout-user__sidenav-link-icon,
.layout-user__sidenav-link:hover .layout-user__sidenav-link-icon {
    display: none;
}

.layout-user__sidenav-link.active .layout-user__sidenav-link-icon-hover,
.layout-user__sidenav-link:hover .layout-user__sidenav-link-icon-hover {
    display: block;
}

.layout-user__sidenav-link-shop-img {
    width: 18px;
    height: 19.461px;
}

.layout-user__sidenav-link-shop {
    position: relative;
    color: var(--main-green);
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    max-width: 268px;
    padding: 8px 13px 8px 13px;
    margin-bottom: 20px;
    transition: .3s;
}

.layout-user__sidenav-link-shop-house {
    width: 12px;
    height: 12px;
}

.layout-user__sidenav-link-shop-text-wrap {
    transition: .3s;
}

.layout-user__sidenav-link-shop-text-wrap {
    position: absolute;
    left: 45px;
    top: 55%;
    transform: translateY(-50%);
    font-size: 14px;
    transition: .3s;
}

.layout-user__sidenav-link-shop-text-wrap--close {
    z-index: -100;
    pointer-events: none;
    opacity: 0;
}

.layout-user__sidenav-link-shop-text-wrap--open {
    pointer-events: all;
    opacity: 1;
}

.layout-user__sidenav-link-shop-title {
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
}

.layout-user__sidenav-link-shop-arow {
    position: absolute;
    top: 31%;
    left: 171px;
    width: 20px;
    height: 18px;
    transition: .3s;
}

.layout-user__sidenav-link-shop-arow--close {
    pointer-events: none;
    z-index: -100;
    opacity: 0;
}

.layout-user__sidenav-link-shop-arow--open {
    pointer-events: all;
    opacity: 1;
}

.layout-user__main {
    display: flex;
    padding: 0 15px;
    overflow: hidden;
    transition: 0.3s;
}

.layout-user__header-wrap {
    border-bottom: 1px solid #D8D8D8;
}

.layout-user__header--is-open-menu {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 15;
    border-bottom: 1px solid #D8D8D8;
}
    
.layout-user__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 15px 2px 0;
}

.layout-user__header-logo-wrap {
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.layout-user__header-logo {
    width: 140px;
    height: auto;
    object-fit: cover;
    padding: 2px;
}

.layout-user__header-search-wrap {
    position: relative;
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
}

.layout-user__header-search--mob-wrap {
    margin-bottom: 20px;
}

.layout-user__header-search--desc-wrap {
    display: none;
    max-width: 600px;
}

.layout-user__header-search-btn-close {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.layout-user__header-search--wrap {
    position: relative;
    width: 100%;
}

.layout-user__header-search {
    width: 100%;
    border: 1px solid #D8D8D8;
    height: 35px;
    padding-left: 45px;
    border-radius: 0;
    --webkit-border-radius: 0;
}

.layout-user__header-search .MuiFormLabel-root.MuiFormLabel-root {
    padding-left: 50px;
    font-size: 14px;
}

.layout-user__header-search .MuiInputBase-input {
    padding-left: 50px;
    font-size: 14px;
    padding: 11.5px 14px 11.5px 50px;
}

.layout-user__header-search .css-14lo706 {
    margin-left: 36px;
}

.layout-user__header-search-img {
    position: absolute;
    top: 9px;
    left: 18px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.layout-user__header-menu-wrap {
    display: flex;
    align-items: center;
}

.layout-user__header-menu-link {
    align-items: center;
    gap: 10px;
}

.layout-user__header-menu-link-icon {
    width: 23px;
    height: 23px;
}

.layout-user__header-menu-link-text {
    display: none;
    font-size: 14px;
    color: #16192C;
}

.layout-user__header-menu-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
}

.layout-user__header-menu-select {
    width: 24.141px;
    height: 27.159px;
    margin-left: 5px;
    cursor: pointer;
}

.layout-user__header-menu {
    background: #fff;
    width: 50px;
    padding: 15px 0px 18px 15px;
    transition: .3s;
}

.layout-user__header-menu--close {
    width: 50px !important;
    transition: .3s;
}

.layout-user__header-side-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 25px;
    height: 20px;
    cursor: pointer;
    transform: rotate(180deg);
}

.layout-user__header-menu-span {
    width: 100%;
    height: 2px;
    background-color: #000000;
}

.layout-user__header-menu-span2 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.layout-user__header-menu-span--open1 {
    width: 68%;
}

.layout-user__header-menu-span--open3 {
   width: 48%; 
}

.layout-user__header-avatar-img {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
}

.layout-user__outlet-wrap {
    min-height: calc(100vh - 60px);
    max-width: 100vw;
    flex: 1 1;
    font-size: 12px;
    padding-bottom: 20px;
}

.layout-user__outlet-wrap--open {
    max-width: 100%;
}

.layout-user__outlet-wrap--close {
    max-height: 69.8px;

}

.layout-user__sidenav-link-icon--collection {
    width: 17px;
    height: 21px;
    margin-left: 4px;
}

.layout-user__header-menu-avatar--desc {
    display: none;
}

.layout-user__header-menu-avatar--mob {
}

.layout-user__header-menu-avatar--mob-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
}

.layout-user__header-menu-avatar--mob-img-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: .3s;
}

.layout-user__header-menu-avatar--mob-img-wrap:hover {
    color: var(--main-green);
}

.layout-user__header-menu-avatar--mob-img-wrap:hover .layout-user__header-menu-avatar--mob-img-hover {
    display: block;
}

.layout-user__header-menu-avatar--mob-img-wrap:hover .layout-user__header-menu-avatar--mob-img {
    display: none;
}

.layout-user__header-menu-avatar--mob-img-hover {
    display: none;
    width: 23px;
    margin-top: 1px;
}

.layout-user__header-menu-avatar--mob-img {
    width: 23px;
    margin-top: 1px;
}

.layout-user__header-menu-select-wrap {
    display: none !important;
}

.layout-user__sidenav--is-safari {
    padding-bottom: 90px;
}

.layout-user__sidenav-link-icon--requests {
    width: 22px;
    height: auto;
}

.layout-user__sidenav-link-icon--requests-hover {
    width: 22px;
    height: auto;
}

.layout-user__header-menu-link-icon-wrap {
    position: relative;
}

.layout-user__header-count-unread-notifications {
    position: absolute;
    top: -10px;
    right: -10px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid;
    border-radius: 50%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-green);
    color: #fff;
    padding: 3px;
}

.layout-user__header-sidenav-count-unread-notifications {
    position: absolute;
    top: -10px;
    right: -10px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid;
    border-radius: 50%;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-green);
    color: #fff;
    padding: 3px;
    opacity: 0;
    transition: .3s;
}

.layout-user__header-count-unread-notifications--sidenav-is-open {
    top: -10px;
    right: -19px;
    opacity: 1;
}

.layout-user__header-count-unread-notifications--sidenav-is-close {
    top: 0;
    right: 1px;
    opacity: 1;
}

@media (min-width: 576px) {
    .layout-user__header-logo {
        width: 150px;
        height: auto;
    }
}

@media (min-width: 600px) {
    .layout-user__header-menu-link {
        display: flex;
    }
}

@media (min-width: 768px) {
    .layout-user__header {
        padding: 12.5px 0;
    }
    .layout-user__header--is-open-menu {
        position: static;
        border-bottom: none;

    }
    .layout-user__sidenav--open {
        position: static;
        padding: 20px 15px 42px 0;
        top: 88px;
    }
    .layout-user__outlet-wrap {
        
    }
    .layout-user__header-search--mob-wrap {
        display: none;
    }
    .layout-user__header-search--desc-wrap {
        display: block;
        margin-right: 15px;
    }
    .layout-user__sidenav-link-shop {
        border: 1px solid var(--main-green);
        height: 62px;
        margin-bottom: 25px;
    }
    .layout-user__sidenav-link-shop-title {
        margin-bottom: 6px;
    }
    .layout-user__sidenav-link-shop-arow {
        top: 38%;
    }
    .layout-user__header-menu-avatar--desc {
        display: block;
    }
    .layout-user__header-menu-avatar--mob {
        display: none;
    }
    .layout-user__header-menu-avatar {
        width: 33px;
        height: 33px;
        margin-left: 20px;
    }
    .layout-user__outlet-wrap--close {
        max-width: none;
        max-height: none;
        width: calc(100% - 215px);
    }
    .layout-user__header-menu-link-text {
        display: block;
    }
    .layout-user__header-menu-select-wrap {
        display: block !important;
    }
    .layout-user__header-menu-link-icon {
        width: 17px;
        height: 17px;
    }
    .layout-user__header-menu-wrap {
        margin-left: auto;
    }
    .layout-user__header-logo {
        width: 127px;
        height: auto;
    }
    .layout-user__header-menu-avatar--mob-wrap {
        display: none;
    }
    .layout-user__header-menu {
        position: fixed;
        top: 91.5%;
        left: 13px;
        z-index: 15;
        background: #fff;
        width: 203px;
        padding: 15px 0px 18px 15px;
        transition: .3s;
    }
    .layout-user__sidenav--close {
        display: block;
        max-width: calc(100% - 230px);
    }
    .layout-user__outlet-wrap--open {
        max-width: calc(100% - 61px);
    }
    .layout-user__outlet-wrap {
        border-left: 1px solid #D8D8D8;
        font-size: 14px;
    }
    .layout-user__sidenav-link {
        margin-bottom: 10px;
    }
}
@media (min-width: 1200px) {
    .layout-user__sidenav-link-text {
        font-size: 14px;
    }
    .layout-user__sidenav-link {
        margin-bottom: 10px;
    }
}
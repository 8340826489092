.success-view {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    margin-top: 40px;
}

.success-view__title {
    font-size: 18px;
    font-weight: 600;
    font-size: 22px;
}

.success-view__text {
    max-width: 476px;
    text-align: center;
    line-height: 1.8;
    font-size: 20px;
}
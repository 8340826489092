.seo-settings {
    padding: 15px 0;
}

.seo-settings__tab-img {
    width: 20px;
    height: 20px;
}

.seo-settings__result-tab-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding: 20px 0;
}

.seo-settings__result-tab-input-wrap {

}

.seo-settings__result-tab-input-lable {
    margin-bottom: 10px;
    font-weight: 500;
}

.seo-settings__text-tab--wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    height: 55.6px;
    padding: 0 15px;
}

.seo-settings__text-tab-wrap {
    display: flex;
    gap: 10px;
    height: 55.6px;
    overflow-y: hidden;
    overflow-x: auto;
}

.seo-settings__text-tab-wrap::-webkit-scrollbar {
    height: 1px;
    background: #898989;
}

.seo-settings__text-tab {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 14px;
    padding-bottom: 20px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.seo-settings__textarea {
    line-height: 1.5;
}

@media (min-width: 768px) {
    .seo-settings {
        padding: 22px 0 22px 15px;
    }
}
@media (min-width: 1200px) {
    .seo-settings__text-tab--wrap {
        padding: 0 45px;
    }
}
@media (min-width: 1680px) {
    .seo-settings__text-tab--wrap {
        padding: 0 75px;
    }
    .seo-settings__text-tab {
        font-size: 15px;
    }
}
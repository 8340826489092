.add-characteristics {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
}

.add-characteristics .MuiInputBase-root {
    border-radius: 0;
}

.add-characteristics .MuiInputBase-input {
    padding: 11.5px 14px;
}

.add-characteristics--is-sub-category {
    max-width: none;
}
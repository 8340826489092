.home-view {
    color: var(--dark-charcoal);
    margin-bottom: 20px;
}

.home-view-wrap {
    padding: 0 15px;
}

.home-view__title-wrap {
    position: relative;
    margin-bottom: 60px;
    width: 100%;
}

.home-view__title-banner {
}

.home-view__title-banner.swiper {
    padding-bottom: 25px;
}    

.home-view__title-banner .swiper-pagination {
    transform: translateY(9px);
}

.home-view__title-banner .swiper-pagination-bullet {
    position: relative;
    width: 8px;
    height: 8px;
    background: #fff;
    border: 1px solid #49454F;
    opacity: 1;
    margin: 0 10px !important;
}

.home-view__title-banner .swiper-pagination-bullet::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -6px;
    width: 20px;
    height: 20px;
}

.home-view__title-banner .swiper-pagination-bullet-active {
    position: relative;
    background: var(--main-green);
}

.home-view__banner-text-wrap {
    font-style: normal;
    padding: 1px;
    z-index: 2;
    font-family: 'Montserrat';
    color: rgba(25, 25, 29, 1);
}

.home-view__banner-text {
    font-size: 20px;
    font-weight: 600;
    flex-shrink: 0;
    font-style: normal;
    font-family: 'Montserrat';
    line-height: 22.5px;
    width: 100%;
    -webkit-text-size-adjust: 100%;
    min-height: 77px;
    padding: 15px 15px 15px 15px;
    border-bottom: 1px solid #2A7F77;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-view__banner-text-btn {
    display: none;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
}

.home-view__banner-text-btn:hover {
    color: var(--main-green);
}

.home-view__title-img {
    height: 340px;
}

.home-view__title-text {
    position: absolute;
    top: 40%;
    left: 15px;
    transform: translateY(-50%);
    line-height: 28px;
    font-size: 22px;
    font-weight: 700;
    color: #2e2e2e;
    width: 50%;
    letter-spacing: 1px;
}

.home-view__info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 22px;
    padding: 0 0;
    margin-bottom: 60px;
}

.home-view__info {
    display: flex;
    gap: 15px;
}

.home-view__info-title {
    font-size: 15px;
    font-weight: 600;
    font-family: Montserrat;
    line-height: 15px;
    margin-bottom: 8px;
    color: #19191D;
}

.home-view__info-text {
    line-height: 22.5px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #49454F;
}

.home-view__banner-wrap {
    margin-bottom: 60px;
    background: #f9f9f9;
    margin-right: -15px;
    margin-left: -15px;
}

.home-view__cards-wrap {
   display: flex;
   flex-direction: column; 
   gap: 54px;
}

.home-view__cards-catalog-btn {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    transition: .3s;
}

.home-view__cards-catalog-btn:hover {
    color: var(--main-green);
}

.home-view__cards-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 32px;
}

.home-view__cards-title {
    line-height: 33px;  
    font-weight: 700;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

.home-view__cards {
    display: flex;
    gap: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1;
    width: calc(100% + 8px);
    margin-left: -4px;
}

.home-view__card-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
    width: 45.5%;
    max-width: 50%;
    padding: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: .3s;
}

.home-view__card-wrap:hover {
    border: 1px solid var(--main-green);
}

.home-view__cards-btn {
    display: block;
    color: var(--dark-charcoal);
    height: 45px;
    font-size: 13px;
    font-weight: 500;
    padding: 11px 20px;
    margin-top: 55px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--main-green);
    transition: .3s;
}

.home-view__cards-btn:hover {
    background-color: var(--main-green);
    color: #fff;
}

.home-view__about-marketplace-wrap {
}

.home-view__about-work-title {
    line-height: 21px;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 38px;
    margin-top: 60px;
}

.home-view__about-work-items {
    display: flex;
    margin-bottom: 60px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.home-view__about-work-item {
    max-width: 340px;
    background: #F9F9F9;
    padding: 18px 14px 30px 40px;
    height: 184.4px;
}

.home-view__about-work-item-title {
    line-height: 21px;
    font-size: 22px;
    font-weight: 600; 
    margin-bottom: 20px;
    color: var(--main-green);
    line-height: 120%;
}

.home-view__about-work-item-text {
    font-size: 15px;
    line-height: 22.5px;
    font-weight: 400;
    color: var(--dark-charcoal);
}

.home-view__card-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.home-view__about-marketplace-mob-wrap {
    
}

.home-view__about-marketplace-des-wrap {
    display: none;
}

.home-view__be-seller-mob-wrap {
    }

.home-view__be-seller-des-wrap {
    display: none;
}

.home-view__producers-block-mob-wrap {
    padding: 0 15px;
}

.home-view__producers-block-des-wrap {
    display: none;
}

.home-view__title-banner-link {
    width: 100%;
    cursor: pointer;
}

.home-view__title-banner {
    overflow-x: hidden;
  }
  
  .home-view__title-banner-slide-wrap {
    scroll-snap-align: start;
  }

@media (min-width: 376px) {
    .home-view__cards {
        flex-direction: row;
    }
    .home-view__card-wrap {
        width: 45.5%;
        max-width: 50%;
    }
}
@media (min-width: 480px) {
    .home-view__cards {
        display: flex;
        flex-direction: row;
        align-items: unset;
        justify-content: flex-start;
        flex-wrap: wrap; 
        gap: 2px;
    }
    .home-view__card-wrap {
        width: 45.5%;
        max-width: 50%;
    }
    .home-view__title-img {
        height: 400px;
    }
}
@media (min-width: 500px) {
    .home-view__about-work-items {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .home-view__about-work-item {
        width: 47%;
        height: 230px;
    }
}
@media (min-width: 570px) {
    .home-view__about-work-item {
        height: 204px;
    }
}
@media (min-width: 640px) {
    .home-view__card-wrap {
        width: 45.5%;
        max-width: 50%;
    }
    .home-view__about-work-items {
        display: flex;
        flex-direction: row;
        align-items: inherit;
        justify-content: center;
        flex-wrap: wrap;
    }
    .home-view__about-work-item {
        width: 48%;
        height: 204px;
        max-width: 340px;
    }
    .home-view__title-text {
        left: 25px;
        line-height: 43px;
        font-size: 36px;
        width: 52%;
    }
    .home-view__info-wrap {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }
    .home-view__info {
        width: 48%;
    }
    .home-view__cards-title {
        line-height: 23px;
        font-size: 24px;
    }
    .home-view__about-work-title {
        font-size: 24px;
    }
    .home-view__banner-text {
        font-size: 24px;
        line-height: 30px;
    }
    .home-view__banner-text {
        border: none;
        padding: 0;
        min-height: auto;
    }
    .home-view__banner-text-wrap {
        position: absolute;
        top: 32%;
        left: 5%;
        font-style: normal;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.70);
        max-width: 490px;
        padding: 15px 25px 15px 25px;
    }
    .home-view__title-banner .swiper-pagination-bullet {
        width: 9px !important;
        height: 9px !important;
    }
    .home-view__title-banner .swiper-pagination{
        bottom: 17px !important;
        transform: translateY(-5px);
    } 
    .home-view__banner-text-btn {
        display: block;
    }
    .home-view__info-title {
        margin-bottom: 12px;
    }
    .home-view__info-title {
        font-size: 16px;
    }
    .home-view__info-text {
        font-size: 15px;
    }
    .home-view__about-marketplace-mob-wrap {
        display: none;
    }
    .home-view__about-marketplace-des-wrap {
        display: block;
    }
    .home-view__be-seller-mob-wrap {
        display: none;
    }
    .home-view__be-seller-des-wrap {
        display: block;
    }
    .home-view__producers-block-mob-wrap {
        display: none;
    }
    .home-view__producers-block-des-wrap {
        display: block;
    }
    .home-view__title-banner.swiper {
        padding-bottom: 0;
    }   
}
@media (min-width: 700px) {
    .home-view__title-img {
        height: 450px;
    }
}
@media (min-width: 800px) {
    .home-view__title-img {
        height: 550px;
    }
}
@media (min-width: 960px) {
    .home-view__about-work-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .home-view__about-work-item {
        width: 23%;
        height: auto;
    }
    .home-view__card-wrap {
        width: 22.5%;
        max-width: 24.7%;
    }
    .home-view__title-img {
        height: 610px;
    }
    .home-view__about-work-title {
        font-size: 24px;
        line-height: 27px;
    }
    .home-view__banner-text {
        font-size: 24px;
    }
    .home-view__info-wrap {
        gap: 23px;
    }
    .home-view__info {
        width: 38%;
    }
    .home-view__cards {
        width: calc(100% + 18px);
    }
    .home-view__banner-text-wrap {
        max-width: 501px;
        padding: 19px 35px 19px 35px;
    }
}
@media (min-width: 1200px) {
    .home-view__card-wrap {
        width: 23.2%;
        max-width: 24.77%;
    }
    .home-view__title-img {
        height: 680px;
    }
    .home-view__about-work-item-title {
        font-size: 27px;
    }
    .home-view__info {
        width: 21%;
    }
    .home-view__banner-text {
        font-size: 32px;
        font-style: normal;
        font-family: 'Montserrat';
        line-height: 42px;
    }
    .home-view-wrap {
        padding: 0 30px;
    }
    .home-view__cards-title {
        font-size: 25px;
        line-height: 27px;
    }
    .home-view__banner-text-wrap {
        top: 50%;
        max-width: 600px;
        padding: 28px 25px 28px 35px;
        background: rgba(255, 255, 255, 0.90);
    }
    .home-view__banner-text-btn {
        margin-top: 20px;
    }
    .home-view__banner-wrap {
        margin-right: -30px;
        margin-left: -30px;
    }
    .home-view__about-work-title {
        font-size: 25px;
    }
    .home-view__title-banner .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
    }
}
@media (min-width: 1680px) {
    .home-view__info-title {
        font-size: 17px;
    }
    .home-view__info-text {
        font-size: 16px;
        line-height: 23px;
    }
    .home-view__info {
        width: 23%;
    }
    .home-view__cards-btn {
        font-size: 14px;
    }
    .home-view__cards-catalog-btn {
        font-size: 16px;
    }
    .home-view__about-work-item-text {
        font-size: 16px;
        line-height: 23px;
    }
    .home-view__banner-text-btn {
        font-size: 16px;
    }
    .home-view__title-img {
        height: 800px;
    }
    .home-view__about-work-item {
        width: 24%;
        max-width: 500px;
    }
    .home-view__card-wrap {
        width: 24.5%;
        max-width: 24.5%;
        padding: 6px;
    }
    .home-view__cards {
        width: calc(100% + 12px);
        gap: 0;
        margin-left: -6px;
    }
}

@media (min-width: 2700px) {
    .home-view__banner-text-wrap {
        left: 9%;
    }
}
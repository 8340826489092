.header {
}

.header-wrap {
	display: flex;
	flex-direction: column;
}

.header-wrap--catalog1 {
	padding-top: 88px;
}

.header-wrap--catalog2 {
	padding-top: 64px;
}

.header__menu--wrap {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 15px 0 0;
}

.header__menu--wrap::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #cfcfcf;
}

.header__header-links:hover {
	text-decoration: underline;
}

.header__header-links-wrap--catalog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 21px;
	z-index: 2;
}

.header__menu--wrap--catalog1 {
	position: fixed;
	top: 24.2px;
	left: 0;
	padding: 0 15px 0 0;
	background: #fff;
	z-index: 3;
}

.header__menu--wrap--catalog2 {
	position: fixed;
	top: 0;
	left: 0;
	background: #fff;
	z-index: 20;
}

.header__logo-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 19.4px 10px 19.4px;
	text-align: center;
	flex: 1 1;
}

.header__logo-link {
	position: relative;
	z-index: 25;
	padding: 5px;
	cursor: pointer;
}

.header__logo-link:focus,
.header__logo-link:hover,
.header__logo-link:active {
	cursor: pointer;
}

.header__logo {
	width: 148px;
	height: auto;
	object-fit: contain;
}

.header__right {
	position: relative;
	z-index: 25;
	display: flex;
	gap: 15px;
	align-items: center;
}

.header__shopping-img-wrap {
	display: flex;
	gap: 20px;
	align-items: center;
}

.header__shopping-img--wrap {
	position: relative;
}

.header__shopping-img {
	width: 21.494px;
	height: 17px;
	flex-shrink: 0;
	cursor: pointer;
}

.header__like-img {
	width: 18.15px;
	height: 15.2px;
	cursor: pointer;
}

.header__count-circle {
	position: absolute;
	display: flex;
	align-items: baseline;
	justify-content: center;
	color: #fff;
	background: var(--main-green);
	font-size: 9px;
	font-weight: 600;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	z-index: 20;
	padding: 2px;
}

.header__count-like {
	top: -7px;
	right: -9px;
}

.header__count-shopping {
	top: -7px;
	right: -10px;
}

.header__menu-text-wrap {
	display: flex;
	gap: 5px;
	align-items: center;
}

.header__menu-wrap {
	align-items: center;
	gap: -webkit-20px;
	gap: -moz-20px;
	gap: -ms-20px;
	gap: -o-20px;
	gap: 12px;
	padding: 2px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.header__menu-text {
	display: none;
	color: #000;
	font-size: 11px;
	font-weight: 400;
	line-height: normal;
}

.header__menu {
	cursor: pointer;
	padding: 8px;
	border-radius: 50%;
	transition: 0.3s;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.no_header__menu {
	display: none;
}

.header__menu-img {
	object-fit: contain;
	width: 19px;
	height: 19px;
	max-width: 19px;
	max-height: 19px;
}

.header__menu:hover {
	background: #f1f1f1;
}

.header__menu-line {
	width: 24px;
	height: 1.55px;
	background-color: rgba(25, 25, 29, 1);
	margin: 5px 0;
}

.header__menu-side-menu-wrap {
}

.header__menu-side-menu--wrap {
}

.header__menu-side-menu {
}

.header__menu-side-menu--wrap .MuiBackdrop-root {
	background: rgba(25, 25, 29, 0.3);
	top: 116px;
	width: 100vw;
}

.header__menu-side-menu--wrap .MuiPaper-root,
.header__menu-side-menu--wrap.MuiDrawer-root {
	top: 57px;
	box-shadow: none;
	height: calc(100vh - 57px);
	width: 100vw;
}

.header__search--wrap {
	position: relative;
}

.header__search-wrap {
	position: absolute;
	width: 101vw;
	top: 8px;
	left: -56px;
	background: #fff;
	transform: translateY(-50%);
	z-index: 26;
}

.header__search-close-btn-wrap {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 33px;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 10;
}

.header__search-close-btn {
	position: relative;
	width: 12px;
	height: 12px;
	cursor: pointer;
	z-index: 10;
}

.header__search-img {
	width: 15px;
	height: 15px;
	cursor: pointer;
}

.header__search-input-img {
	position: absolute;
	top: 49%;
	left: 56px;
	transform: translateY(-50%);
	width: 15px;
	height: 15px;
	z-index: 2;
	cursor: pointer;
}

.header__search-close {
	display: none;
	width: 100%;
	height: 99vh;
	position: absolute;
	overflow: hidden;
	top: 41px;
	left: 3px;
	background: #fff;
}

.header__search-input-wrap {
	position: relative;
	z-index: 1;
	display: flex;
	gap: 12px;
	align-items: center;
	width: 100vw;
	padding-left: 15px;
	padding-right: 15px;
}

.header__search-btn-arrow {
	width: 20px;
	height: 20px;
}

.header__search-input {
	border: 1px solid var(--main-green);
	padding: 9.5px 36px 9.5px 38px;
	width: 100%;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.header__search-history-wrap {
	display: flex;
	gap: 20px;
	position: fixed;
	top: 100%;
	left: 5px;
	width: 101vw;
	height: calc(100vh - 57px);
	background: #fff;
	padding-top: 14px;
	padding-bottom: 25px;
	padding-left: 15px;
	padding-right: 15px;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 10;
	box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.header__search-history-result--wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: auto;
	padding-right: 5px;
}

.header__search-history-result--wrap::-webkit-scrollbar {
	width: 5px;
	background-color: rgb(217, 214, 214);
}

.header__search-history-result-history-wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-left: 5px;
	width: 100%;
	overflow: hidden;
}

.header__search-history-result-wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-left: 5px;
	width: 100%;
}

.header__search-history-result {
	display: flex;
	gap: 5px;
	cursor: pointer;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
	margin-left: 0px;
	transition: 0.3s;
}

.header__search-history-result:hover {
	color: var(--main-green);
}

.header__search-history-result-search-wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	height: fit-content;
}

.header__search-history-result-category {
	justify-content: space-between;
	align-items: center;
	max-width: 330px;
}

.header__search-history-result:hover
	.header__search-history-result-category-arrow {
	display: none;
}

.header__search-history-result:hover
	.header__search-history-result-category-arrow-hover {
	display: block;
}

.header__search-history-result-category-arrow {
	width: 18px;
	height: 18px;
	transform: rotate(180deg);
}

.header__search-history-result-category-arrow-hover {
	display: none;
	width: 18px;
	height: 18px;
	transform: rotate(180deg);
}

.header__search-history-result-sub-title {
	font-size: 14px;
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: 500;
}

.header__search-history-result-img {
	width: 50px;
	height: 50px;
	margin-right: 5px;
	aspect-ratio: 1/1;
	object-fit: contain;
}

.header__search-history-result-text-wrap {
	display: flex;
	flex-direction: column;
}

.header__search-history-result-text {
}

.header__search-history-title {
	margin-bottom: 15px;
	margin-top: 10px;
	font-size: 18px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.header__search-history-product--wrap {
	display: none;
	width: 100%;
	overflow: hidden;
	flex: 1 1;
}

.header__search-history-product--wrap-full {
	max-width: calc(100vw - 340px);
}

.header__search-history-product-wrap {
	display: flex;
	gap: 15px;
	overflow: auto;
	padding: 15px 0;
}

.header__search-history-product {
	position: relative;
	cursor: pointer;
	width: 18%;
	min-width: 200px;
	max-height: 365px;
	transition: 0.3s;
	border: 1px solid #fff;
	padding: 8px;
}

.header__search-history-product:hover {
	border: 1px solid var(--main-green);
}

.header__search-history-product-img {
	aspect-ratio: 1/1;
	height: auto;
	max-height: 253px;
}

.header__search-history-product-img-none {
	max-width: 300px;
	min-width: 200px;
	aspect-ratio: 1/1;
	height: auto;
	padding: 10px;
}

.header__search-history-product-text {
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;
}

.header__categories-wrap {
	display: none;
	gap: 20px;
	justify-content: flex-start;
	flex-wrap: wrap;
	font-size: 16px;
	font-weight: 500;
	padding: 15px 15px;
	border-bottom: 1px solid #cfcfcf;
	transition: 0.3s;
}

.header__categories {
	display: flex;
	align-items: baseline;
	gap: 4px;
	cursor: pointer;
	order: 1;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Montserrat';
	text-transform: uppercase;
	transition: 0.3s;
}

.header__categories:hover {
	position: relative;
	color: var(--main-green);
}

.header__categories:hover .header__categories-img-path,
.header__categories:hover .header__categories-img {
	transform: rotate(180deg);
	fill: var(--main-green);
	transform-origin: center center;
}

.header__categories:hover .header__sub-categories--wrap {
	z-index: 10;
	opacity: 1;
	height: auto;
	text-transform: none;
}

.header__categories-img {
	width: 12px;
	height: 12px;
	fill: #000;
	transform: rotate(0deg);
	transition: 0.3s;
	margin-left: 5px;
}

.header__categories-title {
	position: relative;
}

.header__sub-categories--wrap {
	position: absolute;
	top: 12px;
	z-index: -10;
	opacity: 0;
	height: 0;
	width: 100vw;
}

.header__menu-side-menu--is-link--wrap {
}

.header__menu-side-menu--is-link--wrap .MuiBackdrop-root {
	top: 0;
	background: inherit;
}

.header__menu-side-menu--is-link--wrap .MuiPaper-root,
.header__menu-side-menu--is-link--wrap.MuiDrawer-root {
	top: 87px;
	box-shadow: none;
	width: 100vw;
	height: calc(100vh - 87px);
}

.header--is-search {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 100000;
	background: #fff;
}

@media screen and (-moz-windows-theme) {
	.header__sub-categories--wrap {
		position: absolute;
		top: 15px;
		z-index: -10;
		opacity: 0;
		height: 0;
		width: calc(100vw - 17px);
	}
}

.header__sub-categories--wrap-last-item {
	position: absolute;
	top: 15px;
	left: 7px;
	z-index: -10;
	opacity: 0;
	height: 0;
	width: 0;
}

.header__sub-categories-wrap {
	display: flex;
	gap: 60px;
	flex-wrap: wrap;
	overflow: auto;
	color: #000;
	border: 1px solid #cfcfcf;
	padding: 25px 15px;
	transition: 0.3s;
	background: #fff;
	margin-top: 16px;
}

.header__sub-categories {
	font-size: 14px;
	white-space: nowrap;
	font-weight: 400;
	padding: 5px;
	cursor: pointer !important;
	transition: 0.3s;
}

.header__sub-categories:hover {
	color: var(--main-green);
}

.header__sub-categories-background {
	width: 100%;
	height: 100vh;
	background: rgba(25, 25, 29, 0.3);
}

.header__header-links-wrap {
	background: var(--dark-charcoal);
	color: #fff;
	font-size: 12px;
	padding: 1.4px 0px 2.5px;
	font-weight: 500;
	height: 24.2px;
	z-index: 9;
}

.header__header-links--wrap {
	display: none;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	max-width: 2300px;
	min-height: max-content;
	margin: 0 auto;
	font-size: 12px;
	padding: 2px 15px 2px 15px;
}

.header__header-links--mobile--wrap {
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 21px;
}

.header__header-links--mobile-wrap {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0 15px 0 9px;
}

.header__header-links-mobile {
	display: flex;
	justify-content: space-around;
	color: var(--colorTopBlockHeader);
	flex-shrink: 0;
	margin: 0;
	white-space: nowrap;
	animation-name: marqueeLine;
	animation-duration: 6s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	text-align: center;
}

.header__header-links-mobile > a {
	text-decoration: underline;
}

.header__search-history-result-autocomplete-wrap {
	display: flex;
	gap: 20px;
	flex-direction: column;
	margin-bottom: 15px;
	width: fit-content;
}

.header__search-history-result-autocomplete {
	display: flex;
	align-items: center;
	gap: 10px;
	width: fit-content;
	cursor: pointer;
	transition: 0.3s;
}

.header__search-history-result-autocomplete:hover {
	color: var(--main-green);
}

.header__search-history-result-autocomplete-img {
	width: 15px;
	height: 15px;
	fill: #757575;
}

.header__search-history-result-autocomplete:hover
	.header__search-history-result-autocomplete-img {
	fill: var(--main-green);
}

.header__search-history-product-delete-text {
	position: absolute;
	top: 39%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 33px;
	color: red;
	font-weight: 600;
}

.header__header-links-mobile--1 {
	display: none;
}

.header__menu-img-close {
	width: 11px;
	height: 11px;
	max-width: 11px;
	max-height: 11px;
}

.header__search-history-is-safari-wrap {
	padding-bottom: 90px;
}

.header__search-history-error {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	font-size: 18px;
	text-align: center;
}

.header__skeleton-categories {
	width: 100%;
	max-width: 90px;
	height: 15.2px !important;
	--webkit-cursor: pointer !important;
}

.header__categories-link {
	cursor: pointer !important;
	--webkit-cursor: pointer !important;
}

@media (min-width: 408px) {
	.header__logo {
		width: 155px;
	}
	.header__logo-wrap {
		padding: 18.9px 10px 18.9px;
	}
}
@media (min-width: 420px) {
	.header__search-wrap {
		left: -56px;
	}
}
@media (min-width: 576px) {
	.header__shopping-count {
		width: 20px;
		height: 20px;
	}
	.header__menu-side-menu--wrap .MuiPaper-root,
	.header__menu-side-menu--wrap.MuiDrawer-root {
		top: 57px;
		height: calc(100vh - 57px);
	}
	.header__menu-side-menu--is-link--wrap .MuiPaper-root,
	.header__menu-side-menu--is-link--wrap.MuiDrawer-root {
		top: 81px;
		height: calc(100vh - 81px);
	}
}
@media (min-width: 640px) {
	.header__search-wrap {
		width: 380px;
		top: 51%;
		left: -10px;
	}
	.header__shopping-img-wrap {
		padding-left: 63.4px;
	}
	.header__search-btn-arrow {
		display: none;
	}
	.header__search-input-wrap {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	.header__search-input-img {
		left: 10px;
	}
	.header__search-history-wrap {
		left: -99px;
		max-height: 70vh;
	}
	.header__search-close {
		display: block;
		width: 99vw;
		background: initial;
		top: -35px;
		height: 100vh;
		left: -94px;
	}
	.header__search-history-result-history-wrap {
		padding-left: 85px;
		padding-bottom: 20px;
	}
	.header__search-history-result-wrap {
		padding-left: 85px;
		padding-bottom: 20px;
	}
	.header__header-links-mobile--1 {
		display: block;
	}
	.header__header-links--mobile-wrap {
		justify-content: space-between;
		padding: 0 10px;
	}
	.header__menu-text {
		display: block;
	}
	.header__menu {
		width: 35px;
		height: 38px;
	}
	.header__menu-wrap {
		gap: 20px;
	}
	.header__search-close-btn-wrap {
		right: 12px;
	}
	.header__logo-wrap {
		padding: 18.64px 10px 18.64px;
	}
	.header__logo {
		width: 160px;
	}
	.header__menu--wrap--catalog1 {
		padding: 0 15px 0 5px;
	}
	.header__menu--wrap {
		padding: 0 15px 0 5px;
	}
	.header__search-history-error {
		align-items: center;
	}
}
@media (min-width: 768px) {
	.header__search-history-product--wrap {
		display: block;
	}
	.header__search-wrap {
		width: 375px;
	}
	.header__menu-wrap {
		gap: 27px;
		padding: 2px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.header__shopping-img-wrap {
		gap: 27px;
	}
	.header__search-history-wrap {
		left: -104px;
		padding-bottom: 15px;
		box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
		min-height: auto;
	}
	.header__search-close {
		display: block;
		left: -110px;
	}
	.header__search-history-result-history-wrap {
		padding-left: 90px;
		width: auto;
	}
	.header__search-history-result-wrap {
		padding-left: 90px;
		width: auto;
	}
	.header__search-history-error--history-products {
		max-width: 404px;
	}
}
@media (min-width: 800px) {
	.header__search-history-result-history-wrap {
		min-width: 250px;
	}
	.header__search-history-result-wrap {
		min-width: 250px;
	}
	.header__search-wrap {
		width: 447px;
	}
}
@media (min-width: 960px) {
	.header__menu-side-menu--is-link--wrap.MuiDrawer-root,
	.header__menu-side-menu--wrap.MuiDrawer-root {
		top: 0;
	}
	.header__menu-side-menu--is-link--wrap .MuiPaper-root,
	.header__menu-side-menu--wrap .MuiPaper-root,
	.header__menu-side-menu--wrap .MuiBackdrop-root {
		top: 0;
		height: 100%;
		width: auto;
	}
	.header__header-links--wrap {
		display: flex;
	}
	.header__header-links--mobile--wrap {
		display: none;
	}
	.header__logo {
		width: 188px;
	}
	.header__search-wrap {
		width: 255px;
	}
	.header__logo-wrap {
		padding: 21px 20px 21px;
	}
	.header-wrap--catalog1 {
		padding-top: 93px;
	}
	.header-wrap--catalog2 {
		padding-top: 71px;
	}
}
@media (min-width: 1200px) {
	.header__menu--wrap {
		padding: 0 30px 0 20px;
	}
	.header__sub-categories-wrap {
		padding: 30px 30px;
	}
	.header__categories {
		font-size: 12px;
	}
	.header__logo-wrap {
		padding: 24px 20px 24px;
	}
	.header__search-history-wrap {
		left: -120px;
		padding-left: 31px;
	}
	.header__search-wrap {
		width: 330px;
	}
	.header__search-history-product--wrap {
		padding-right: 30px;
	}
	.header__search-close {
		left: -125px;
		top: -43px;
	}
	.header__search-history-result-history-wrap {
		padding-left: 89px;
	}
	.header__search-history-result-wrap {
		padding-left: 89px;
	}
	.header__header-links--wrap {
		font-size: 14px;
	}
	.header__header-links-wrap {
		font-size: 14px;
	}
	.header__header-links--mobile-wrap {
		padding: 0 30px;
	}
	.header__header-links--wrap {
		padding: 2px 30px 2px 30px;
	}
	.header-wrap--catalog1 {
		padding-top: 101.94px;
	}
	.header-wrap--catalog2 {
		padding-top: 77px;
	}
}

@media (min-width: 1289px) {
	.header__categories-wrap {
		display: flex;
		padding: 13px 30px;
		gap: 12px;
	}
}
@media (min-width: 1350px) {
	.header__categories-wrap {
		gap: 17px;
	}
}
@media (min-width: 1440px) {
	.header__categories-wrap {
		font-size: 12px;
		gap: 20px;
	}
}
@media (min-width: 1680px) {
	.header__categories-wrap {
		justify-content: flex-start;
		gap: 30px;
	}
	.header__categories {
		font-size: 14px;
	}
	.header__like-img {
		width: 21.2px;
		height: 18px;
	}
	.header__shopping-img {
		width: 25px;
		height: 19px;
	}
	.header__search-img {
		width: 18px;
		height: 18px;
	}
	.header__menu-text {
		font-size: 13px;
	}
	.header__count-shopping {
		top: -7px;
	}
	.header__search-history-wrap {
		left: -137px;
	}
	.header__search-history-result-history-wrap {
		padding-left: 118px;
	}
	.header__search-history-result-wrap {
		padding-left: 118px;
	}
	.header__skeleton-categories {
		height: 16.8px !important;
	}
	.header__sub-categories {
		font-size: 15px;
	}
}

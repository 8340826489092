.shopping-delivery__input-label {
    font-size: 15px;
    font-weight: 600;
}

.shopping-delivery__radio-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 25px;
}
.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 320px;
    color: var(--dark-charcoal);
}

.side-menu__logo-wrap {
    display: none;
    gap: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 22px;
    padding: 29px 24px 22px 10px;
}

.side-menu__logo-link {
    width: 201px;
    height: 19px;
}

.side-menu__logo-img {
    object-fit: contain;
}

.side-menu__close-btn {
    width: 20px;
    height: 20px;
}

.side-menu__close-btn-img {

}

.side-menu__link--wrap {
    position: relative;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 147px);
}

.side-menu__link-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 320px;
    flex: 1 1;
}

.side-menu__link-category-wrap {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.side-menu__link-category-title {
    display: none;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 17px;
}

.side-menu__link-category-parent-wrap {
    border-top: 1px solid #D8D8D8;
}

.side-menu__link-page-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 18px 30px 65px 18px;
    border-top: 1px solid #D8D8D8;
}

.side-menu__link-category {
    width: 100%;
    padding: 20px 30px 20px 20px;
    transition: .3s;
    color: #19191D;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
}

.side-menu__link-category-parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat;
    color: #19191D;
    font-style: normal;
    line-height: normal;
    padding: 20px 30px 20px 20px;
    transition: .3s;
}

.side-menu__link-category-parent--active {
    color: var(--main-green);
    fill: var(--main-green) !important;
    font-family: Montserrat;
}

.side-menu__link-category-mobile:hover,
.side-menu__link-category-btn-all:hover,
.side-menu__link-page-all-category:hover,
.side-menu__link-page:hover,
.side-menu__link-category:hover,
.side-menu__link-category-parent:hover {
    color: var(--main-green);
}

.side-menu__link-category-parent-arrow-img {
    width: 20px;
    height: 20px;
}

.side-menu__link-category-parent-arrow-img-path {
    fill: #49454F;
    transition: .3s;
}

.side-menu__link-page {
    font-size: 15px;
    font-weight: 600;
    padding: 8px 0;
    transition: .3s;
}

.side-menu__link-page-all-category {
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
    transition: .3s;
}

.side-menu__link-category-btn-back-wrap {
    display: flex;
    gap: 15px;
    align-items: baseline;
    justify-content: space-between;
    font-size: 18px;
}

.side-menu__link-category-btn-back-text {
    cursor: pointer;
    font-size: 17px;
    font-family: Montserrat;
    font-weight: 600;
}

.side-menu__link-category-btn-back {
    width: 45px;
    border-radius: 50%;
    padding: 6px;
    transition: .3s;
}

.side-menu__link-category-btn-back:hover {
    background: aliceblue;
}

.side-menu__link-category-btn-all {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    transition: .3s;
    margin-top: -12px;
}

.side-menu__link-sub-category-wrap {
    display: none;
    flex-direction: column;
    width: 430px;
    padding: 18px 36px 26px 26px;
    border-left: 1px solid #D8D8D8;
}

.side-menu__link-category-right {
    font-size: 15px;
    text-align: start;
    transition: .3s;
}

.side-menu__link-category-right:hover {
    color: var(--main-green);
}

.side-menu__link-sub-category-mobile-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
} 

.side-menu__link-sub-category-mobile-back {
    display: flex;
    gap: 18px;
    align-items: center;
    border-bottom: 1px solid var(--main-green);
    padding: 24px 15px 20px 20px;
    cursor: pointer;
    border-top: 1px solid #D8D8D8;
}

.side-menu__link-sub-category-mobile-arrow-img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
}

.side-menu__link-sub-category-mobile-back-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--neutral-gray);
}   

.side-menu__link-category-mobile-btn-back-wrap {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding: 21px 18px 10px 12px;
    border-bottom: 1px solid #D8D8D8;
}

.side-menu__link-category-mobile-btn-back-text {
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    padding: 8px 0;
    transition: .3s;
}

.side-menu__link-category-mobile {
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
    padding: 10px 17px 18px 35px;
    border-bottom: 1px solid #D8D8D8;
    margin-top: 8px;
    transition: .3s;
}

.side-menu__link-page-is-safari-wrap {
    padding-bottom: 130px;
}

.side-menu__link-wrap--mob {
    height: 85vh;
    overflow: hidden;
}

@media (min-width: 640px) {
    .side-menu__link-page-wrap {
        padding: 18px 30px 35px 18px;
    }
}
@media (min-width: 960px) {
   .side-menu__link-sub-category-wrap {
       display: flex;
       gap: 24px;
   }
   .side-menu__logo-wrap {
       display: flex;
   }
   .side-menu__link-sub-category-mobile-wrap {
       display: none;
   }
   .side-menu__link-category-title {
       display: block;
   }
   .side-menu__link-category-wrap {
        padding: 18px 30px 18px 18px;
        gap: 24px;
    }
    .side-menu__link-category-parent {
        border-top: 0;
        padding: 6px 0;
    }
    .side-menu__link-category-right {
        padding: 7px 0;
    }
    .side-menu__link-category {
        padding: 6px 0;
    }
    .side-menu__link-category-parent-wrap {
        border-top: 0;
    }
    .side-menu__link-wrap {
        width: 430px;
    }
}
@media (min-width: 1680px) {
    .side-menu__link-category-title {
        font-size: 18px;
    }
    .side-menu__link-category-parent {
        font-size: 16px;
    }
    .side-menu__link-page {
        font-size: 16px;
    }
    .side-menu__link-page-all-category {
        font-size: 16px;
    }
    .side-menu__link-category-parent {
        padding: 8px 0;
    }
    .side-menu__link-category-btn-back-text {
        font-size: 18px;
    }
    .side-menu__link-category-right {
        padding: 8px 0;
    }
    .side-menu__link-category {
        padding: 8px 0;
        font-size: 16px;
    }
    .side-menu__link-category-right {
        font-size: 16px;
    }
}
.user-notifications {
    font-size: 14px;
    color: #19191D;
}

.user-notifications__filter {
    display: flex;
    align-items: flex-start;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
    width: calc(100% + 30px);
    margin-left: -15px;
}

.user-notifications__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-notifications__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-notifications__header-title-img {
    width: 24px;
    height: 24px;
}

.user-notifications__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-notifications__notifications--wrap {
    width: 100%;
    padding: 20px 0;
}
.user-notifications__notifications-wrap {
    display: flex;
    flex-direction: column;
    border: 1px solid #D8D8D8;
}

.user-notifications__notifications {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    color: #898989;
    border-bottom: 1px solid #D8D8D8;
    padding: 12px 15px;
    overflow: hidden;
    color: var(--dark-charcoal);
}

.user-notifications__notifications-type-1 {
    background: #eeeeee;
    color: #898989;
    border-color: #898989;
}

.user-notifications__notifications-type-2 {
    background: #e4eaff;
    color: #b82dbd;
    border-color: #b82dbd;
}

.user-notifications__notifications-type-3 {
    background: #dfeceb;
    color: #2A7F77;
    border-color: #2A7F77;
}

.user-notifications__notifications-type-4 {
    background: #f4c8ba;
    color: #932906;
    border-color: #932906;
}

.user-notifications__notifications-type-5 {
    background: #b6e7d6;
    color: #4C6FFF;
    border-color: #4C6FFF;
}

.user-notifications__notifications-text-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    flex: 1 1;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    cursor: pointer;
}

.user-notifications__notifications-text-logo-wrap {
    display: flex;
    gap: 15px;
}

.user-notifications__notifications-text-name-wrap {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
}

.user-notifications__notifications-text-name--wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.user-notifications__notifications-text-name {
    font-weight: 600;
    color: #19191D;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-notifications__notifications-text-type {
    border: 1px solid;
    background: #fff;
    padding: 2px 12px;
    width: 110px;
    text-align: center;
}

.user-notifications__notifications-text-message {
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #898989;
}

.user-notifications__notifications-text {
    display: none;
    max-width: 260px;
    width: 100%;
}

.user-notifications__notifications-text-date {
    width: 150px;
}

.user-notifications__notifications-pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.user-notifications__notifications-btn {
    display: none;
    color: var(--dark-charcoal);
    background: #fff;
    padding: 13px 10px;
    border: 1px solid #2A7F77;
    font-size: 13px;
    transition: .3s;
}

.user-notifications__notifications-btn:hover {
    color: #fff;
    background: #2A7F77;
}

.user-notifications__products-header--wrap {
    display: none;
    padding: 15px 0 10px 0;
}

.user-notifications__products-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-size: 13px;
    font-weight: 500;
}

.user-notifications__products-header-name {
    width: 309px;
}

.user-notifications__products-header-date {
    width: 274px;
}

.user-notifications__products-header-tel {
    width: 240px;
}
.user-notifications__products-header-page {
    width: 110px;
}

.user-notifications__filter-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    font-size: 12px;
}

.user-notifications__filter-btn--wrap.MuiButtonBase-root {
    padding: 0 0;
}

.user-notifications__filter-btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #D8D8D8;
    padding: 2px 15px 2px 10px;
    width: 220px;
}

.user-notifications__select-wrap {
    
}

.user-notifications__select-wrap .MuiSelect-select {
    font-size: 14px !important;
    padding: 3.5px 9px !important;
}

.user-notifications__filter-btn-text {
    font-size: 13px;
    color: var(--dark-charcoal);
    text-transform: initial;
    font-family: 'Montserrat';
}

.user-notifications__notifications-text--wrap {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.user-notifications__notifications-text-date--mob,
.user-notifications__notifications-text-type--mob {

}

.user-notifications__notifications-text-date--des,
.user-notifications__notifications-text-type--des {
    display: none;
}

.user-notifications__notifications-btn-wrap {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}

.user-notifications__text-tab--wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    min-width: 175px;
    overflow: auto;
}

.user-notifications__text-tab-wrap::-webkit-scrollbar {
    height: 1px;
    color: #898989;
}

.user-notifications__text-tab {
    width: fit-content;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.user-notifications__text-tab-wrap {
    display: none;
    white-space: nowrap;
}

.user-notifications__notifications--unread {
    background: #89898926;
}

.user-notifications__notifications-btn-img {
    width: 18px;
    height: auto;
    fill: #19191D;
    transition: .3s;
}

.user-notifications__notifications-btn:hover .user-notifications__notifications-btn-img {
    fill: #fff;
}

.user-notifications__notifications-btn-img--mob {
    position: absolute;
    top: 15px;
    right: 15px;

}

@media (min-width: 400px) {
    .user-notifications__header-title-wrap {
        gap: 20px;
    }
}
@media (min-width: 567px) {
    .user-notifications__notifications-text-logo-wrap {
        flex-wrap: nowrap;
    }
}

@media (min-width: 768px) {
    .user-notifications__header {
        flex-wrap: nowrap;
        gap: 20px;
    }
    .user-notifications__notifications-text {
        display: block;
        width: 240px;
    }
    .user-notifications__notifications-text-message {
        display: -webkit-box;
    }
    .user-notifications__notifications-text-date--mob,
    .user-notifications__notifications-text-type--mob {
        display: none;
    }
    .user-notifications__notifications-text-date--des,
    .user-notifications__notifications-text-type--des {
        display: block;
        white-space: nowrap;
        width: 110px;
    }
    .user-notifications__notifications-text-logo-wrap {
        flex-wrap: nowrap;
        align-items: center;
    }
    .user-notifications__notifications {
        gap: 15px;
        padding: 10px 15px;
    }
    .user-notifications__text-tab--wrap::-webkit-scrollbar {
        height: 1px;
        background: #898989;
    }
    .user-notifications__header {
        width: calc(100% + 15px);
        margin-left: 0;
    }
    .user-notifications__filter {
        width: calc(100% - 15px);
        margin-left: 15px;
    }
    .user-notifications__notifications--wrap {
        padding-left: 15px;
        overflow: auto;
    }
    .user-notifications__notifications-text-wrap {
        flex-wrap: nowrap;
    }
    .user-notifications__notifications {
        width: auto;
        flex: 1 1;
        min-width: 970px;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .user-notifications__notifications-btn-wrap {
        width: fit-content;
    }
    .user-notifications__notifications-btn {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .user-notifications__notifications-text-name-wrap {
        max-width: 240px;
        min-width: 240px;
    }
    .user-notifications__notifications-wrap {
        min-width: 1012px;
    }
    .user-notifications__products-header--wrap {
        display: block;
        min-width: 1012px;
    }
    .user-notifications__select-wrap {
        display: none !important;
    }
    .user-notifications__text-tab-wrap {
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
    .user-notifications__text-tab--wrap {
        justify-content: flex-start;
        transform: translateY(16px);
    }
    .user-notifications__notifications-btn-img--mob {
        display: none;
    }
}
@media (min-width: 1200px) {
    .user-notifications__header {
        padding: 20px 30px;
    }
    .user-notifications__text-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
    .user-notifications__products-header-date {
        width: 298px;
    }
    .user-notifications__products-header-tel {
        width: 230px;
    }
    .user-notifications__products-header-page {
        width: 98px;
    }
}
@media (min-width: 1200px) {
    .user-notifications__notifications {
        gap: 40px;
    }
}    
.banner-result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0 45px;
    border-bottom: 1px solid #D8D8D8;
}

.banner-result__result-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.banner-result__result-del-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #D8D8D8;
}

.banner-result__img-result {
   max-height: 800px;
}

@media (min-width: 768px) {
    .banner-result {
        padding: 15px;
        border: 1px solid #E5E5E5;
    }
    .user-settings__result-img--wrap {
        gap: 30px;
    }
}


.vendor-view {
    font-family: Montserrat;
    color: var(--dark-charcoal);
    font-size: var(--text-size);
}

.vendor-view-wrap {
}

.vendor-view__img-wrap {
    position: relative;
    min-height: 100px;
}

.vendor-view__img-background {
    width: 100%;
    height: 170px;
}

.vendor-view__img-logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 148px;
    height: 148px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}

.vendor-view__rating--wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vendor-view__rating-number {
    font-size: 30px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 6px;
    width: fit-content !important;
}

.vendor-view__rating-wrap {
    justify-content: flex-start;
    margin-bottom: 5px;
    width: fit-content !important;
}

.vendor-view__rating {
}

.vendor-view__rating .MuiRating-decimal {
    color: var(--dark-charcoal);
    font-size: 25px;
}

.vendor-view__rating-text {
    position: relative;
    display: inline-block;
    line-height: 150%;
    font-size: var(--text-size);
}

.vendor-view__rating-text::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 3px;
    border-top: 1px solid;
    width: 100%;
}

.vendor-view__text-wrap {
    margin-top: 14px;
    padding: 0 10px;
    margin-bottom: 60px;
}

.vendor-view__text-title-wrap {
    margin-top: 90px;
    margin-bottom: 20px;
    padding: 0 10px;
}

.vendor-view__text-title {
    color: var(--dark-charcoal);
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-weight: 500;
}

.vendor-view__text-sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    text-align: center;
    font-family: Montserrat;
}

.vendor-view__text-tab--wrap {
    display: none;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.vendor-view__text-tab-wrap {
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 44px;
    max-width: 1300px;
    border-bottom: 1px solid #D8D8D8;
    margin-left: auto;
    margin-right: auto;
}

.vendor-view__text-tab {
    position: relative;
    width: 33.5%;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.vendor-view__text-tab::before {
    display: none;
    content: '';
    position: absolute;
    bottom: -2.6px;
    left: 0;
    border-bottom: 2px solid var(--main-green);
    width: 100%;
    z-index: 2;
}

.vendor-view__text-tab:hover::before {
    display: block;
}

.vendor-view__text-tab-active {
    position: relative;
    color: var(--main-green);
    z-index: 2;
    font-weight: 500;
    font-family: Montserrat;
}

.vendor-view__text-tab-active::after {
    content: '';
    position: absolute;
    bottom: -2.6px;
    left: 0;
    border-bottom: 2px solid var(--main-green);
    width: 100%;
    z-index: 2;
}

.vendor-view__text-modal-tab-btn-close-wrap {
    padding: 8px;
}

.vendor-view__text-modal-tab-btn-close {
    width: 12px;
    height: 12px;
    margin-left: auto;
    cursor: pointer;
}

.vendor-view__text-tab-1 {
    position: relative;
    display: flex;
    flex-direction: column;
    line-height: 1.55;
    overflow: auto;
    font-family: Montserrat;
    padding: 15px 10px 30px;
    font-size: 15px;
    font-weight: 400;
    z-index: 2;
    height: calc(100vh - 137px);
    white-space: pre-wrap
}

.vendor-view__text-tab-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-family: Montserrat;
    padding: 15px 10px 30px;
    z-index: 2;
    height: calc(100vh - 137px);

}

.vendor-view__text-tab-2-item {
    display: flex;
    gap: 20px;
    font-family: Montserrat;
    width: 100%;
    max-width: 445px;
}

.vendor-view__text-tab-2-item-img {
    width: 45px;
    min-width: 45px;
    height: 45px;
}

.vendor-view__text-tab-2-item-text-wrap {
    line-height: 1.55;
    font-family: Montserrat;
}

.vendor-view__text-tab-2-item-text-title {
    margin-bottom: 15px;
    margin-top: 12px;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 15px;
}

.vendor-view__text-tab-2-item-text-li {
    list-style-type: disc;
    margin-left: 20px;
    font-family: Montserrat;
    max-width: 360px;
    font-size: 15px;
}

.vendor-view__text-tab-3 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    font-family: Montserrat;
    padding: 15px 10px 30px;
    z-index: 2;
    height: calc(100vh - 137px);
}

.vendor-view__text-tab-3-info {
    width: 100%;
    line-height: 1.55;
    font-family: Montserrat;
}

.vendor-view__text-tab-3-info-title {
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat;
}

.vendor-view__text-tab-3-info-text {
    margin-bottom: 30px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
}

.vendor-view__text-tab-3-info-title-addres {
    margin-bottom: 30px;
}

.vendor-view__text-tab-3-input-wrap {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    gap: 25px;
    width: 100%;
    max-width: 500px;
}

.vendor-view__text-tab-3-input {
    height: 60px;
    font-family: Montserrat;
    padding: 0 20px;
    line-height: 1.33;
    width: 100%;
    font-size: 15px;
    border: 1px solid #c7c7c7;
    border-radius: 0;
    --webkit-border-radius: 0;
}

.vendor-view__text-tab-3-input::placeholder {
    font-size: 15px;
}

.vendor-view__text-tab-3-textarea {
    padding: 17px 20px 0;
    line-height: 1.33;
    font-family: Montserrat;
    width: 100%;
    border: 1px solid #c7c7c7;
    outline: none;
    font-size: 15px;
    border-radius: 0;
    resize: none;
    --webkit-border-radius: 0;
}
.vendor-view__text-tab-3-textarea::placeholder {
    font-size: 15px;
}

.vendor-view__text-tab-3-textarea:focus-visible {
    border: 1px solid #c7c7c7;
}

.vendor-view__text-tab-3-btn-wrap {
    margin-left: auto;
    margin-right: auto;
}

.vendor-view__text-tab-3-btn {
    height: 60px;
    font-size: 15px;
}

.vendor-view__products-title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 32px;
    font-family: Montserrat;
    text-align: center;
}

.vendor-view__products {
    display: flex;
    gap: 2px;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1;
    width: calc(100% + 10px);
    margin-left: -4px;
}

.vendor-view__product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
    width: 50%;
    max-width: 49.5%;
    padding: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: .3s;
}

.vendor-view__product:hover {
    border: 1px solid var(--main-green);
}

.vendor-view__banner-wrap {
    margin-top: 30px;
}

.vendor-view__products-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.vendor-view__text-mobile-tab-wrap {
    margin-top: 20px;
}

.vendor-view__text-mobile-tab-btn {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16.6px 0;
    border-bottom: 1px solid #D8D8D8;
}

.vendor-view__text-mobile-tab-btn-text {
    color: var(--dark-charcoal);
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    font-family: Montserrat;
    font-weight: 600;
}

.vendor-view__text-mobile-tab-btn-text-img-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
}

.vendor-view__text-modal-tab-btn-close {
    width: 12px;
    height: 12px;
    margin-left: auto;
    cursor: pointer;
}

.vendor-view__text-tab-is-safari {
    padding-bottom: 110px;
    background: #fff;
}

.vendor-view__modal-wrap {
    position: fixed;
    top: 88px;
    z-index: 26;
    width: 100%;
    height: calc(100% - 88px);
}

.vendor-view__text-modal-tab-wrap {
    position: relative;
    z-index: 6;
    background: #fff;
    overflow: hidden;
}

.vendor-view__modal-form--wrap {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
}

.vendor-view__text-modal-tab-title-wrap {
    border-bottom: 1px solid #D8D8D8;
    display: flex;
    gap: 10px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 20px 10.5px 10px;
}

.vendor-view__text-tab-1-conditions_delivery {
    font-size: 15px;
    margin: 10px 0;
}

@media (min-width: 480px) {
    .vendor-view__products-title {
        font-size: 24px;
    }
    .vendor-view__product {
        max-width: 49.71%;
    }
}
@media (min-width: 640px) {
    .vendor-view__img-background {
        height: 342px;
    }
    .vendor-view__text-title {
        font-size: 32px;
    }
    .vendor-view__text-sub-title {
        font-size: 14px;
    }
    .vendor-view__text-tab-1 {
        font-size: 15px;
        border: none;
        height: auto;
        padding: 30px 0 0;
    }
    .vendor-view__text-tab-2 {
        border: none;
        height: auto;
        padding: 30px 0 0;
        gap: 30px;
    }
    .vendor-view__text-tab-3 {
        border: none;
        height: auto;
        padding: 30px 0 0;
    }
    .vendor-view__text-tab-2-item-text-title {
        font-size: 15px;
        margin-top: 0;
        line-height: 1.17;
        margin-bottom: 8px;
    }
    .vendor-view__text-tab-2-item-text-li { 
        font-size: 15px;
        width: max-content;
        max-width: none;
    }
    .vendor-view__text-mobile-tab-wrap {
        display: none;
    }
    .vendor-view__text-tab--wrap {
        display: block;
    }
    .vendor-view__products-title {
        margin-top: 60px;
    }
    .vendor-view__text-title-wrap {
        padding: 0 15px;
    }
    .vendor-view__text-wrap {
        padding: 0 15px;
    }
    .vendor-view__text-tab {
        font-size: 15px;
    }
    .vendor-view__text-tab-wrap {
        margin-bottom: 0;
        border-top: none;
    }
    .vendor-view__text-tab-2-item {
        max-width: none;
    }
}
@media (min-width: 760px) {
    .vendor-view__product {
        width: 25%;
        max-width: 24.79%;
    }
}
@media (min-width: 960px) {
    .vendor-view__text-tab-3 {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .vendor-view__text-tab-3-info {
        text-align: start;
        width: 49%;
    }
    .vendor-view__text-tab-3-input-wrap {
        width: 49%;
    }
    .vendor-view__product {
        width: 25%;
        max-width: 24.84%;
    }
    .vendor-view__text-tab-1 {
        max-width: 90%;
    }
}
@media (min-width: 1200px) {
    .vendor-view__text-title {
        font-size: 34px;
    }
    .vendor-view__text-sub-title {
        font-size: 16px;
    }
    .vendor-view__text-tab-1 {
        font-size: 15px;
        padding: 35px 0 0;
    }
    .vendor-view__text-tab-3,
    .vendor-view__text-tab-2 {
        padding: 35px 0 0;
    }
    .vendor-view__text-tab-2-item-text-title {
        font-size: 15px;
    }
    .vendor-view__products-title {
        font-size: 25px;
        margin-top: 80px;
    }
    .vendor-view__text-wrap {
        padding: 0 30px;
    }
}
@media (min-width: 1680px) {
    .vendor-view__img-background {
        height: 400px;
    }
    .vendor-view__img-logo {
        width: 160px;
        height: 160px;
    }
    .vendor-view__text-tab {
        font-size: 16px;
    }
    .vendor-view__products {
        max-width: 1640px;
        margin-left: auto;
        margin-right: auto;
    }
    .vendor-view__product {
        padding: 6px;
    }
}
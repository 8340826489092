.select-characteristic {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    position: relative;
}

.select-characteristic__input {
    min-width: 240px;
    max-width: none !important;
}

.select-characteristic__result-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;    
}

.select-characteristic__characteristics-delete-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.select-characteristic__result {
    display: flex;
    align-items: center;
    transition: .3s;
}

.select-characteristic__result:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.select-characteristic__accordion-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
}

.select-characteristic__input-characteristics-lable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-charcoal);
}

.select-characteristic__input-characteristics-lable-text {
    min-width: 280px;
}

.select-characteristic__select-mark--wrap {
    min-width: 300px;
    border-radius: 0 !important;
    width: 100%;
    min-height: 40.4px;
    font-size: 15px !important;
}

.select-characteristic__select-mark--wrap .MuiSelect-select {
    color: var(--dark-charcoal);
    padding: 8.7px 32px 8.7px 14px !important;
    white-space: normal !important;
}

.select-characteristic__select-mark--wrap .MuiOutlinedInput-notchedOutline {
    border-color: #89898999 !important;
}    

.select-characteristic__delete-btn-img {
    width: 20px;
    height: 20px;
}

.select-characteristic__add-btn-owner-select {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.select-characteristic__accordion-select--wrap {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.select-characteristic__accordion-select-wrap {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.select-characteristic__add-btn-owner-select-active {
    pointer-events: none;
}

.select-characteristic__add-result-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 16px;
}

.select-characteristic__add-result {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 6px 10px 6px 15px;
    background: #EDEDF0;
    border-radius: 45px;
}

.select-characteristic__add-result-del-words-btn {
    width: 8px;
    height: 8px;
}

.select-characteristic__add-result--wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.select-characteristic__add-btn--wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.select-characteristic__select-mark--wrap .css-14lo706 {
    display: none;
}

.select-characteristic__select-mark-item {
    transition: .3s;
}

.select-characteristic__select-mark-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.select-characteristic__select-mark-item.Mui-selected {
}

.select-characteristic__select-mark--few_values {
    height: auto !important;
    min-height: 40.4px;
    width: 100%;
    border-radius: 0 !important;
    font-size: 15px !important;
}

.select-characteristic__select-mark--few_values .MuiSelect-select {
    padding: 8.7px 32px 8.7px 14px !important;
    white-space: wrap !important;
    -webkit-white-space: wrap !important;
}

.select-characteristic__input-characteristics-lable-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.select-characteristic__characteristics-delete-btn--desk {
    display: none;
}

.select-characteristic__add-btn-owner-select--desk {
    display: none;
}

.select-characteristic__add-btn--wrap--is-open-menu {
}

.select-characteristic__select-mark-placeholder-wrap {
    position: relative;
    min-width: 300px;
    border-radius: 0 !important;
    width: 100%;
}

.select-characteristic__select-mark-placeholder-wrap .MuiSelect-select {
    color: var(--dark-charcoal);
    padding: 8.7px 32px 8.7px 14px !important;
}

.select-characteristic__select-mark-placeholder-wrap .MuiOutlinedInput-notchedOutline {
    border-color: #89898999 !important;
}   

.select-characteristic__select-mark-placeholder {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 15px;
}

@media (min-width: 600px) {
    .select-characteristic__select-mark-placeholder-wrap,
    .select-characteristic__select-mark--wrap {
        max-width: calc(100% - 260px);
    }
    .select-characteristic__add-btn--wrap {
        max-width: 240px;
    }
    .select-characteristic__input {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
 
    .select-characteristic {
        padding: 0 10px 0 0;
    }
    .select-characteristic__add-btn--wrap--is-open-menu {
        max-width: 300px;
    }
}
@media (min-width: 831px) {
    .select-characteristic__add-btn--wrap--is-open-menu {
        max-width: 240px;
    }
}
@media (min-width: 870px) {
    .select-characteristic__input-characteristics-lable-btn-wrap {
        display: none;
    }
    .select-characteristic__characteristics-delete-btn--desk {
        display: block;
    }
    .select-characteristic__accordion-select-wrap {
        flex-wrap: nowrap;
    }
    .select-characteristic__characteristics-delete-btn--desk {
        display: block;
    }
    .select-characteristic__add-btn-owner-select--desk {
        display: block;
    }
}
@media (min-width: 1170px) {
    .select-characteristic {
        flex-wrap: nowrap;
    }
    .select-characteristic__input-characteristics-lable {
        max-width: 280px;
    }
}
.notifications-read {
    font-size: 14px;
    color: #19191D;
}

.notifications-read__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.notifications-read__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.notifications-read__header-title-img {
    width: 24px;
    height: 24px;
}

.notifications-read__header-title {
    font-weight: 500;
    font-size: 16px;
}

.notifications-read__header-btn--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.notifications-read__btn-wrap {
}

.notifications-read__btn {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px;
    width: calc(100% + 30px);
    border-bottom: 2px solid #D8D8D8;
    margin-left: -15px;
}

.notifications-read__btn-img {
    width: 24px;
    height: 24px;
    opacity: .5;
}

.notifications-read__btn-text {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.notifications-read__btn-text-name {
    font-weight: 600;
}

.notifications-read__btn-text-tel {
    color: #898989;
}

.notifications-read__main {
    padding: 22px 0;
    
}
.notifications-read__main-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    border: 1px solid #D8D8D8;
    padding: 19px 24px;
}

.notifications-read__main-text-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 150px;
}

.notifications-read__main-title {
    font-size: 13px;
    font-weight: 500;
}

.notifications-read__main-text {
    font-size: 14px;
    line-height: 1.5;
}

.notifications-read__message-wrap {
    display: flex;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    padding: 20px 24px;
    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
}

.notifications-read__mail--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notifications-read__mail-img {
    width: 21px;
    height: 16px;
}

.notifications-read__mail {

}

.notifications-read__tel--wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notifications-read__tel-img {
    width: 13px;
    height: 13px;
}

.notifications-read__tel {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.notifications-read__main-text-message-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.notifications-read__main-text-img-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.notifications-read__main-text-img {
    max-width: 224px;
    height: auto;
    aspect-ratio: 1/1;
    margin-top: 5px;
    object-fit: contain;
}

.notifications-read__main-title-wrap {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .notifications-read__main {
        padding: 22px 0 22px 15px;
    }
    .notifications-read__header {
        width: calc(100% + 15px);
        margin-left: 0;
    }
    .notifications-read__btn {
        width: calc(100% + 15px);
        margin-left: 0;
    }
}
@media (min-width: 1200px) {
    .notifications-read__header {
        padding: 20px 30px;
    }
    .notifications-read__main {
        padding: 22px 15px 22px 30px;
    }
    .notifications-read__btn {
        padding: 15px 30px;
    }   
    .notifications-read__main-wrap {
        flex-direction: row;
        gap: 30px;
    }
    .notifications-read__main-text-wrap {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    .notifications-read__main-title {
        font-size: 14px;
    }
}
.creating-category {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: #19191D;
    padding-bottom: 20px;
}

.creating-category__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    min-height: 83.6px;
    padding: 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-bottom: 2px solid #D8D8D8;
}

.creating-category__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.creating-category__header-title-img {
    width: 24px;
    height: 24px;
}

.creating-category__header-title {
    font-weight: 500;
    font-size: 16px;
}

.creating-category__header-btn-wrap {
    margin-left: auto;
}

.creating-category__header-btn--wrap {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
}

.creating-category__header-btn {
    display: flex;
    align-items: center; 
    gap: 13px;
    background: #2A7F77;
    color: #fff;
    margin-left: auto;
    width: fit-content;
    min-width: 207px;
    padding: 11px 10px 11px 10px;
    white-space: nowrap;
    font-weight: 500;
}

.creating-category__header-btn-img {
    width: 18px;
    height: 18px;
}

.creating-category__tab-0-wrap {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;
    flex-wrap: wrap;
    padding: 22px 0 0;
}

.creating-category__tab-1-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 23px;
    flex-wrap: wrap;
    padding: 22px 0 0;
}

.creating-category__input-wrap {
    display: flex;
    gap: 23px;
    flex-wrap: wrap;
}

.creating-category__input--wrap {

}

.creating-category__input-lable {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    width: fit-content;
}

.creating-category__input {
    max-width: 500px;
    width: 100%;
    border: 1px solid #89898999;
    padding: 11px 23px 11px 11px;
    border-radius: 0;
    color: var(--dark-charcoal);
    font-size: 14px;
    maximum-scale: 1;
    --webkit-maximum-scale: 1;
    user-scalable: no;
    --webkit-user-scalable: no;
}

.creating-category__input::placeholder {
    font-size: 12px;
    user-scalable: no;
    --webkit-user-scalable: no;
}

.creating-category__input:focus {
    border: 1px solid #2A7F77;
}

.creating-category__textarea {
    max-width: 500px;
    width: 100%;
    border: 1px solid #89898999;
    padding: 11px;
    font-family: 'Montserrat';
    outline: none;
    color: var(--dark-charcoal);
    border-radius: 0;
    font-size: 14px;
}

.creating-category__textarea::placeholder {
    font-size: 12px;
}

.creating-category__textarea:focus-visible {
    border: 1px solid #2A7F77;
}

.creating-category__input-sub-title {
    font-size: 11px;
    color: #898989;
    margin-top: 8px;
    max-width: 500px;
}

.creating-category__input-count {
    max-width: 150px;
    width: 100%;
}

.creating-category__add-btn-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    border-top: 1px solid #D8D8D8;
}

.creating-category__add-btn--wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 500px;
}

.creating-category__add-btn {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    cursor: pointer;
    z-index: 2;
}

.creating-category__add-result-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.creating-category__add-result {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 6px 10px 6px 15px;
    background: #EDEDF0;
    border-radius: 45px;
    font-size: 14px;
}

.creating-category__add-result-del-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.creating-category__add-result-del-btn {
    width: 24px;
    height: 24px;
}

.creating-category__add-result-del-btn--edite {
    width: 22px;
    height: 22px;
}

.creating-category__add-result-del-words-btn {
    width: 8px;
    height: 8px;
}

.creating-category__characteristics-result-wrap {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 40px;
}

.creating-category__characteristics-result-name-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    font-size: 14px;
}

.creating-category__characteristics-result-name {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.creating-category__characteristics-result--wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 14px;
    border-bottom: 1px solid #D8D8D8;
}

.creating-category__characteristics-result-characteristics-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
}

.creating-category__characteristics-result {
    display: flex;
    align-items: center;
    gap: 10px;
}

.creating-category__create-img--wrap {
    flex: 1 1;
    display: flex;
    align-items: center;
    min-width: 350px;
    gap: 10px;
    padding-top: 25px;
    max-width: 500px;
}

.creating-category__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 230px;
    text-align: center;
    padding: 5px 5px;
}

.creating-category__create-img-text-wrap {
    position: relative;
    font-size: 14px;
}

.creating-category__create-img-text-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    background: #F2F4F7;
}

.creating-category__create-img-text-img {
    width: 20px;
    height: 20px;
}

.creating-category__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.creating-category__create-img-link-upload-lable {
    color: #2A7F77;
    cursor: pointer;
    font-weight: 600;
}

.creating-category__upload-img-result {
    width: 120px;
    aspect-ratio: 1/1;
    object-fit: contain; 
}

.creating-category__upload-result-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 350px; 
}

.creating-category__upload-result-del-btn {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
}

.creating-category__accordion {
    
}

.creating-category__accordion .MuiButtonBase-root {
    padding: 0;
    min-height: 40px !important;
}

.creating-category__accordion .Mui-expanded {
    display: flex;
    align-items: center;
    margin: 0 !important;
}

.creating-category__accordion .MuiAccordionDetails-root {
    padding: 8px 0;
}

.creating-category__accordion.MuiButtonBase-root {
    border-bottom: 1px solid #D8D8D8;
    padding: 0;
}

.creating-category__accordion .MuiTypography-root {
    display: flex;
    align-items: center;
    font-family: 'Montserrat' !important;
    font-size: 13px;
}

.creating-category__accordion .MuiAccordionSummary-content {
    margin: 0;
}

.creating-category__accordion.MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: none;
    margin: 0 !important;
    width: 100%;
}

.creating-category__accordion.MuiPaper-root::before {
    display: none;
}

.creating-category__produc-accordion-arrow {
    transform: rotate(90deg);
    width: 20px;
    height: 21px;
}

.creating-category__accordion-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.creating-category__characteristic-add-result {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 6px 10px 6px 15px;
    background: #EDEDF0;
    border-radius: 7px;
    font-size: 14px;
}

.creating-category__select-tab {
    width: 207px;
    height: 35px;
    border-radius: 0 !important;
}

.creating-category__select-tab.MuiInputBase-root {
    font-size: 14px !important;
}   

.select-characteristic__add-result-error {
    color: #898989;
    font-size: 13px;
}

.creating-category__text-tab--wrap {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.creating-category__text-tab {
    white-space: nowrap;
    padding-bottom: 29px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
}

.creating-category__text-tab-wrap {
    display: none;
    width: fit-content;
}

.creating-category__header-btn-save-wrap {
    margin-right: 0;
}

.creating-category__header-btn-save {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 11px 27px 11px 20px;
    color: #fff;
    white-space: nowrap;
    font-weight: 500;
}

.creating-category__select-tab-item {
    background: #fff !important;
}

.creating-category__select-tab-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-category__input-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.creating-category__characteristics-result-name-title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.creating-category__characteristics-is-hide {
    opacity: .6;
}

.creating-category__add-characteristics-wrap {
    max-width: 500px;
    width: 100%;
}

.creating-category__characteristics-is-focus {
    color: var(--main-green);
}

.creating-category__input-title {
    font-size: 16px;
    margin-bottom: 40px;
    font-weight: 600;
}

.creating-category__add-seo-wrap {
    display: flex;
    flex-direction: column;
}

.creating-category__input-lable-add {
    font-weight: 500;
}

.creating-category__characteristics-result-name--sub {
    margin-bottom: 7px;
}

.creating-category__products-filter-label {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
}

.catalog-view__popover-img {
    width: 20px;
    height: auto;
    cursor: pointer;
    opacity: 1 !important;
}

.catalog-view__popover-text {
    padding: 10px;
    font-size: 13px;
    font-family: 'Montserrat' !important;
}

.catalog-view__products-filter-checkbox-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    flex-wrap: wrap;
}

.catalog-view__popover-text-wrap {
    width: max-content;
    background: #fff;
    transform: translateY(15px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.creating-category__modal-characteristics-name {
    margin-bottom: 15px;
}

.creating-category__modal-error-name {
    margin-bottom: 8px;
    line-height: normal;
    color: red;
}

.creating-category__input-error-text {
    color: red;
    font-size: 13px;
    font-weight: 400;
}

.creating-category__input--error {
    border: 1px solid red !important;
}

@media (min-width: 440px) {
    .catalog-view__products-filter-checkbox-wrap {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
}
@media (min-width: 453px) {
    .creating-category__header-btn {
        width: fit-content;
        min-width: 207px;
        padding: 11px 10px 11px 10px;
        white-space: nowrap;
    }
    .creating-category__header-btn-wrap {
        margin-right: 0;
    }
}
@media (min-width: 768px) {
    .creating-category__text-tab--wrap {
        justify-content: flex-start;
        transform: translateY(16px);
        overflow: auto;
    }
    .creating-category__text-tab--wrap::-webkit-scrollbar {
        height: 1px;
        background: #898989;
    }
    .creating-category__text-tab-wrap {
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
    .creating-category__header {
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 4px 15px 4px 15px;
        gap: 20px;
        height: 83.6px;
        margin-left: 0;
        width: calc(100% + 15px);
    }
    .creating-category__header-btn--wrap {
        gap: 5px;
    }
    .creating-category__tab-1-wrap,
    .creating-category__tab-0-wrap {
        padding: 22px 0 22px 15px;
    }
    .creating-category__input-sub-title {
        font-size: 13px;
    }
    .creating-category__upload-img-result {
        max-width: 180px;
        width: 100%;
    }
    .creating-category__create-img--wrap {
        flex-direction: column;
    }
    .creating-category__create-img-wrap {
        padding: 5px 10px;
        margin-bottom: 15px;
    }
    .creating-category__header-btn {
        min-width: 234px;
        margin-left: 0;
    }
    .creating-category__header-btn-wrap {
        width: min-content;
    }
    .creating-category__input::placeholder {
        font-size: 13px;
    }
    .creating-category__textarea::placeholder {
        font-size: 13px;
    }
    .creating-category__text-tab--save-wrap {
        transform: translateY(16px);
    }
    .creating-category__text-tab--save--wrap {
        padding-bottom: 29px;
        padding-top: 0;
    }
    .creating-category__header--save {
        height: 83.6px;
    }
}

@media (min-width: 1200px) {
    .creating-category__header {
        height: 83.6px;
        padding: 4px 30px;
    }
    .creating-category__header-btn--wrap {
        flex-wrap: nowrap;
    }
    .creating-category__text-tab--wrap {
        white-space: nowrap;
        margin-bottom: 4px;
        transform: translateY(17px);
    }
    .creating-category__text-tab {
        padding-bottom: 31px;
    }
    .creating-category__text-tab--save-wrap {
        transform: translateY(17px);
    }
}
@media (min-width: 1272px) {
    .creating-category__text-tab {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 1680px) {
    .creating-category__tab-0-wrap {
        gap: 70px;
    }
}
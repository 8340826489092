@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    overflow: auto;
    overscroll-behavior-y: none; 
    overflow-x: hidden;
}

body {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    position: relative;
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    padding: 0 !important;
    color: #19191D;
    touch-action: '';
    -ms-touch-action: '';
    -webkit-text-size-adjust: 100%;  
}

:root {
    --main-green: #2A7F77;
    --neutral-gray: #898989;
    --dark-charcoal: #19191D;
    --purplish-gray: #49454F;

    --main-title-size: 24px;         /* 480 = 26px  960 = 28px*/
    --medium-title-size: 20px;         /* 480 = 22px  960 = 24px*/
    --title-size: 18px;             /* 960 = 20px*/
    --medium-text-size: 16px;             /* 960 = 18px*/
    --big-text-size: 15px;             /* 640 = 16px*/
    --text-size: 14px;             /* 640 = 16px*/
}

.hidden {
    overflow: hidden;
}

.hiddenScroll {
    overflow: hidden;
    max-height: 100dvh;
}

*,
::after,
::before {
    box-sizing: border-box;
}
/* *:last-child {
margin-bottom: 0;
} */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: 'Montserrat',Arial,sans-serif;
}
ul,
ol,
li {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: 'Montserrat',Arial,sans-serif;
}
a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-family: 'Montserrat',Arial,sans-serif;
}
button,
label,
input {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Montserrat',Arial,sans-serif;
}
img,
svg {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

button:focus {
    outline: none;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 2560px;
}

.displayNone {
    display: none !important;
}

.disabledContainer {
    pointer-events: none;
    opacity: .6;
}

.requiredFields {
    position: relative;
}

.requiredFields::after {
    content: '*';
    position: absolute;
    right: -8px;
    top: -8px;
    color: red;
}

.color-text-red {
    color: red;
}

button {
    -webkit-tap-highlight-color: transparent;
}

.swiper-horizontal {
    touch-action: pan-y pinch-zoom !important;
}

.swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    will-change: filter;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
    -webkit-filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
}

.swiper-button-next::after {
    font-size: 16px !important;
    color: #000;
    margin-left: 3px;
    margin-top: 1px;
}

.swiper-button-prev {
    width: 40px !important; 
    height: 40px !important;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    will-change: filter;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
    -webkit-filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16));
}

.swiper-button-prev::after {
    font-size: 16px !important;
    color: #000;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2A7F77 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
}

.Mui-focused {
    color: #2A7F77 !important;
}

.Mui-selected {
    background-color: #d8d8d86e !important;
    font-family: 'Montserrat' !important;
}

.MuiSelect-select {
    font-family: 'Montserrat' !important;
    color: var(--dark-charcoal) !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #898989 !important;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2A7F77 !important;
    font-family: 'Montserrat' !important;
}

.MuiTypography-root,
.MuiButtonBase-root {
    font-family: 'Montserrat' !important;
}

.main-btn-1 {
    background: var(--main-green);
    font-size: 14px;
    color: #fff;
    height: 45px;
    font-weight: 500 !important;
    padding: 13.3px 30px;
    border: 1px solid var(--main-green);
    transition: .3s;
}

.main-btn-1:hover {
    background: #fff;
    color: var(--dark-charcoal);
} 

.main-btn-2 {
    padding: 13.3px 30px;
    font-weight: 500;
    font-size: 14px;
    height: 45px;
    border: 1px solid var(--main-green);
    background: #fff;
    color: var(--dark-charcoal);
    transition: .3s;
}

.main-btn-2:hover {
    background: var(--main-green);
    color: #ffffff;
}

.main-btn-3 {
    padding: 13.3px 30px;
    font-weight: 500;
    font-size: 14px;
    height: 45px;
    border: 1px solid var(--main-green);
    background: #fff;
    color: var(--dark-charcoal);
    transition: .3s;
}

.main-btn-3:hover {
    border: 1px solid #fff;
    background: var(--main-green);
    color: #ffffff;
}

.main-btn-4 {
    background: var(--main-green);
    font-size: 15px;
    color: #fff;
    height: 50px;
    font-weight: 500;
    padding: 13.3px 30px;
    border: 1px solid var(--main-green);
    transition: .3s;
}

.main-btn-4:hover {
    background: #fff;
    color: var(--dark-charcoal);
}

@media (min-width: 560px) {
    .main-btn-4 {
        font-size: 16px;
    }
}
@media (min-width: 640px) {
    :root {
        --text-size: 16px;
        --main-title-size: 26px;
        --title-size: 20px;
        --big-text-size: 16px;
    }
    .hiddenScroll {
        max-height: 100dvh;
    }
}

@media (min-width: 960px) {
    .MuiButtonBase-root,
    .Mui-selected {
        font-size: 14px !important;
    }
}
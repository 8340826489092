.partners-view {
    font-size: var(--text-size);
    color: #49454F;
}

.partners-view--wrap {
    color: var(--dark-charcoal);
}

.partners-view__title-wrap {
    position: relative;
    display: flex;
    padding-top: 53px;
    background-image: url('./../../assets/partnersMain.jpg');
    height: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
}

.partners-view__title---wrap {
    max-width: 1200px;
}
.partners-view__title--wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 280px;
    padding-left: 15px;
}

.partners-view__title-text {
    line-height: 1.3;
    font-size: var(--main-title-size);
    font-weight: 700;
    letter-spacing: 1px;
    max-width: 600px;
}

.partners-view__title-sub-text {
    font-size: 15px;
    line-height: 1.55;
    margin-bottom: 7px;
    color: #49454F;
    background: rgba(255, 255, 255, 0.70);
    width: 95%;
    font-weight: 400;
    padding: 6px;
}

.partners-view__title-arrow-btn {
    position: absolute;
    bottom: 40px;
    left: 47%;
    transform: translateX(-50%);
    width: 65px;
    height: 65px;
    animation: arrow-animation 1.5s ease-in-out infinite;
}

@keyframes arrow-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

.partners-view__title-arrow-btn-img {
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 26px;
    height: 26px;
    transform: rotate(-45deg);
}

.partners-view__info-wrap {
    background-color: var(--main-green);
}

.partners-view__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 60px 15px;
}

.partners-view__info-text-wrap {
    color: #ffffff;
    max-width: 500px;
}

.partners-view__info-text-title {
    font-size: var(--medium-title-size);
    font-weight: 700;
    margin-bottom: 20px;
}

.partners-view__info-text {
    line-height: 1.55;
    font-size: var(--text-size);
}

.partners-view__card-wrap {
    background-color: #fff;
}

.partners-view__card {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 1200px;
    padding: 60px 15px;
}

.partners-view__card-reverse-wrap {
    background-color: #e8e8e8;
    padding-top: 60px;
    padding-bottom: 60px;
}

.partners-view__card-reverse--white-wrap {
    background-color: #fff;
}

.partners-view__card-reverse {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 0 15px;
}

.partners-view__card-text-wrap {

}

.partners-view__card-text-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 45px;
    max-width: 539px;
    line-height: 1.17;
}

.partners-view__card-text {
    line-height: 1.55;
    opacity: .85;
    color: #49454F;
}

.partners-view__card-img-wrap {
}

.partners-view__card-img-slider-wrap {
    margin-bottom: 15px;
}
.partners-view__card-img-slider-1-wrap {
    margin-bottom: 45px;
}

.partners-view__card-img {
    object-fit: contain;
    object-position: top;
    margin-bottom: 45px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.partners-view__card-swiper {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.partners-view__card-swiper-img-wrap {
    height: auto;
}

.partners-view__card-swiper-img {
    cursor: zoom-in;
    object-position: left;
}

.partners-view__how-wrap {

}

.partners-view__how {
    padding: 60px 15px;
    max-width: 600px;
}

.partners-view__how-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 45px;
    line-height: 1.17;
}

.partners-view__how-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.partners-view__how-card {
    padding: 20px 20px 30px;
    background-color: rgb(42, 127, 119);
    color: #ffffff;
}

.partners-view__how-card-img {
    width: 65px;
    height: 65px;
    margin-bottom: 30px;
}

.partners-view__how-card-title {
    font-size: var(--medium-title-size);
    font-weight: 700;
    margin-bottom: 15px;
}

.partners-view__how-card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 1.55;
    padding-left: 15px;
    font-size: var(--text-size);
}

.partners-view__sales-wrap {
    padding-top: 60px;
    padding-bottom: 20px;
}

.partners-view__sales {
    padding: 0 15px;
}

.partners-view__sales-title {
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-bottom: 45px;
}

.partners-view__sales-text {
    line-height: 1.5;
    max-width: 760px;
    margin-bottom: 45px;
    color: #49454F;
}   

.partners-view__sales-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.partners-view__sales-item {
    max-width: 330px;
}

.partners-view__sales-item-img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
}

.partners-view__sales-item-text {
    line-height: 1.55;
    color: #49454F;
}

.partners-view__form-wrap {
    background-color: var(--main-green);
    color: #fff;
}

.partners-view__form {
    padding: 60px 15px;
}

.partners-view__form--wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
}

.partners-view__form-info {
    display: flex;
    gap: 45px;
    flex-direction: column;
    width: 100%;
}

.partners-view__form-info-title {
    font-weight: 700;
    font-size: var(--main-title-size);
}

.partners-view__form-info-text {
    line-height: 25px;
}

.partners-view__form-input-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
}

.partners-view__form-input {
    height: 50px;
    line-height: 1.33;
    width: 100%;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    font-size: var(--text-size);
    border-radius: 0;
    -webkit-border-radius: 0;
}

.partners-view__form-input::placeholder {
    color: rgb(147 190 186);
}
.partners-view__form-input:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #2A7F77 inset;
    caret-color: #fff;
}

.partners-view__form-input:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #2A7F77 inset;
    caret-color: #fff;
}

.partners-view__banner-wrap {
    
}

.partners-view__form-input-label {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
}

.partners-view__card-text-sub-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--dark-charcoal);
    margin-bottom: 8px;
    margin-top: 30px;
    line-height: 1.17;
}

.partners-view__card-swiper .swiper-pagination {
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    bottom: 10px !important;
}

.partners-view__card-swiper .swiper-pagination-bullet {
    position: relative;
}

.partners-view__card-swiper .swiper-pagination-bullet::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -6px;
    width: 20px;
    height: 20px;
}

.partners-view__card-text-sub-title-wrap {

}

.partners-view__card-img-1-wrap {
    display: none;
}

.partners-view__card-text-title-1 {
    display: none;
}

.partners-view__form-error-text {
    color: #ff7b7b;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.partners-view__form-error-text--phone {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ff7b7b;
    font-size: 13px;
}

.partners-view__form-error {
    border-bottom: 1px solid #ff7b7b !important;
    margin-bottom: 0 !important;
}

.partners-view__form-error-banner {
    margin-bottom: 20px;
}

@media (min-width: 450px) {
    .partners-view__title--wrap {
        height: 325px;
    }
    .partners-view__title-sub-text {
        width: 80%;
    }
}
@media (min-width: 640px) {
    .partners-view__title-text {
        line-height: 43px;
        font-size: 30px;
    }
    .partners-view__how-card {
        padding: 30px 30px 40px;
        max-width: 600px;
    }
    .partners-view__title-sub-text {
        max-width: 635px;
        font-size: 16px;
    }
    .partners-view__info {
        padding: 60px 65px;
    }
    .partners-view__card-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
    }
    .partners-view__card-text-sub-title-wrap {
        width: 48%;
    }
    .partners-view__card-text-sub-title {
        margin-top: 15px;
        min-height: 42px;
        margin-bottom: 0;
    }
    .partners-view__how {
        max-width: 100%;
    }
    .partners-view__form-info-text {
        max-width: 760px;
    }
    .partners-view__sales-items {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 24px;
    }
    .partners-view__sales-item {
        width: 48%;
        margin-bottom: 20px;
        max-width: 100%;
    }
    .partners-view__sales-wrap {
        padding-bottom: 0;
    }
}
@media (min-width: 960px) {
    .partners-view__title--wrap {
        margin-left: 0;
    }
    .partners-view__info {
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        padding: 70px 15px;
        gap: 45px;
    }
    .partners-view__info-text-wrap {
        width: 49%;
    }
    .partners-view__card {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        gap: 20px;
    }
    .partners-view__card-text-wrap {
        width: 39%;
    }
    .partners-view__how {
        max-width: 1200px;
        padding: 70px 15px;
    }
    .partners-view__how-cards {
        flex-direction: row;
        align-items: unset;
        justify-content: center;
    }
    .partners-view__how-card {
        width: 48%;
        max-width: 460px;
    }
    .partners-view__sales {
        max-width: 1200px;
    }
    .partners-view__sales-items {
        flex-direction: row;
        justify-content: space-between;
    }
    .partners-view__sales-item {
        width: 23%;
        margin-bottom: 30px;
    }
    .partners-view__form--wrap {
        flex-direction: row;
        align-items: flex-start;
    }
    .partners-view__form-info {
        align-items: flex-start;
        width: 49%;
    }
    .partners-view__form-input-wrap {
        width: 50%;
    }
    .partners-view__form-info-text {
        text-align: start;
    }
    .partners-view__main-text {
        text-align: start;
    }
    .partners-view__form {
        max-width: 1200px;
        padding: 70px 15px;
    }
    .partners-view__card-img-1-wrap {
        display: block;
        width: 59%;
    }
    .partners-view__card-img-wrap {
        display: none;
    }
    .partners-view__card-text-title-1 {
        display: block;
        font-size: var(--main-title-size);
        font-weight: 700;
        padding: 70px 15px 45px;
        line-height: 1.17;
    }
    .partners-view__card-text-title {
        display: none;
    }
    .partners-view__card-text-sub-title-wrap {
        width: 100%;
    }
    .partners-view__card-wrap {
        padding-bottom: 70px;
    }
    .partners-view__card-text-sub-title {
        margin-top: 0;
        min-height: auto;
        margin-bottom: 8px;
    }
    .partners-view__slider-wrap {
        display: block;
        width: 59%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .partners-view__card-reverse {
        gap: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .partners-view__card-reverse-wrap {
        padding-top: 0;
        padding-bottom: 70px;
    }
    .partners-view__card-slider-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-top: 70px;
    }
    .partners-view__card-1-slider-wrap {
        flex-direction: row-reverse;
    }
    .partners-view__card-slider-1-wrap {
        display: block;
        width: 59%;
        margin-bottom: 0;
    }
    .partners-view__card-img {
        margin-bottom: 0;
    }
    .partners-view__sales-wrap {
        padding-top: 70px;
    }
}
@media (min-width: 980px) {
    .partners-view__title-sub-text {
        font-size: 18px;
    }
    .partners-view__title-wrap {
        height: 600px;
    }
    .partners-view__title-sub-text {
        width: 45%;
        background: inherit;
    }
}
@media (min-width: 1080px) {
    .partners-view__title-sub-text {
        width: 49%;
    }
}
@media (min-width: 1200px) {
    .partners-view__info {
        padding: 70px 30px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
    }
    .partners-view__title-wrap {
        padding-top: 0;
        background-image: url('./../../assets/partnersMain.jpg');
    }
    .partners-view__title-sub-text {
        background: inherit;
        margin-bottom: 0;
    }
    .partners-view__title---wrap {
        display: flex;
        align-items: center;
    }
    .partners-view__title--wrap {
        height: 295px;
    }
    .partners-view__card-text-title-1 {
        padding: 70px 30px 45px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
    .partners-view__card {
        padding: 0 30px;
    }
    .partners-view__card-reverse {
        padding: 0 30px;
    }
    .partners-view__how {
        padding: 70px 30px;
    }
    .partners-view__how-card-text {
        line-height: 27.9px;
        gap: 5px;
    }
    .partners-view__how-card {
        max-width: 560px;
    }
    .partners-view__form {
        padding: 70px 30px;
    }
    .partners-view__sales {
        padding: 0 30px;
    }
    .partners-view__how-cards {
        gap: 30px;
    }
    .partners-view__title-sub-text {
        width: 54%;
        max-width: 630px;
    }
    .partners-view__title-text {
        max-width: 630px;
    }
    .partners-view__info-text-title {
        font-size: 22px;
    }
    .partners-view__info-text {
        font-size: 18px;
    }
}
@media (min-width: 1500px) {
    .partners-view__title-wrap {
        height: 650px;
    }
}
@media (min-width: 1680px) {
    .partners-view__title---wrap {
        max-width: 1500px;
    }
    .partners-view__info {
        max-width: 1500px;
        justify-content: space-between;
    }
    .partners-view__card-text-title-1 {
        max-width: 1500px;
    }
    .partners-view__card {
        max-width: 1500px;
    }
    .partners-view__card-reverse {
        max-width: 1500px;
    }
    .partners-view__how {
        max-width: 1500px;
    }
    .partners-view__form {
        max-width: 1500px;
    }
    .partners-view__sales {
        max-width: 1500px;
    }
    .partners-view__title-sub-text {
        max-width: 738px;
    }
    .partners-view__title-text {
        max-width: 673px;
    }
    .partners-view__title--wrap {
        height: 230px;
        gap: 30px;
    }
    .partners-view__info-text-wrap {
        max-width: 650px;
    }
}
.sign-in {
    position: relative;
    margin: 0 auto;
    width: 100%;
    font-size: var(--text-size);
}

.sign-in-wrap {
}

.sign-in__form-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-in__form {
    padding: 20px;
}

.sign-in__form-input-wrap {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
}

.sign-in__form-title {
    display: flex;
    justify-content: center;
    color: var(--main-green);
    font-weight: 700;
    font-size: larger;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.sign-in__btn {
    width: 100%;
    font-size: var(--text-size);
    background: var(--main-green);
    border: 1px solid var(--main-green);
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    padding: 15px 0;
    outline: none;
    margin-top: 15px;
    margin-bottom: 40px;
    font-weight: 500;
    transition: .3s;
}

.sign-in__btn:hover {
    color: #000;
    background-color: #fff;
}

.sign-in__form-input {
    width: 100%;
}
 
@media (min-width: 768px) {
    .sign-in-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sign-in__form {
        padding: 50px;
    }
    .sign-in__form-wrap {
        width: 49%;
    }
}
.user-products-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.user-products-filter__filter-lable {
    font-size: 13px;
    color: var(--dark-charcoal);
}

.user-products-filter__search-wrap {
    position: relative;
    display: flex;
    align-items: center;
    height: 33.6px !important;
}

.user-products-filter__search-input {
    max-width: 160px;
    height: 33.6px !important;
    border: 1px solid #D8D8D8;
    padding: 8.6px 22px 8.5px 30px;
    border-radius: 0;
    --webkit-border-radius: 0;  ;
}

.user-products-filter__search-img {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.user-products-filter__select-wrap {
    display: flex;
    flex-direction: column;
    gap: 3px;
    min-width: 190px;
    max-width: 290px;
    color: #6B7A99;
    font-family: 'Montserrat'
}

.user-products-filter__select-sort-price-wrap {
    display: flex;
    flex-direction: column;
    gap: 3px;
    min-width: 190px;
    max-width: 350px;
    color: #6B7A99;
    font-family: 'Montserrat'
}

.user-products-filter__select-wrap .rdrMonth {
    width: auto;
}
.user-products-filter__select-wrap .rdrDayInPreview,
.user-products-filter__select-wrap .rdrDayEndPreview,
.user-products-filter__select-wrap .rdrDayStartPreview,
.user-products-filter__select-wrap .rdrEndEdge,
.user-products-filter__select-wrap .rdrStartEdge,
.user-products-filter__select-wrap .rdrInRange {
    color: #2A7F77 !important;
}

.user-products-filter__filter-btn--wrap {
    height: 33.6px !important;
}

.user-products-filter__filter-btn--wrap.MuiButtonBase-root {
    padding: 0 0;
}

.user-products-filter__filter-btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #D8D8D8;
    padding: 2px 15px 2px 10px;
    min-width: 160px;
    width: 100%;
    height: 33.6px;
}

.user-products-filter__filter-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    font-size: 12px;
}

.user-products-filter__filter-btn-img {
    width: 28px;
    height: 28px;
}

.user-products-filter__filter-btn-text {
    font-size: 13px;
    color: var(--dark-charcoal);
    text-transform: initial;
    font-family: 'Montserrat';
}

.user-products-filter__modal-filter-btn-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.user-products-filter--wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    overflow: auto;
}

.user-products-filter--wrap::-webkit-scrollbar {
    height: 2px;
    background-color: rgb(217, 214, 214);
}

.user-products-filter__filter-menu--wrap {
    
}

.user-products-filter__filter-menu--wrap .MuiPaper-root{
    max-height: 400px;
}

.user-products-filter__filter-wrap--desc {
    display: none;
}

.user-products-filter__filter-menu-active {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.user-products-filter__filter-menu-item {
    transition: .3s;
}

.user-products-filter__filter-menu-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.user-products-filter__products-filter-slider-wrap .MuiPaper-root {
    width: 333px;
}

.user-products-filter__products-filter-slider .MuiSlider-thumb {
    width: 25px;
    height: 25px;
    color: #fff;
}

.user-products-filter__products-filter-slider {
    color: var(--main-green) !important;
    height: 2px !important;
}

.user-products-filter__products-filter-price-result-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.user-products-filter__products-filter-price-result--wrap {
    position: relative;
    border: 1px solid #89898999;
    width: 45%;
    padding: 13px 8px;
}

.user-products-filter__products-filter-price-result-title {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    background: #fff;
    padding: 0 3px;
    color: var(--purplish-gray);
}

.user-products-filter__products-filter-price-result-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.user-products-filter__products-filter-price-result {
    width: 100%;
    padding-right: 25px;
    color: var(--dark-charcoal);
}

.user-products-filter__products-filter-price-result-line {
    width: 10px;
    background-color: #000;
    height: 1px;
}
@media (min-width: 768px) {
    .user-products-filter__filter-btn-wrap {
        width: auto;
    }
    .user-products-filter__filter-wrap  {
        width: auto;
    }
    .user-products-filter__filter-btn-text {
        white-space: nowrap;
    }
    .user-products-filter__modal-filter-btn-wrap {
        display: none;
    }
    .user-products-filter {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .user-products-filter--wrap {
        gap: 0;
        flex-wrap: nowrap;
    }
    .user-products-filter__filter-menu--wrap .MuiPaper-root{
        max-height: 600px;
    }
    .user-products-filter__filter-wrap--desc {
        display: flex;
    }
}

.order-status__btn-wrap {
    padding: 0 !important;
}

.order-status__btn.MuiButtonBase-root {
    font-size: 30px;
    padding: 0;
    min-width: 30px;
    height: 21px;
    line-height: 0;
}

.order-status__btn-text {
    color: #B0BAC9;
}

.order-status__btn-select {

}
.order-status__btn-select .MuiMenu-paper {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.order-status__btn-select .MuiButtonBase-root {
    font-family: 'Montserrat';
}

.order-status__btn-select .MuiPaper-root  {
}

.order-status__btn-text--str {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--main-green);
}

.order-status__btn {
    text-transform: none;
    font-size: 13px;
    height: 21.6px;
    transition: .3s;
}

.order-status__btn:hover {
}

.order-status__btn-img {
    width: 20px;
}

.order-status__btn-img-hover {
    display: none;
    width: 20px;
}

.order-status__btn-wrap:hover .order-status__btn-img {
    display: none;
}

.order-status__btn-wrap:hover .order-status__btn-img-hover {
    display: block;
}

.order-status__btn-title-wrap {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.order-status__btn-title {
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #898989;
    text-align: start;
    text-transform: none;
    line-height: normal;
}

.order-status__order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
}

@media (min-width: 768px) {
    .order-status__btn-title {
        display: block;
    }
    
}
@media (min-width: 1680px) {
    .order-status__order-status {
        width: 130px;
    }
}
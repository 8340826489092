.loader-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 150px);
}

.loader {
}
@keyframes l1 {
  0% {background-position: right}
}
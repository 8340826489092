.collections-view {
    min-height: calc(100vh - 493px);
    font-size: var(--text-size);
}

.collections-view-wrap {
    padding: 0 15px;
}

.collections-view__title {
    line-height: 27px;
    font-size: var(--main-title-size);
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 40px;
}

.collections-view__collections {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.collections-view__collection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.collections-view__collection-img {
    aspect-ratio: 16/9;
    max-height: 300px;
}

.collections-view__collection-title {
    font-size: var(--medium-text-size);
    font-weight: 500;
    line-height: 1.35;
    margin-top: 15px;
    margin-bottom: 15px;
}

.collections-view__banner-wrap {
    margin-top: 35px;
    margin-bottom: 60px;
    background: #f9f9f9;
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 480px) {
    .collections-view__collections {
        gap: 14px;
    }
    .collections-view__collection {
        width: 48.5%;
        margin-bottom: 25px;
    }
}
@media (min-width: 960px) {
    .collections-view__collections {
        gap: 20px;
    }
    .collections-view__collection {
        width: 31%;
    }
}
@media (min-width: 1200px) {
    .collections-view-wrap {
        padding: 0 30px;
    }
    .collections-view__banner-wrap {
        margin-top: 45px;
        margin-bottom: 70px;
    }
    .collections-view__banner-wrap {
        margin-right: -30px;
        margin-left: -30px;
    }
}
.creating-collection--wrap {
    
}

.creating-collection {
    padding: 20px 0;
    color: var(--dark-charcoal);
}

.creating-collection__uploud-lable {
    margin-top: 23px;
    margin-bottom: 8px;
    font-weight: 500;
}

.creating-collection__input-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.creating-collection__upload-result-del-btn {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
}

.creating-collection__upload-img-result {
    max-width: 400px;
    aspect-ratio: 16/9;
    width: calc(100% - 26px);
}

.creating-collection__products--wrap {
    width: 100%;
    overflow: auto;
}

.creating-collection__products-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.creating-collection__product {
    position: relative;
    width: 28%;
    min-width: 239px;
    height: auto;
    padding: 5px 35px 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.creating-collection__products-pagination {
    
}

.creating-collection__product-label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-top: 25px;
    margin-bottom: 16px;
}

.creating-collection__products-error {
    font-size: 22px;
    margin-top: 100px;
    text-align: center;
}

.creating-collection__products-header-wrap {
    display: none;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    font-weight: 500;
    min-width: min-content;
    width: 100%;
}

.creating-collection__products-header-title {
    flex: 1 1;
    max-width: 894.6px;
    min-width: 275px;
}
.creating-collection__products-header-category {
    width: 150px;
}
.creating-collection__products-header-price {
    width: 100px;
}
.creating-collection__products-header-code {
    width: 100px;
}

.creating-collection__create-img--wrap {
    display: flex;
    flex-wrap: wrap;  
    gap: 23px;
    margin-bottom: 20px;
}

.creating-collection__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    width: 100%;
    height: 190px;
    max-width: 500px;
    text-align: center;
    padding: 5px 10px;
}

.creating-collection__upload-result-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.creating-collection__input-sub-title {
    font-size: 11px;
    color: #898989;
}

.creating-category__seo-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
    margin-bottom: 15px;
    padding: 20px 0;
}

.user-collection__text-tab {
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    transition: .3s;
    font-weight: 500;
    line-height: 20px;
    font-family: Montserrat;
    border-bottom: 2px solid transparent;
    padding-bottom: 25px;
}

.creating-collection__filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 23px;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
    color: #19191D;
}

.creating-collection-wrap {
    display: flex;
    flex-direction: column;
    gap: 23px;
    max-width: 500px;
    width: 100%;
}

.creating-collection__search-wrap {
    position: relative;
    max-width: 530px;
    width: 100%;
}

.creating-collection__input-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
    width: 100%;
}

.creating-collection__input {
    max-width: 500px;
    width: 100%;
    height: 40px;
    border: 1px solid #89898999;
    padding: 11px 22px 11px 11px;
    border-radius: 0;
    font-size: 14px;
}

.creating-collection__input::placeholder {
    font-size: 12px;
}

.creating-collection__input:focus {
    border: 1px solid #2A7F77;
}

.creating-collection__textarea {
    max-width: 500px;
    width: 100%;
    border: 1px solid #89898999;
    padding: 11px;
    font-family: 'Montserrat';
    outline: none;
}

.creating-collection__textarea::placeholder {
    font-size: 12px;
}

.creating-collection__textarea:focus-visible {
    border: 1px solid #2A7F77;
}

.creating-collection__search-input .MuiFormLabel-root {
    left: 28px;
    top: -5px;
}

.creating-collection__search-input {
    padding-left: 35px;
    border-radius: 0;
    height: 40px;
}

.creating-collection__search-input-img {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    z-index: 1;
}

.creating-collection__select-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 190px;
    max-width: 530px;
    width: 100%;
}

.creating-collection__select {
    height: 40px;
    outline-color: #2A7F77 !important;
    border-radius: 0 !important;
}

.creating-collection__select .MuiOutlinedInput-notchedOutline {
    border-color: #89898999 !important;
}

.creating-collection__select.MuiInputBase-root {
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
}

.creating-collection__select .MuiNativeSelect-select {
    padding: 9.5px 14px;
}

.creating-collection__products-filter-slider .MuiSlider-thumb {
    width: 25px;
    height: 25px;
    color: #fff;
}

.creating-collection__products-filter-slider {
    color: var(--main-green) !important;
    height: 2px !important;
}

.creating-collection__products-filter-price-result-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.creating-collection__products-filter-price-result-input-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 200px;
}

.creating-collection__products-filter-price-result--wrap {
    position: relative;
    border: 1px solid #89898999;
    width: 45%;
    padding: 13px 8px;
}

.creating-collection__products-filter-price-result-title {
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    background: #fff;
    padding: 0 3px;
    color: var(--purplish-gray);
}

.creating-collection__products-filter-price-result-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.creating-collection__products-filter-price-result {
    width: 100%;
    padding-right: 25px;
}

.creating-collection__products-filter-price-result-line {
    width: 10px;
    background-color: #000;
    height: 1px;
}

.creating-collection__products-filter-price-btn-wrap {
    display: flex;
    gap: 5px;
}

.creating-collection__products-filter-price-btn {
    border: 1px solid #2A7F77;
    color: #19191D;
    padding: 11.6px 10px;
    font-size: 12px;
    transition: .3s;
}

.creating-collection__products-filter-price-btn-sent {
    border: 1px solid #2A7F77;
    background: var(--main-green);
    color: #fff;
    padding: 11.6px 10px;
    font-size: 12px;
    transition: .3s;
}

.creating-collection__products-filter-price-result-title--mob {

}

.creating-collection__products-filter-price-result-title--des {
    display: none;
}

.creating-collection__input-lable-wrap {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.creating-collection__input-lable {
    font-weight: 500;
}

.creating-collection__input-lable-price-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.creating-collection__input-lable-price {

}

.creating-collection__input-sub-title {
    color: #898989;
}

.creating-collection__select-item {
    
}

.creating-collection__select-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-collection__select-item-active {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}

.creating-collection__products-pagination-wrap {
    align-items: center;
}

.creating-collection__input-wrap--alias {
    margin-top: 23px;
}

@media (min-width: 460px) {
    .creating-collection__products-filter-price-btn-sent,
    .creating-collection__products-filter-price-btn {
        padding: 11.6px 27px 11.6px 20px;
    }
}
@media (min-width: 768px) {
    .creating-collection__create-img-wrap {
        height: 200px;
    }
    .creating-collection__products-wrap {
        padding-top: 20px;
    }
    .creating-collection {
        padding: 20px 0 20px 15px
    }
    .creating-collection__upload-img-result {
        max-width: 300px;
    }
    .creating-collection__products-header-wrap {
        display: flex;
    }
    .creating-collection__input-sub-title {
        font-size: 13px;
    }
    .creating-collection__product-label {
        margin-bottom: 25px;
    }
    .creating-category__seo-wrap {
        padding: 20px 0 20px 15px ;
    }
    .creating-collection__products-filter-price-result-title--mob {
        display: none;
    }
    .creating-collection__products-filter-price-result-title--des {
        display: block;
    }
    .creating-collection__products-filter-price-result-input-wrap {
        max-width: 262px;
    }
    .creating-collection__products-filter-price-btn-sent,
    .creating-collection__products-filter-price-btn {
        min-width: 111px;
        font-size: 14px;
        padding: 10.8px 27px 10.8px 20px;
    }
    .creating-collection__textarea::placeholder,
    .creating-collection__input::placeholder {
        font-size: 13px;
    }
}

@media (min-width: 1128px) {
    .creating-collection__select-wrap--des {
        margin-top: 6px;
    }
    .creating-collection__select-wrap--sort {
        margin-top: -0.5px;
    }
    .creating-collection-wrap--category {
        margin-top: 3px;
    }
    .creating-collection-wrap--hide {
        margin-top: 6px;
    }
}
@media (min-width: 1130px) {
    .creating-collection-wrap--des {
        padding-top: 24.6px;
    } 
}
@media (min-width: 1280px) {
    .creating-collection-wrap--search {
        padding-top: 25px;
    }   
}
@media (min-width: 1440px) {
    .creating-collection {
        gap: 49px;
    }
    .creating-collection__create-img--wrap {
        gap: 49px;
    }
}
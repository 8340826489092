.favorite_view {
    color: var(--dark-charcoal);
    font-size: var(--text-size);
}

.favorite_view-wrap {
    padding: 0 10px;
}

.favorite_view__title {
    color: var(--dark-charcoal);
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}

.favorite_view__products {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    width: calc(100% + 8px);
    margin-left: -4px;
}

.favorite_view__product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
    width: 50%;
    max-width: 50%;
    padding: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    margin-bottom: 2px;
    transition: .3s;
}

.favorite_view__product:hover {
    border: 1px solid var(--main-green);
}

.favorite_view__products-error {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(130,130,130,.5);
    color: rgba(130,130,130,.7);
    width: 100%;
    padding: 40px;
    height: 285px;
    text-align: center;
}

@media (min-width: 960px) {
    .favorite_view__product {
        width: 25%;
        max-width: 25%;
    }
    .favorite_view__title {
        font-size: 24px;
    }
}
@media (min-width: 1200px) {
    .favorite_view-wrap {
        padding: 0 30px;
    }
    .favorite_view__title {
        font-size: 25px;
    }
}
@media (min-width: 1680px) {
    .favorite_view__title {
        font-size: 28px;
    }
}
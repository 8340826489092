.user-vendors {
    font-size: 14px;
    color: #19191D;
}

.user-vendors__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    height: 83.6px;
    margin-left: -15px;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
}

.user-vendors__header-title-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-vendors__header-title-img {
    width: 28px;
    height: 28px;
}

.user-vendors__header-title {
    font-weight: 500;
    font-size: 16px;
}

.user-vendors__header-btn-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: auto;
}

.user-vendors__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 8px 27px 8px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.user-vendors__header-btn-save {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 11px 21px 11px 15px;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.user-vendors__header-btn-save--1 {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #2A7F77;
    padding: 11px 27px 11px 20px;
    color: #fff;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
}

.user-vendors__header-btn-img {
    width: 24px;
    height: 24px;
}

.user-vendors__header-btn-img-save {
    width: 18px;
    height: 18px;
}

.user-vendors__modal-edite-btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 100;
    transition: .3s;
}

.user-vendors__modl-uploud-input {
    position: absolute;
    z-index: -10;
    width: 0;
    height: 0;
    opacity: 0;
}

.user-vendors__modl-uploud-result {
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-vendors__vendors-wrap {
    display: flex;
    flex-wrap: wrap;
}

.user-vendors__vendor {
    display: flex;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 10px 0;
    font-size: 13px;
}

.user-vendors__vendor--is-blocked {
    opacity: .6;
}

.user-vendors__vendor--wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.user-vendors__vendor-wrap {
    flex: 1 1;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.user-vendors__vendor-img--wrap {
}

.user-vendors__vendor-img-wrap {
}

.user-vendors__vendor-img {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.user-vendors__vendor-name {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.user-vendors__vendor-accordion.MuiPaper-root {
    margin: 0 !important;
}

.user-vendors__vendor-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.user-vendors__vendor-text {
    font-size: 14px;
}

.user-vendors__create-wrap {
}

.user-vendors__btn-download-link-wrap {
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 20px 0;
}

.user-vendors__btn-download-link::-webkit-scrollbar {
    height: 2px;
    background-color: rgb(217, 214, 214);
}


.user-vendors__btn-download-link {
    transition: .3s;
}

.user-vendors__btn-download-link:hover {
    color: #2A7F77;
}

.user-vendors__btn-download-link-overflow {
    overflow: auto;
}

.user-vendors__vendor-menu-wrap {

}

.user-vendors__vendor-menu-btn {

}

.user-vendors__vendor-menu-btn-text {
    font-size: 36px;
    margin-top: -16px;
}

.user-vendors__vendor-products-wrap {
    display: flex;
    gap: 5px;
    align-items: center;
    min-width: 122px;
}

.user-vendors__vendor-products {

}

.user-vendors__vendor-products-text {
}

.user-vendors__vendor-text-title {
    font-size: 12px;
}

.user-vendors__vendor-date-wrap {
    min-width: 140px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.user-vendors__vendor-date {

}

.user-vendors__products-header-wrap {
    display: none;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 10px;
    padding: 15px 0 10px 0;
    margin-left: 15px;
    margin-top: 20px;
    font-weight: 500;
}

.user-vendors__products-header-vendor {
    flex: 1 1;
}
.user-vendors__products-header-product {
    min-width: 123.5px;
}
.user-vendors__products-header-date {
    min-width: 184.5px;
}

.user-vendors__filter-btn--wrap {
    height: 33.6px !important;
}

.user-vendors__filter-btn--wrap.MuiButtonBase-root {
    padding: 0 0;
}

.user-vendors__filter-btn-wrap {
    display: flex;
    align-items: center;
    gap: 2px;
    border: 1px solid #D8D8D8;
    padding: 2px 5px 2px 4px;
    min-width: 177px;
}

.user-vendors__filter--wrap {
    display: flex;
    align-items: flex-start;
    padding: 20px 15px;
    border-bottom: 2px solid #D8D8D8;
    width: calc(100% + 30px);
    margin-left: -15px;
}

.user-vendors__filter-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 12px;
    min-width: 176px;
}

.user-vendors__filter-btn-img {
    width: 28px;
    height: 28px;
}

.user-vendors__filter-btn-text {
    width: 100%;
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
    color: var(--dark-charcoal);
    text-transform: initial;
}

.user-vendors__filter-menu-text-item {
    color: var(--dark-charcoal) !important;
}

.user-vendors2__search-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 530px;
    width: 100%;
}

.user-vendors2__input-wrap {
    width: fit-content;
    position: relative;
    height: 33.6px;
}

.user-vendors2__input {
    max-width: 160px;
    width: 100%;
    height: 33.6px;
    border: 1px solid #D8D8D8;
    padding: 8.4px 22px 8.5px 30px;
    border-radius: 0;
    --webkit-border-radius: 0;
    font-size: 12px;
}

.user-vendors2__input::placeholder {
    font-size: 12px;
}

.user-vendors2__input:focus {
    border: 1px solid #2A7F77;
}

.user-vendors2__search-input-img {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
}

.user-vendors__btn-download-link-btn {
    transition: .3s;
}

.user-vendors__btn-download-link-btn:hover {
    color: var(--main-green);
}

.user-vendors__filter-lable {
    font-size: 13px;
}

.user-vendors__header-btn-img-save {
    width: 18px;
    height: 18px;
}

@media (min-width: 400px) {
    .user-vendors__header-title-wrap {
        gap: 20px;
    }
    .user-vendors__vendor-wrap {
        gap: 20px;
    }
}
@media (min-width: 500px) {
    .user-vendors__vendor--wrap {
        gap: 15px;
    }
}
@media (min-width: 610px) {
    .user-vendors__header-btn-save {
        padding: 11px 27px 11px 20px;
    }
}
@media (min-width: 768px) {
    .creating-vendor {
        padding: 0 0 22px 15px;
    }
    .user-vendors__header {
        width: calc(100% + 15px);
        margin-left: 0;
        padding: 20px 15px 20px 15px;
        gap: 20px;
    }
    .user-vendors__filter--wrap {
        width: auto;
        margin-left: 15px;
    }
    .user-vendors__vendors-wrap {
        padding-left: 15px;
    }
    .user-vendors__products-header-wrap {
        display: flex;
    }
    .user-vendors__vendor-text-title {
        display: none;
    }
    .user-vendors__vendor-name {
        flex: 1 1;
    }
    .user-vendors__vendor-text-wrap {
        flex-direction: row;
        flex: 1 1;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
    }
    .user-vendors__create-wrap {
        padding: 22px 15px 22px 15px;
    }
    .user-vendors__create-wrap {
        padding: 0 0 22px 15px;
    }
    .user-vendors__header-btn {
        font-size: 14px;
    }
    .user-vendors__header-btn-save {
        font-size: 14px;
    }
}
@media (min-width: 1200px) {
    .user-vendors__header {
        padding: 20px 30px 20px 30px;
    }
}
@media (min-width: 1680px) {
    .user-vendors__vendor {
        font-size: 14px;
    }
    .user-vendors__filter-lable {
        font-size: 14px;
    }
}

.notification-logo__notifications-text-logo-wrap {
    flex: 1 1;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.notification-logo__notifications-text-logo {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.notification-logo__notifications-type-1 {
    background: #8989894f;
    color: #898989;
    border-color: #898989;
}

.notification-logo__notifications-type-2 {
    background: #b82dbd36;
    color: #b82dbd;
    border-color: #b82dbd;
}

.notification-logo__notifications-type-3 {
    background: #2a7f7742;
    color: #2A7F77;
    border-color: #2A7F77;
}

.notification-logo__notifications-type-4 {
    background: #9329063b;
    color: #932906;
    border-color: #932906;
}

.notification-logo__notifications-type-5 {
    background: #4c6fff4d;
    color: #4C6FFF;
    border-color: #4C6FFF;
}

@media (min-width: 1200px) {
    .notification-logo__notifications-text-logo {
        width: 48px;
        height: 48px;
    }
}
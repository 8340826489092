.button-scroll {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    left: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.button-scroll__1 {

}

.button-scroll__2 {
    display: none;
}

.button-scroll:hover .button-scroll__1 {
    display: none;
}

.button-scroll:hover .button-scroll__2 {
    display: block;
}
.collection-products {
    min-height: calc(100vh - 493px);
    font-size: var(--text-size);
}

.collection-products-wrap {
    padding: 0 15px;
}

.collection-products__title {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--dark-charcoal);

    line-height: 33px;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.collection-products__banner-wrap {
    margin-top: 60px;
    margin-bottom: 60px;
    background: #f9f9f9;
    margin-right: -15px;
    margin-left: -15px;
}

.collection-products__collections {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    width: calc(100% + 18px);
    margin-bottom: 60px;
    flex-wrap: wrap;
    margin-left: -9px;
    flex: 1 1;
}

@media (min-width: 480px) {
    .collection-products__collection {
        width: 45.5%;
    }
}
@media (min-width: 960px) {
    .collection-products__collection {
        width: 24%;
    }
}
@media (min-width: 1200px) {
    .collection-products__collection {
        width: 23.5%;
        max-width: 25%;
    }
    .collection-products-wrap {
        padding: 0 30px;
    }
    .collection-products__collections {
        margin-bottom: 70px;
    }
    .collection-products__banner-wrap {
        margin-top: 70px;
        margin-bottom: 70px;
        margin-right: -30px;
        margin-left: -30px;
    }
    .collection-products__title {
        font-size: 25px;
        line-height: 27px;
    }
}
.context-menu-vendor {
    margin-left: auto;
}

.context-menu-vendor__btn {

}

.context-menu-vendor__btn.MuiButtonBase-root {
    font-size: 30px;
    padding: 0;
    min-width: 30px;
    height: 21px;
    line-height: 0;
}

.context-menu-vendor__btn-img {
    width: 20px;
}

.context-menu-vendor__btn-select {
    
}

.context-menu-vendor__btn-select .MuiPaper-root  {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.context-menu-vendor__btn-select-item {
    transition: .3s;
}

.context-menu-vendor__btn-select-item:hover {
    background: #d8d8d899 !important;
    color: var(--dark-charcoal) !important;
}
.bread_crumbs {
    display: flex;
    align-items: center;
    gap: 3px;
    width: 100%;
    overflow-x: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 15px;
    white-space: nowrap;
    color: var(--neutral-gray);
}

.bread_crumbs::-webkit-scrollbar {
    height: 1px;
    background-color: rgb(217, 214, 214);
}

.bread_crumbs__last {
    text-decoration: underline;
    height: 22px;
    white-space: nowrap;
}

.bread_crumbs__skeleton {
    width: 100%;
    max-width: 90px;
    height: 22px !important;
}
@media (min-width: 1680px) {
    .bread_crumbs {
        font-size: 14px;
    }
    .bread_crumbs {
        margin-top: 26px;
        margin-bottom: 26px;
    }
}
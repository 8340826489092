.material-hover {
    display: flex;
    gap: 5px;
    border: 1px solid #000;
    padding: 7px;
    background: #fff;
    font-family: 'Montserrat';
}

.material-hover__img {
    height: 200px;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.material-hover__text-wrap {
    font-family: 'Montserrat';
    overflow: hidden;
}

.material-hover__text-title {
    font-size: 15px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat';
}

.material-hover__text-sub-title {
    font-weight: 600;
    font-size: 12px;
}

.material-hover__text {
    font-size: 12px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat';
}

.material-hover__text-keeping {
    font-size: 12px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat';
}